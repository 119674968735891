import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";

export class BuyModel extends ModelBase<BuyModel, BuyModelDTO> {
    @MobX.observable
    public token: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public type: string = "";

    @MobX.observable
    public expiry: Date | null = null;

    @MobX.observable
    public security: string = "";

    @MobX.observable
    public cardNumber: string = "";

    @MobX.observable
    public propertyId: string = "";

    @MobX.observable
    public paymentSuccess: boolean | undefined;

    @MobX.observable
    public stripeResponse: string = "";

    public fromDto = (model: BuyModelDTO) => {};
    public toDto = (model: BuyModel) => {};
}

export interface BuyModelDTO {
    name: string;
    type: string;
    expiry: Date | null;
    security: string;
    cardNumber: string;
    propertyId: string;
    updatedStripePaymentRequests: UpdateStripePaymentRequestDTO[];
    paymentType: string;
    price: number;
    paymentSuccess: boolean;
    actionUrl: string;
    intentId: string;
    stripeResponse: string;
}

export interface UpdateStripePaymentRequestDTO {
    id: string;
    paidByIdentityUserId: string;
}
