export const Client = {
    Path: {
        Account: {
            Login: "/login",
            Logout: "/logout",
            ForgotPassword: "/forgotpassword",
            ForgotPasswordEmail: "/forgotpassword/:emailAddress",
            ResetPassword: "/resetpassword",
        },
        Dashboard: "/:propertyId?",
        Choices: "/choices/:propertyId",
        ChoicesArea: "/choicesarea/:propertyId/:areaId",
        Documents: "/documents/:propertyId",
        Inspection: "/inspection/:propertyId",
        Progress: "/progress/:propertyId",
        Property: "/property/:propertyId",
        Snag: "/snag/:propertyId/:snagId",
        Snags: "/snags/:propertyId",
    },
};

export const Server = {
    Api: {
        Account: {
            Login: "./api/account/login",
            Logout: "./api/account/logout",
            ChangePassword: "./api/account/changepassword",
            ForgotPassword: "./api/account/forgotpassword",
        },
        Dashboard: {
            Get: "./api/dashboard/get",
            GetByPropertyId: "./api/dashboard/getbypropertyid",
            GetChoices: "./api/dashboard/getchoices",
            GetChoicesAndRelated: "./api/dashboard/getchoicesandrelated",
            GetDocuments: "./api/dashboard/getdocuments",
            GetDocument: "./api/dashboard/getdocument",
            GetDevelopmentDocuments: "./api/dashboard/getdevelopmentdocuments",
            GetDevelopmentDocument: "./api/dashboard/getdevelopmentdocument",
            GetProgress: "./api/dashboard/getprogress",
            GetProperty: "./api/dashboard/getproperty",
            GetSnags: "./api/dashboard/getsnags",
            GetInspection: "./api/dashboard/getinspection",
            GetPropertyDrawingDocuments: "./api/dashboard/getdrawingdocuments",
            GetPropertyDrawingDocument: "./api/dashboard/getdrawingdocument",
        },
        Snags: {
            GetViewById: "./api/snags/GetViewByid",
            GetSnagRelated: "./api/snags/getrelated",
            Upsert: "./api/snags/upsert",
        },
        Choices: {
            GetChoicesArea: "./api/choices/getchoicesarea",
            GetChoicesAreaAndRelated: "./api/choices/getchoicesareaandrelated",
            AddToOrder: "./api/choices/addtoorder",
            GetChoicesSummary: "./api/choices/getchoicessummary",
            GetIsChoicesLocked: "./api/choices/getischoiceslocked",
        },
        Image: {
            GetCompanyImage: "./api/image/getcompanyimage",
            GetBlob: "/api/image/getblob",
        },

        // Likely redundant and can be removed.
        Admin: {
            ResetFailedLoginAttemptsCount: "",
        },
    },
};
