// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { SnagModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { SnagsModel } from "Custom/Models/DashboardModels/SnagsModel";
import { NewSnagViewModel } from "Custom/ViewModels/DashboardSubViewModels/NewSnagViewModel";
import { SnagViewModel } from "Custom/ViewModels/DashboardSubViewModels/SnagViewModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "../../Stores";

export class SnagsViewModel extends ViewModelBase<SnagsModel> {
    constructor(propertyId: string) {
        super(new SnagsModel());
        this.setDecorators(SnagsModel);

        //console.log("SNAGSVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId;

        this.newSnagViewModel = new NewSnagViewModel(this, propertyId);

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId);
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    @observable
    public newSnagViewModel: NewSnagViewModel;

    // #endregion Properties

    // #region Computeds

    @computed
    public get SnagViewModels() {
        return this.model.snags.map(snag => new SnagViewModel(snag));
    }

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    @action
    public setNewSnagsViewModel = (propertyId: string) => {
        this.newSnagViewModel = new NewSnagViewModel(this, propertyId);
    };

    public add = (dto: SnagModelDTO, propertyId: string): void => {
        this.model.addFromDto(dto);

        // Reset its viewmodel.
        this.setNewSnagsViewModel(propertyId);
    };

    public navigateToDashboard = () => {
        this.history.push(`/${this.propertyId}`);
    };

    public naviagateToViewSnag = (propertyId: string, snagId: string) => {
        this.history.push("/snag/:propertyId/:snagId".replace(":propertyId", propertyId).replace(":snagId", snagId));
    };

    private load = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<SnagModelDTO[]>(Server.Api.Dashboard.GetSnags + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
