// Libraries
import { Box, CardMedia, Divider, IconButton, InputAdornment, Link, Typography } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { Redirect } from "react-router";

// Core
import { Loader } from "Core/Components";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { StandardButton } from "Custom/Components/Buttons/StandardButton";
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import { AccountPageStyles, LoginPageStyles } from "Custom/StylesAppSpecific/AccountPagesStyling";
import { LoginViewModel } from "Custom/ViewModels/LoginViewModel";

export const LoginPage: React.FunctionComponent = () => {
    // #region Code Behind

    const accountPageStyles = AccountPageStyles();
    const loginPageStyles = LoginPageStyles();

    const [viewModel] = useState(() => new LoginViewModel());
    const [showPassword, setShowPassword] = React.useState(false);

    const onClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();

        viewModel.login();
    };

    // #endregion Code Behind

    const renderPasswordVisibilityButton = () => (
        <InputAdornment position="end" style={{ display: isEmptyOrWhitespace(viewModel.model.password) ? "none" : "flex" }}>
            <IconButton aria-label="Toggle password visibility" edge="end" onClick={onClickShowPassword} onMouseDown={onClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    const renderBusy = () => <Loader />;

    const renderError = () => (
        <React.Fragment>
            <Box className={accountPageStyles.error}>
                <Typography className={accountPageStyles.title} gutterBottom>
                    There seems to have been a problem
                </Typography>
                <Typography className={accountPageStyles.subTitle} gutterBottom>
                    {viewModel.serverValidationMessage}
                </Typography>
                <Box>
                    <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                        <StandardButton className={accountPageStyles.submitButton} displayName="Try again" execute={viewModel.tryAgain} />
                    </Box>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </React.Fragment>
    );

    const renderForm = () => (
        <form onSubmit={onSubmit}>
            <Box>
                <EditableInput
                    className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl} ${accountPageStyles.formInputControl}`}
                    fieldName="emailAddress"
                    inputProps={{
                        placeholder: "Login in (your email address)",
                    }}
                    type="email"
                    validateOnBlur={true}
                    viewModel={viewModel}
                    data-cy="CPloginEmail"
                />
                <EditableInput
                    className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl} ${accountPageStyles.formInputControl}`}
                    fieldName="password"
                    inputProps={{
                        InputProps: {
                            endAdornment: renderPasswordVisibilityButton(),
                        },
                        placeholder: "Your password",
                    }}
                    type={showPassword ? "text" : "password"}
                    viewModel={viewModel}
                    data-cy="CPloginPassword"
                />
                <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                    <StandardButton canExecute={!viewModel.IsLoading} className={accountPageStyles.submitButton} displayName="Log in" isSubmit={true} />
                </Box>
                <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                    <Link className={`${accountPageStyles.linkButton} ${loginPageStyles.linkButton}`} component="button" onClick={viewModel.navigateToForgotPassword}>
                        I've forgotten my password...
                    </Link>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </form>
    );

    return useObserver(() => {
        if (viewModel.isLoggedIn) {
            return <Redirect to={"/"} />;
        } else {
            return (
                <Box className={`${accountPageStyles.root} ${loginPageStyles.root}`}>
                    <Box className={accountPageStyles.houseImageContainer} />
                    <Box className={accountPageStyles.body}>
                        <Box className="login-block">
                            <Box className={accountPageStyles.customerPortalImageContainer} />
                            <Box className={accountPageStyles.bodyContent}>
                                {viewModel.canDisplayBusy && renderBusy()}
                                {viewModel.canDisplayError && renderError()}
                                {viewModel.canDisplayForm && renderForm()}
                            </Box>
                            <Box className={accountPageStyles.logoImageContainer} />
                        </Box>
                    </Box>
                </Box>
            );
        }
    });
};
