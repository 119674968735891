// Libraries
import { action, computed, observable } from "mobx";
import moment, { Moment } from "moment";
import "moment-timezone";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "util";

export class InspectionStageModel extends ModelBase<InspectionStageModel, InspectionStageModelDTO> {
    // #region Properties

    @observable
    inspectionStageId = "";

    @observable
    inspectionStageName = "";

    @observable
    inspectionStageColor = "";

    @observable
    inspectionStageType = 0;

    @observable
    inspectionStageDate: Moment | null = null;

    @observable
    inspectionStageStatusName = "";

    @observable
    inspectionStageStatusType = 0;

    // #endregion Properties

    @computed
    public get inspectionStageDateFormatted() {
        return this.inspectionStageDate !== null && this.inspectionStageDate !== undefined ? this.inspectionStageDate.format("DD/MM/YYYY") : "";
    }

    @action
    public fromDto = (dto: InspectionStageModelDTO): void => {
        this.inspectionStageId = dto.inspectionStageId;
        this.inspectionStageName = dto.inspectionStageName;
        this.inspectionStageColor = dto.inspectionStageColor;
        this.inspectionStageType = dto.inspectionStageType;
        this.inspectionStageDate = !isEmptyOrWhitespace(dto.inspectionStageDate) ? moment.tz(dto.inspectionStageDate, "Europe/London") : null;
        this.inspectionStageStatusName = dto.inspectionStageStatusName;
        this.inspectionStageStatusType = dto.inspectionStageStatusType;
    };

    public toDto = (): InspectionStageModelDTO => {
        throw "toDto is not supported";
    };
}

export interface InspectionStageModelDTO {
    inspectionStageId: string;
    inspectionStageName: string;
    inspectionStageColor: string;
    inspectionStageType: number;
    inspectionStageDate: string;
    inspectionStageStatusName: string;
    inspectionStageStatusType: number;
}
