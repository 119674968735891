// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom

export class SpecificationProductModel extends ModelBase<SpecificationProductModel, SpecificationProductModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public specificationId: string = "";

    @observable
    public areaId: string = "";

    @observable
    public productGroupId: string = "";

    @observable
    public productId: string = "";

    @observable
    public isIncluded: boolean = false;

    @observable
    public isUpgrade: boolean = false;

    @observable
    public isOptional: boolean = false;

    @observable
    public price: string = "";

    @observable
    public isOverride: boolean = false;

    // #endregion Properties

    @action
    public fromDto = (dto: SpecificationProductModelDTO): void => {
        this.id = dto.id;
        this.specificationId = dto.specificationId;
        this.areaId = dto.areaId;
        this.productGroupId = dto.productGroupId;
        this.productId = dto.productId;
        this.isIncluded = dto.isIncluded;
        this.isUpgrade = dto.isUpgrade;
        this.isOptional = dto.isOptional;
        this.price = dto.price;
        this.isOverride = dto.isOverride;
    };

    public toDto = (): SpecificationProductModelDTO[] => {
        throw "toDto is not supported";
    };
}

export interface SpecificationProductModelDTO {
    id: string;
    specificationId: string;
    areaId: string;
    productGroupId: string;
    productId: string;
    isIncluded: boolean;
    isUpgrade: boolean;
    isOptional: boolean;
    price: string;
    isOverride: boolean;
}
