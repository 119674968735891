// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom

export class SpecificationHouseTypeProductModel extends ModelBase<SpecificationHouseTypeProductModel, SpecificationHouseTypeProductModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public specificationId: string = "";

    @observable
    public houseTypeId: string = "";

    @observable
    public areaId: string = "";

    @observable
    public productGroupId: string = "";

    @observable
    public productId: string = "";

    @observable
    public defaultOverridePrice: string = "";

    // #endregion Properties

    @action
    public fromDto = (dto: SpecificationHouseTypeProductModelDTO): void => {
        this.id = dto.id;
        this.specificationId = dto.specificationId;
        this.houseTypeId = dto.houseTypeId;
        this.areaId = dto.areaId;
        this.productGroupId = dto.productGroupId;
        this.productId = dto.productId;
        this.defaultOverridePrice = dto.defaultOverridePrice;
    };

    public toDto = (): SpecificationHouseTypeProductModelDTO[] => {
        throw "toDto is not supported";
    };
}

export interface SpecificationHouseTypeProductModelDTO {
    id: string;
    specificationId: string;
    houseTypeId: string;
    areaId: string;
    productGroupId: string;
    productId: string;
    defaultOverridePrice: string;
}
