// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { ProductModel, ProductModelDTO } from "./ProductModel";
import { ProductVariantModelDTO } from "./ProductVariantModel";
import { SpecificationHouseTypeProductModelDTO } from "./SpecificationHouseTypeProductModel";
import { SpecificationProductModelDTO } from "./SpecificationProductModel";
import { Format } from "Custom/Utils";
import { isFinite } from "lodash-es";
import { ProductSummaryModelDTO } from "./ProductSummary";

export class ProductGroupModel extends ModelBase<ProductGroupModel, ProductGroupAndRelatedModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public productGroupName: string = "";

    @observable
    public orderAreaProductGroupId: string = "";

    @observable
    public isOrderAreaProductGroupComplete: boolean = false;

    @observable
    public orderAreaProductGroupNotes: string = "";

    @observable
    public isNoneOfTheAbove: boolean = false;

    @observable
    public areaId: string = "";

    @observable
    public products = observable<ProductModel>([]);

    // #endregion Properties

    @action
    public fromDto = (dto: ProductGroupAndRelatedModelDTO): void => {
        this.id = dto.productGroup.id;
        this.productGroupName = dto.productGroup.productGroupName;
        this.orderAreaProductGroupId = dto.productGroup.orderAreaProductGroupId;
        this.isOrderAreaProductGroupComplete = dto.productGroup.isOrderAreaProductGroupComplete;
        this.orderAreaProductGroupNotes = dto.productGroup.orderAreaProductGroupNotes;
        this.isNoneOfTheAbove = dto.productGroup.isNoneOfTheAbove;
        this.areaId = dto.productGroup.areaId;

        // The value is always the value minus the highest 'Included' product cost within the product group if isUpgrade
        // See PropertyChoiceSelectorNestedProductListIncluded.tsx > getProductPrice() in HBP.
        let includedProductsWithPrice = dto.products.filter(
            p => p.isIncluded === true && p.productPrice !== "" && p.productPrice !== "POA" && p.productPrice !== null && p.productPrice !== undefined,
        );

        const productCosts: number[] = [];

        let includedProducts = dto.products.filter(
            p => p.isIncluded === true && p.productPrice !== "" && p.houseTypeProductPrice !== "POA" && p.productPrice !== null && p.productPrice !== undefined,
        );

        includedProducts.forEach(houseTypeProductIncluded => {
            // Use HTP price if it exists, otherwise use the product price.
            if (this.houseTypeProductHasPrice(houseTypeProductIncluded) && houseTypeProductIncluded.houseTypeProductPrice.toUpperCase() !== "POA") {
                let unformattedHTPPrice: number = Number(Format.currencyFormatForUpsert(houseTypeProductIncluded.houseTypeProductPrice));
                productCosts.push(unformattedHTPPrice);
            } else if (houseTypeProductIncluded.productPrice) {
                if (houseTypeProductIncluded.productPrice !== "0.00") {
                    let unformattedProductPrice: number = Number(Format.currencyFormatForUpsert(houseTypeProductIncluded.productPrice));

                    productCosts.push(unformattedProductPrice);
                }
            }
        });

        //! highest included prices get's assigned infinity if product costs is empty

        let highestIncludedProductCost = Math.max.apply(Math, productCosts);

        for (const productDto of dto.products) {
            const product = new ProductModel();

            let productVariants = dto.productVariants.filter(pv => pv.productId === productDto.productId);

            if (productDto.isUpgrade) {
                if (
                    productDto.houseTypeProductPrice !== undefined &&
                    productDto.houseTypeProductPrice !== null &&
                    productDto.houseTypeProductPrice !== "" &&
                    productDto.houseTypeProductPrice.toUpperCase() !== "POA"
                ) {
                    let unformattedHTPPrice: number = Number(Format.currencyFormatForUpsert(productDto.houseTypeProductPrice, true));

                    if (Number.isFinite(highestIncludedProductCost) == false) {
                        productDto.highestIncludedProductCost = unformattedHTPPrice.toFixed(2);
                    } else {
                        if (highestIncludedProductCost !== null && highestIncludedProductCost !== undefined) {
                            productDto.highestIncludedProductCost = (unformattedHTPPrice - highestIncludedProductCost).toFixed(2);
                        } else {
                            productDto.highestIncludedProductCost = unformattedHTPPrice.toFixed(2);
                        }
                    }
                } else {
                    let unformattedProductPrice: number = Number(Format.currencyFormatForUpsert(productDto.productPrice, true));

                    if (Number.isFinite(highestIncludedProductCost) == false) {
                        productDto.highestIncludedProductCost = unformattedProductPrice.toFixed(2);
                    } else {
                        if (highestIncludedProductCost !== null && highestIncludedProductCost !== undefined) {
                            productDto.highestIncludedProductCost = (unformattedProductPrice - highestIncludedProductCost).toFixed(2);
                        } else {
                            productDto.highestIncludedProductCost = unformattedProductPrice.toFixed(2);
                        }
                    }
                }
            }

            productDto.orderQuantity === 0 ? (productDto.orderQuantity = 1) : productDto.orderQuantity;

            product.fromDto({
                product: productDto,
                productVariants: productVariants,
                specificationHouseTypeProducts: dto.specificationHouseTypeProducts,
                specificationProducts: dto.specificationProducts,
                productsSummary: dto.productsSummary,
                products: dto.products,
            });

            this.products.push(product);
        }
    };

    public toDto = (): OrderAreaProductGroupModelDTO => {
        let orderAreaProductGroup: OrderAreaProductGroupModelDTO = {
            id: this.orderAreaProductGroupId,
            orderId: "",
            areaId: "",
            productGroupId: this.id,
            isComplete: this.isOrderAreaProductGroupComplete,
            notes: this.orderAreaProductGroupNotes,
            isNoneOfTheAbove: this.isNoneOfTheAbove,
        };

        return orderAreaProductGroup;
    };

    private houseTypeProductHasPrice = (houseTypeProduct: ProductModelDTO) => {
        return houseTypeProduct.houseTypeProductPrice !== undefined && houseTypeProduct.houseTypeProductPrice !== null && houseTypeProduct.houseTypeProductPrice !== "";
    };
}

export interface ProductGroupModelDTO {
    id: string;
    productGroupName: string;
    orderAreaProductGroupId: string;
    isOrderAreaProductGroupComplete: boolean;
    orderAreaProductGroupNotes: string;
    isNoneOfTheAbove: boolean;
    areaId: string;
}

export interface ProductGroupAndRelatedModelDTO {
    productGroup: ProductGroupModelDTO;
    products: ProductModelDTO[];
    productVariants: ProductVariantModelDTO[];
    specificationHouseTypeProducts: SpecificationHouseTypeProductModelDTO[];
    specificationProducts: SpecificationProductModelDTO[];
    productsSummary: ProductSummaryModelDTO[];
}

export interface OrderAreaProductGroupModelDTO {
    id: string;
    orderId: string;
    areaId: string;
    productGroupId: string;
    isComplete: boolean;
    notes: string;
    isNoneOfTheAbove: boolean;
}
