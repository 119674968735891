import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/symbol";
import "core-js/es/string/repeat";
import "core-js/features/string/repeat";
import "core-js/stable/symbol";
import "babel-polyfill";
import "proxy-polyfill";

//EN Do not remove default theme from here
import "Custom/StylesAppSpecific/AppStyling";
import React from "react";
import ReactDOM from "react-dom";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import { CoreIndex } from "./Core/CoreIndex";
import App from "./App";

const Init: React.FC = () => {
    return (
        <CoreIndex>
            <App />
        </CoreIndex>
    );
};

ReactDOM.render(<Init />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
