// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";

export class SalesStatus extends ModelBase<SalesStatus, SalesStatusDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public name = "";

    @MobX.observable
    public companyId = "";

    @MobX.observable
    public type = 0;

    @MobX.observable
    public ordinal = 0;

    @MobX.observable
    public isTask = false;

    @MobX.observable
    public isSubTask = false;

    @MobX.observable
    public isDeleted = false;

    @MobX.observable
    public isContactOnly = false;

    @MobX.observable
    public isPropertyOnly = false;

    @MobX.observable
    public colour = "";

    @MobX.observable
    public parentSalesStatusId = "";

    @MobX.observable
    public isDefault = false;

    @MobX.observable
    public isLastActivity = false;

    @MobX.action
    public fromDto = (model: SalesStatusDTO): void => {
        this.id = model.id;
        this.name = model.name;
        this.companyId = model.companyId;
        this.type = model.type;
        this.ordinal = model.ordinal;
        this.isTask = model.isTask;
        this.isSubTask = model.isSubTask;
        this.isDeleted = model.isDeleted;
        this.isContactOnly = model.isContactOnly;
        this.isPropertyOnly = model.isPropertyOnly;
        this.colour = model.colour;
        this.parentSalesStatusId = model.parentSalesStatusId;
        this.isDefault = model.isDefault;
        this.isLastActivity = model.isLastActivity;
    };

    public toDto = (): SalesStatusDTO => {
        throw "toDto is not supported";
    };

    @MobX.action
    public reset = (): void => {
        this.id = "";
        this.name = "";
        this.companyId = "";
        this.type = 0;
        this.ordinal = 0;
        this.isTask = false;
        this.isSubTask = false;
        this.isDeleted = false;
        this.isContactOnly = false;
        this.isPropertyOnly = false;
        this.colour = "";
        this.parentSalesStatusId = "";
        this.isDefault = false;
        this.isLastActivity = false;
    };
}

export interface SalesStatusDTO {
    id: string;
    name: string;
    companyId: string;
    type: number;
    ordinal: number;
    isTask: boolean;
    isSubTask: boolean;
    isDeleted: boolean;
    isContactOnly: boolean;
    isPropertyOnly: boolean;
    colour: string;
    parentSalesStatusId: string;
    isDefault: boolean;
    isLastActivity: boolean;
}
