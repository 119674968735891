// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { PropertyDrawingDocumentModel, PropertyDrawingDocumentModelDTO } from "./PropertyDrawingDocumentModel";

export class PropertyDrawingDocumentsModel extends ModelBase<PropertyDrawingDocumentsModel, PropertyDrawingDocumentsModelDTO> {
    // #region Properties

    @observable
    public documents = observable<PropertyDrawingDocumentModel>([]);

    // #endregion Properties

    @action
    public fromDto = (dto: PropertyDrawingDocumentsModelDTO): void => {
        for (const document of dto.propertyDrawingDocuments) {
            const documentModel = new PropertyDrawingDocumentModel();

            documentModel.fromDto(document);

            this.documents.push(documentModel);
        }
    };

    public toDto = (): PropertyDrawingDocumentsModelDTO[] => {
        throw "toDto is not supported";
    };
}

export interface PropertyDrawingDocumentsModelDTO {
    propertyDrawingDocuments: PropertyDrawingDocumentModelDTO[];
}
