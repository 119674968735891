// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

export class DocumentModel extends ModelBase<DocumentModel, DocumentModelDTO> {
    // #region Properties

    @observable
    public id = "";

    @observable
    public documentName = "";

    @observable
    public documentUrl = "";

    @observable
    public subCategoryName = "";

    @observable
    public type = 0;

    // #endregion Properties

    @action
    public fromDto = (dto: DocumentModelDTO): void => {
        this.id = dto.id;
        this.documentName = dto.documentName;
        this.documentUrl = dto.documentUrl;
        this.subCategoryName = dto.subCategoryName;
        this.type = dto.type;
    };

    public toDto = (): DocumentModelDTO => {
        throw "toDto is not supported";
    };
}

export interface DocumentModelDTO {
    id: string;
    documentName: string;
    documentUrl: string;
    subCategoryName: string;
    type: number;
}
