import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { Error } from "Custom/Components/Error";
import { InspectionStagePresetHelper } from "Custom/Content/Components/InspectionStageSVG";
import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { InspectionPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/InspectionPageStyling";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { InspectionViewModel } from "Custom/ViewModels/DashboardSubViewModels/InspectionViewModel";

export const Inspection: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    //console.log("BUILDVIEW: PropertyId", router.match.params);
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const inspectionPageStyles = InspectionPageStyles();

    const [viewModel] = useState(() => new InspectionViewModel(propertyId));

    const getTextStyle = (inspectionStageStatusType: any) => {
        if (inspectionStageStatusType != null) {
            return {
                color: "black",
            };
        }
    };
    const getSubTextStyle = (inspectionStageStatusType: any) => {
        let colour = "";
        switch (inspectionStageStatusType) {
            case 2:
                colour = "#dc3545"; // Red
                break;
            case 4:
                colour = "#5bb44e"; // Green
                break;
        }
        return {
            color: colour,
        };
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching build information..." />;
    const renderError = () => <Error errorText="Failed to retrieve the build information." />;
    const renderTitleRow = () =>
        isDesktop ? (
            <>
                <Box className={inspectionPageStyles.titleRow}>
                    <Typography className={inspectionPageStyles.title} variant={"h5"}>
                        Build
                    </Typography>
                    <CloseButton execute={viewModel.navigateToDashboard} />
                </Box>
                <Box className={inspectionPageStyles.titleBorder} />
            </>
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Inspection</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderStages = () => (
        <Box className={inspectionPageStyles.stages}>
            <Box className={inspectionPageStyles.gradient} />
            {Array.from(viewModel.inspectionStages)
                .slice()
                .map((stage, index) => (
                    <React.Fragment key={index}>
                        <div className={inspectionPageStyles.stage} key={index}>
                            <Box>
                                {/* Dummy box for link - do not remove */}
                                <Box />
                                <Box className={inspectionPageStyles.icon}>{InspectionStagePresetHelper(stage.inspectionStageColor, stage.inspectionStageStatusType)}</Box>
                                {/* Dummy box for link - do not remove */}
                                <Box />
                            </Box>
                            <Box className={inspectionPageStyles.stageTextRow}>
                                <Typography className={inspectionPageStyles.stageTitle} style={getTextStyle(stage.inspectionStageStatusType)}>
                                    {stage.inspectionStageName}
                                </Typography>
                                {/* <Typography className={inspectionPageStyles.stageSubTitle} style={getSubTextStyle(stage.inspectionStageStatusType)}>
                                        {stage.inspectionStageStatusName}
                                </Typography> */}
                            </Box>
                        </div>
                    </React.Fragment>
                ))}
        </Box>
    );

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${inspectionPageStyles.root}`}>
            <Box className={`${dashboardPageStyles.workspace} ${inspectionPageStyles.workspace}`}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderTitleRow()}
                        {renderStages()}
                    </React.Fragment>
                )}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
        </Box>
    ));
};
