import * as MobX from "mobx";

// Custom
import { SnagPhotoModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { BaseStore } from "./BaseStore";
import { Stores } from "../Stores";

export class SnagPhotoStore extends BaseStore {
    @MobX.observable
    private snagPhotos: SnagPhotoModelDTO[] = [];

    @MobX.observable
    private tmpPhotos: string[] = [];

    @MobX.action
    public populateSnagPhotos(snagPhotos: SnagPhotoModelDTO[]) {
        this.snagPhotos = snagPhotos;
    }

    @MobX.computed
    public get getSnagPhotos(): SnagPhotoModelDTO[] {
        return this.snagPhotos;
    }

    @MobX.action
    public populateTmpPhotos(files: string[]) {
        this.tmpPhotos = files;
    }

    @MobX.computed
    public get getTmpPhotos(): string[] {
        return this.tmpPhotos;
    }

    public Init(stores: Stores) {}
}
