// Libraries
import { action, computed, observable } from "mobx";
import moment, { Moment } from "moment";
import "moment-timezone";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { StoresInstance } from "Custom/Stores";

export class DashboardModel extends ModelBase<DashboardModel, DashboardModelDTO> {
    // #region Properties

    @observable
    public id = "";

    @observable
    public choicesModifiedDate: Moment | null = null;

    @observable
    public documentsModifiedDate: Moment | null = null;

    @observable
    public progressModifiedDate: Moment | null = null;

    @observable
    public snagsModifiedDate: Moment | null = null;

    @observable
    public inspectionStageModifiedDate: Moment | null = null;

    @observable
    public choicesMadePercentage = 0;

    @observable
    public documentCount = 0;

    @observable
    public snagCount = 0;

    @observable
    public developmentName = "";

    @observable
    public developmentId = "";

    @observable
    public houseTypeName = "";

    @observable
    public houseTypeImageUrl = "";

    @observable
    public houseTypeThumbnailUrl = "";

    @observable
    public plotNumber = "";

    @observable
    public progressName = "";

    @observable
    public availablePlots: AvailablePlotDTO[] = [];

    @observable
    public inspectionStageName = "";

    @observable
    public isChoicesLocked: boolean = false;

    @observable
    public isChoicesToPlot: boolean = false;

    @observable
    public isReportSnags: boolean = false;

    @observable
    public isTargetCompletionVisible: boolean = false;

    @observable
    public isViewBuildStages: boolean = false;

    // #endregion Properties
    @action
    public fromDto = (dto: DashboardModelDTO): void => {
        this.id = dto.id;
        this.choicesModifiedDate = !isEmptyOrWhitespace(dto.choicesModifiedDateUTC) ? moment.tz(dto.choicesModifiedDateUTC, "Europe/London") : null;
        this.documentsModifiedDate = !isEmptyOrWhitespace(dto.documentsModifiedDateUTC) ? moment.tz(dto.documentsModifiedDateUTC, "Europe/London") : null;
        this.progressModifiedDate = !isEmptyOrWhitespace(dto.progressModifiedDateUTC) ? moment.tz(dto.progressModifiedDateUTC, "Europe/London") : null;
        this.snagsModifiedDate = !isEmptyOrWhitespace(dto.snagsModifiedDateUTC) ? moment.tz(dto.snagsModifiedDateUTC, "Europe/London") : null;
        this.inspectionStageModifiedDate = !isEmptyOrWhitespace(dto.inspectionStageModifiedDateUTC) ? moment.tz(dto.inspectionStageModifiedDateUTC, "Europe/London") : null;
        this.choicesMadePercentage = dto.choicesMadePercentage;
        this.documentCount = dto.documentCount;
        this.snagCount = dto.snagCount;
        this.developmentName = dto.developmentName;
        this.developmentId = dto.developmentId;
        this.houseTypeName = dto.houseTypeName;
        this.houseTypeImageUrl = dto.houseTypeImageUrl;
        this.houseTypeThumbnailUrl = dto.houseTypeThumbnailUrl;
        this.plotNumber = dto.plotNumber;
        this.progressName = dto.progressName;
        this.availablePlots = dto.availablePlots;
        this.inspectionStageName = dto.inspectionStageName;
        this.isChoicesLocked = dto.isChoicesLocked;
        this.isChoicesToPlot = dto.isChoicesToPlot;
        this.isReportSnags = dto.isReportSnags;
        this.isTargetCompletionVisible = dto.isTargetCompletionVisible;
        this.isViewBuildStages = dto.isViewBuildStages;
    };

    public toDto = (): DashboardModelDTO => {
        throw "toDto is not supported";
    };
}

export interface DashboardModelDTO {
    id: string;
    choicesModifiedDateUTC: string;
    documentsModifiedDateUTC: string;
    progressModifiedDateUTC: string;
    snagsModifiedDateUTC: string;
    inspectionStageModifiedDateUTC: string;
    choicesMadePercentage: number;
    documentCount: number;
    snagCount: number;
    developmentName: string;
    developmentId: string;
    houseTypeName: string;
    houseTypeImageUrl: string;
    houseTypeThumbnailUrl: string;
    plotNumber: string;
    progressName: string;
    inspectionStageName: string;
    availablePlots: AvailablePlotDTO[];
    isInTenYearWarranty: boolean;
    isChoicesLocked: boolean;
    isChoicesToPlot: boolean;
    isReportSnags: boolean;
    isTargetCompletionVisible: boolean;
    isViewBuildStages: boolean;
}

export interface AvailablePlotDTO {
    id: string;
    plotNumber: string;
    developmentName: string;
}
