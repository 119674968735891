import React, { useState, useEffect } from "react";
import { CircularProgress, Fade, Grid, Typography } from "@material-ui/core";
import { useTimeout } from "Core/Utils";

interface Props {
    delayBeforeShow?: number;
    loadingText?: string;
}

export const Loader = (props: Props) => {
    const [show, setShow] = useState(false);
    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, props.delayBeforeShow);

    useEffect(() => {
        start();
        return () => {
            clear();
        };
    }, []);

    if (show) {
        return (
            <Fade in={true} timeout={2000}>
                <Grid
                    ref={el => {
                        if (el) {
                            el.style.setProperty("flex-direction", "column", "important");
                        }
                    }}
                    style={{ flex: 1 }}
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                    <Grid item>
                        <Typography>{props.loadingText}</Typography>
                    </Grid>
                </Grid>
            </Fade>
        );
    } else return <></>;
};

Loader.defaultProps = {
    delayBeforeShow: 0,
    loadingText: "Loading...",
};
