import { darken, lighten, makeStyles, Theme } from "@material-ui/core";

export const DocumentLinkStyle = makeStyles((theme: Theme) => ({
    root: {
        color: "black",
        "& > div": {
            alignItems: "center",
            display: "flex",
            fontSize: "12px",
            "& > svg": {
                fill: "#575756",
                fontSize: "30px",
                marginRight: "6px",
            },
        },
    },
}));
