import * as MobX from "mobx";

// Custom
import { CPItemModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { BaseStore } from "./BaseStore";
import { Stores } from "../Stores";

export class SnagTypeStore extends BaseStore {
    @MobX.observable
    private snagTypes: CPItemModelDTO[] = [];

    @MobX.action
    public populateSnagTypes(snagTypes: CPItemModelDTO[]) {
        this.snagTypes = snagTypes;
    }

    @MobX.computed
    public get getSnagTypes(): CPItemModelDTO[] {
        return this.snagTypes;
    }

    public Init(stores: Stores) {}
}
