// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { SnagModel, SnagModelDTO } from "./SnagModel";

export class SnagsModel extends ModelBase<SnagsModel> {
    // #region Properties

    @observable
    public snags = observable<SnagModel>([]);

    // #endregion Properties

    @action
    public addFromDto = (dto: SnagModelDTO): void => {
        const snagModel = new SnagModel();

        snagModel.fromDto(dto);

        this.snags.unshift(snagModel);
    };

    @action
    public fromDto = (dto: SnagModelDTO[]): void => {
        for (const snagDto of dto) {
            const snagModel = new SnagModel();

            snagModel.fromDto(snagDto);

            this.snags.push(snagModel);
        }
    };

    public toDto = (): SnagModelDTO[] => {
        throw "toDto is not supported";
    };
}
