// Libraries
import { Button } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "util";

// Custom
import { NavButtonStyle } from "./ButtonStyling";

interface IProps {
    canExecute?: boolean;
    className?: string;
    displayName: string;
    execute?: () => void;
    isSubmit?: boolean;
}

export const NavButton: React.FC<IProps> = props => {
    // #region Code Behind

    const navButtonStyle = NavButtonStyle();

    const getClassNames = (): string => {
        let classNames = navButtonStyle.root;

        if (!isEmptyOrWhitespace(props.className)) {
            classNames = `${classNames} ${props.className}`;
        }

        return classNames;
    };

    const getType = (): "button" | "submit" | "reset" => {
        return !isNullOrUndefined(props.isSubmit) && props.isSubmit ? "submit" : "button";
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const onClick = (): void => {
        if (!isNullOrUndefined(props.execute)) {
            props.execute();
        }
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Button aria-label="close" className={getClassNames()} disabled={isDisabled()} onClick={onClick} type={getType()}>
            {props.displayName}
        </Button>
    ));
};
