// Libraries
import React, { useContext } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Core
import { PrivateRoute } from "Core/Utils";
import { Error2 } from "Core/Views/Error2";
import { NotFound } from "Core/Views/NotFound";

// Custom
import { NavigationBar } from "Custom/Components/NavigationBar";
import { PageTitle } from "Custom/Components/PageTitle";
import { Client } from "Custom/Globals/AppUrls";
import { Stores, StoresContext } from "Custom/Stores";

import { Dashboard } from "Custom/Views/Dashboard";
import { Property } from "Custom/Views/DashboardSubViews/Property";
import { ViewSnag } from "Custom/Views/DashboardSubViews/ViewSnag";
import { Snags } from "Custom/Views/DashboardSubViews/Snags";
import { Documents } from "Custom/Views/DashboardSubViews/Documents";
import { ChoicesArea } from "Custom/Views/DashboardSubViews/ChoicesArea";
import { Choices } from "Custom/Views/DashboardSubViews/Choices";
import { Progress } from "Custom/Views/DashboardSubViews/Progress";
import { Inspection } from "Custom/Views/DashboardSubViews/Inspection";
import { ForgotPasswordPage } from "Custom/Views/ForgotPasswordPage";
import { LoginPage } from "Custom/Views/LoginPage";
import { Logout } from "Custom/Views/Logout";
import { ResetPasswordPage } from "Custom/Views/ResetPasswordPage";
import { SessionExpired } from "Custom/Views/SessionExpired";

export const AppRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);

    return (
        <Switch>
            <Route path={Client.Path.Account.Login} exact component={LoginPage} />
            <Route path={Client.Path.Account.ForgotPassword} exact component={ForgotPasswordPage} />
            <Route path={Client.Path.Account.ForgotPasswordEmail} exact component={ForgotPasswordPage} />
            <Route path={Client.Path.Account.ResetPassword} component={ResetPasswordPage} />
            <Route path={Client.Path.Account.Logout} exact component={Logout} />

            <Route path="/sessionexpired" exact component={SessionExpired} />
            <Route path="/error" exact component={Error2} />
            <Route path="/notfound" exact component={NotFound} />

            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Dashboard}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Dashboard" />
                        <Dashboard key={routeProps.match.params.propertyId || "NotAvailble"} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Property}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Property" />
                        <NavigationBar />
                        <Property key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Inspection}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Build" />
                        <NavigationBar />
                        <Inspection key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Documents}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Documents" />
                        <NavigationBar />
                        <Documents key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Progress}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Progress" />
                        <NavigationBar />
                        <Progress key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Choices}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Choices" />
                        <NavigationBar />
                        <Choices key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.ChoicesArea}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Choices" />
                        <NavigationBar />
                        <ChoicesArea key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Snags}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Snags" />
                        <NavigationBar />
                        <Snags key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
            <PrivateRoute
                exact
                isAllowed={() => store.domain.AccountStore.IsLoggedIn}
                path={Client.Path.Snag}
                render={routeProps => (
                    <React.Fragment>
                        <PageTitle title="Snag" />
                        <NavigationBar />
                        <ViewSnag key={routeProps.match.params.propertyId} />
                    </React.Fragment>
                )}
            />
        </Switch>
    );
};
