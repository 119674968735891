import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import Stickyfill from "stickyfilljs";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { Error } from "Custom/Components/Error";
import { CardPayment } from "Custom/Components/PaymentMethods/CardPayment";
import Lightbox from "Custom/Components/Popups/Lightbox";
import logoMastercard from "Custom/Content/logo-mastercard.svg";
import logoVisa from "Custom/Content/logo-visa.svg";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { PropertyPageStyles, PaymentMethods, PaymentSectionStyles, PaymentHistoryStyles } from "Custom/StylesAppSpecific/DashboardSubViews/PropertyPageStyling";
import { BuyViewModel } from "Custom/ViewModels/BuyViewModel";
import { CARD_TYPE } from "Custom/ViewModels/CardPaymentViewModel";
import { PropertyViewModel } from "Custom/ViewModels/DashboardSubViewModels/PropertyViewModel";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { DashboardViewModel } from "Custom/ViewModels/DashboardViewModel";

enum PAYMENT_METHOD {
    Visa,
    Mastercard,
}

export const Property: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    //console.log("PROPERTYVIEW: PropertyId", router.match.params);
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const propertyPageStyles = PropertyPageStyles();
    const paymentSectionStyles = PaymentSectionStyles();
    const paymentHistoryStyles = PaymentHistoryStyles();

    const [viewModel] = useState(() => new PropertyViewModel(propertyId));
    const [dashboardViewModel] = useState(() => new DashboardViewModel(propertyId));

    const onAccordionChange = () => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        setCanDisplay(!canDisplay);
    };

    const onGalleryAccordionChange = () => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        setCanGalleryDisplay(!canGalleryDisplay);
    };

    const onFloorplanAccordionChange = () => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        setCanFloorplanDisplay(!canFloorplanDisplay);
    };
    const [buyViewModel] = React.useState(() => BuyViewModel.Instance);
    const [selectedPaymentMethod, setPaymentMethod] = React.useState<PAYMENT_METHOD | null>(null);
    const paymentMethods = React.useRef<HTMLDivElement | null>(null);

    const [lightboxImg, setLightboxImg] = useState("");
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxCaption, setLightboxCaption] = useState("");
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [canDisplay, setCanDisplay] = useState(false);
    const [canGalleryDisplay, setCanGalleryDisplay] = useState(false);
    const [canFloorplanDisplay, setCanFloorplanDisplay] = useState(false);

    const setFinish = () => {
        setPaymentSuccess(true);
        viewModel.setPaymentComplete();
    };

    const canDisplayPaymentRow = (): boolean => {
        return (
            viewModel.showStripePaymentRequest &&
            viewModel.hasStripePaymentRequest &&
            (!viewModel.hasPaidStripeReservationPaymentRequest || !viewModel.hasPaidStripeOrderPaymentRequest)
        );
    };

    const canDisplayPaymentMethodRow = (): boolean => {
        return canDisplayPaymentRow();
    };

    const canDisplayPaymentHistoryRow = (): boolean => {
        return viewModel.hasPaidStripeReservationPaymentRequest || viewModel.hasPaidStripeOrderPaymentRequest;
    };

    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    React.useEffect(() => {
        handleResize();
    });

    React.useEffect(() => {
        if (selectedPaymentMethod !== null) {
            window.scrollTo({ top: document.body.scrollHeight - window.innerHeight, behavior: "smooth" });
        }
    }, [selectedPaymentMethod]);

    React.useEffect(() => {
        if (paymentMethods.current) {
            Stickyfill.addOne(paymentMethods.current);
        }
    }, [paymentMethods.current]);

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching property..." />;

    const renderError = () => <Error errorText="Failed to retrieve property information." />;

    const renderTitleRow = () =>
        isDesktop || (
            <Box className={propertyPageStyles.titleRow}>
                <Box className="test1">
                    <Typography variant={"h5"}>{viewModel.PropertyTitle}</Typography>
                    <Typography>{viewModel.Property.houseTypeName}</Typography>
                </Box>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderIntroductionRow = () => (
        <Box className={propertyPageStyles.introduction}>
            <Box className="property_head">
                <Typography className={propertyPageStyles.introductionTitle}>{viewModel.PropertyOverview}</Typography>
                <Typography className={propertyPageStyles.introductionTitle}>{viewModel.PropertyAddress}</Typography>
            </Box>
            {isDesktop && <CloseButton execute={viewModel.navigateToDashboard} />}
        </Box>
    );

    const renderDesriptionRow = () => (
        <Accordion className={propertyPageStyles.description}>
            <AccordionDetails>
                {viewModel.Description != null && viewModel.Description != ""
                    ? isMobile
                        ? showMore
                            ? viewModel.Description
                            : `${viewModel.Description.substring(0, 130)}...`
                        : viewModel.Description
                    : ""}
                <a className={propertyPageStyles.link} onClick={() => setShowMore(!showMore)}>
                    {viewModel.Description != null && viewModel.Description != "" ? (isMobile ? (showMore ? " read less" : "read more") : "") : ""}
                </a>
            </AccordionDetails>
        </Accordion>
    );

    const renderReservationRow = () => (
        <Box className={propertyPageStyles.reservation}>
            <Box>
                {viewModel.CanDisplaySalesAgent && (
                    <Box className={propertyPageStyles.reservationTitle}>
                        <div>Sales agent:</div>
                        <div>{viewModel.Property.salesAgentName}</div>
                    </Box>
                )}
                <Box className={propertyPageStyles.reservationTitle}>
                    <div>Development contact no:</div>
                    <div>
                        <a href={`tel:${viewModel.Property.developmentContactNumber}`}>{viewModel.Property.developmentContactNumber}</a>
                    </div>
                </Box>
                <Box className={propertyPageStyles.reservationTitle}>
                    <div>Development email:</div>
                    <div>
                        <a href={`mailto:${viewModel.Property.developmentContactEmail}`}>{viewModel.Property.developmentContactEmail}</a>
                    </div>
                </Box>
            </Box>
            <Box>
                {viewModel.CanDisplayAgreedPurchasePrice && (
                    <Box className={propertyPageStyles.reservationTitle}>
                        <div>Agreed price:</div>
                        <div>{viewModel.AgreedPurchasePrice}</div>
                    </Box>
                )}
                {viewModel.CanDisplayReservationFeePaid && (
                    <Box className={propertyPageStyles.reservationTitle}>
                        <div>Deposit:</div>
                        <div>{viewModel.ReservationFeePaid}</div>
                    </Box>
                )}
            </Box>
        </Box>
    );

    const renderStatusRow = () => (
        <Box className={propertyPageStyles.status}>
            <Box>
                <Typography variant="h5">Build completion</Typography>
                <Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Target:</div>
                        <div data-cy="BuildTargetDate">{viewModel.BuildTargetDate}</div>
                    </Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Actual:</div>
                        <div data-cy="BuildActualDate">{viewModel.BuildActualDate}</div>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5">Choices complete</Typography>
                <Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Target:</div>
                        <div data-cy="ChoiceTargetDate">{viewModel.ChoicesTargetDate}</div>
                    </Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Actual:</div>
                        <div data-cy="ChoiceActualDate">{viewModel.ChoicesActualDate}</div>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5">Contract exchange</Typography>
                <Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Deadline:</div>
                        <div data-cy="ContractTargetDate">{viewModel.ContractTargetDate}</div>
                    </Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Actual:</div>
                        <div data-cy="ContractActualDate">{viewModel.ContractActualDate}</div>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5">Legal completion</Typography>
                <Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Target:</div>
                        <div data-cy="LegalTargetDate">{viewModel.LegalTargetDate}</div>
                    </Box>
                    <Box className={propertyPageStyles.statusTitle}>
                        <div>Actual:</div>
                        <div data-cy="LegalActualDate">{viewModel.LegalActualDate}</div>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const renderPaymentRow = (): JSX.Element => (
        <Box className={paymentSectionStyles.paymentRow}>
            <Grid container spacing={4} className="payment-module">
                <Grid item md={7} xs={12}>
                    <Typography className={paymentSectionStyles.paymentRowTitle} variant="h5">
                        Payments outstanding
                    </Typography>
                    <Grid container>
                        <Grid className={paymentSectionStyles.paymentRowSubTitle} item sm={6} xs={12}>
                            <div>Reservation fee:</div>
                            <div>{viewModel.getReservationStripePaymentRequest}</div>
                        </Grid>
                        <Grid className={paymentSectionStyles.paymentRowSubTitle} item sm={6} xs={12}>
                            <div>Choices:</div>
                            <div>{viewModel.getChoicesStripePaymentRequest}</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5} xs={12} className={paymentSectionStyles.paymentSelection}>
                    <PaymentMethods className={paymentSectionStyles.paymentMethodRow} ref={paymentMethods}>
                        <Box
                            className={`${paymentSectionStyles.paymentMethod} ${selectedPaymentMethod === PAYMENT_METHOD.Visa ? paymentSectionStyles.paymentMethodSelected : ""}`}
                            onClick={() => setPaymentMethod(PAYMENT_METHOD.Visa)}
                            role="button"
                        >
                            <img alt="Visa" src={logoVisa} />
                        </Box>
                        <Box
                            className={`${paymentSectionStyles.paymentMethod} ${
                                selectedPaymentMethod === PAYMENT_METHOD.Mastercard ? paymentSectionStyles.paymentMethodSelected : ""
                            }`}
                            onClick={() => setPaymentMethod(PAYMENT_METHOD.Mastercard)}
                            role="button"
                        >
                            <img alt="Mastercard" src={logoMastercard} />
                        </Box>
                    </PaymentMethods>
                    <Typography className={paymentSectionStyles.paymentMethodSelectionTitle}>Please select a payment method</Typography>
                </Grid>
            </Grid>
        </Box>
    );

    const renderPaymentMethod = (): JSX.Element => {
        switch (selectedPaymentMethod) {
            case PAYMENT_METHOD.Visa:
                return (
                    <CardPayment
                        key={`card-payment-${CARD_TYPE.Visa}`}
                        cardType={CARD_TYPE.Visa}
                        totalPrice={viewModel.getPaymentPrice}
                        propertyToProcessId={propertyId}
                        reservationRequestId={viewModel.getReservationStripePaymentRequestId}
                        orderRequestId={viewModel.getChoicesStripePaymentRequestId}
                        currentUserId={viewModel.currentUserId}
                        onCancel={() => setPaymentMethod(null)}
                    />
                );

            case PAYMENT_METHOD.Mastercard:
                return (
                    <CardPayment
                        key={`card-payment-${CARD_TYPE.Mastercard}`}
                        cardType={CARD_TYPE.Mastercard}
                        totalPrice={viewModel.getPaymentPrice}
                        propertyToProcessId={propertyId}
                        reservationRequestId={viewModel.getReservationStripePaymentRequestId}
                        orderRequestId={viewModel.getChoicesStripePaymentRequestId}
                        currentUserId={viewModel.currentUserId}
                        onCancel={() => setPaymentMethod(null)}
                    />
                );

            default:
                return <React.Fragment />;
        }
    };

    const renderPaymentHistoryRow = (): JSX.Element => (
        <>
            <Box className={paymentHistoryStyles.paymentRow}>
                <Box>
                    <Typography className={paymentHistoryStyles.paymentRowTitle} variant="h5">
                        Payments history
                    </Typography>
                </Box>
                {viewModel.hasPaidStripeReservationPaymentRequest && (
                    <Box className={paymentHistoryStyles.paymentRowSubTitle}>
                        <div>{viewModel.PaidReservationDate} - Reservation fee:</div>
                        <div>{viewModel.getReservationStripePaymentRequest}</div>
                    </Box>
                )}
                {viewModel.hasPaidStripeOrderPaymentRequest && (
                    <Box className={paymentHistoryStyles.paymentRowSubTitle}>
                        <div>{viewModel.PaidOrderDate} - Choices fee:</div>
                        <div>{viewModel.getChoicesStripePaymentRequest}</div>
                    </Box>
                )}
            </Box>
        </>
    );

    const renderPaymentSection = (): JSX.Element => (
        <Box className={paymentSectionStyles.root}>
            {buyViewModel.getValue("paymentSuccess") && renderSuccessMethod()}
            {canDisplayPaymentRow() && renderPaymentRow()}
            {canDisplayPaymentMethodRow() && renderPaymentMethod()}
            {canDisplayPaymentHistoryRow() && renderPaymentHistoryRow()}
        </Box>
    );

    const renderNewPropertyImages = () => (
        <>
            <Accordion className={propertyPageStyles.accordion} expanded={canGalleryDisplay} onChange={onGalleryAccordionChange()}>
                <AccordionSummary className={propertyPageStyles.accordionSummary} expandIcon={canGalleryDisplay ? <RemoveCircleIcon /> : <AddCircleIcon />}>
                    <Typography>Gallery</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={propertyPageStyles.propertyBox}>
                        <Grid container spacing={4}>
                            {viewModel.getPropertyHouseTypeGeneralImages &&
                                viewModel.getPropertyHouseTypeGeneralImages.map(
                                    image =>
                                        image.imageUrl && (
                                            <Grid item xs={3}>
                                                {/* <img
                                                    src={image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl}
                                                    alt="First image of house"
                                                    className={propertyPageStyles.propertyImages}
                                                    onClick={() => {
                                                        setLightboxImg(image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl);
                                                        setLightboxCaption(image.caption);
                                                        setLightboxOpen(true);
                                                    }}
                                                /> */}
                                                <PrivateBlob
                                                    src={image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl}
                                                    alt="First image of house"
                                                    className={propertyPageStyles.propertyImages}
                                                    onClick={() => {
                                                        setLightboxImg(image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl);
                                                        setLightboxCaption(image.caption);
                                                        setLightboxOpen(true);
                                                    }}
                                                />
                                                <div className={propertyPageStyles.caption}>{image.caption}</div>
                                            </Grid>
                                        ),
                                )}
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion className={propertyPageStyles.accordion} expanded={canFloorplanDisplay} onChange={onFloorplanAccordionChange()}>
                <AccordionSummary className={propertyPageStyles.accordionSummary} expandIcon={canFloorplanDisplay ? <RemoveCircleIcon /> : <AddCircleIcon />}>
                    <Typography>Floorplan</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={propertyPageStyles.propertyBox}>
                        <Grid container spacing={4}>
                            {viewModel.getPropertyHouseTypeFloorplanImages &&
                                viewModel.getPropertyHouseTypeFloorplanImages.map(
                                    image =>
                                        image.imageUrl && (
                                            <Grid item xs={3}>
                                                {/* <img
                                                    src={image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl}
                                                    alt="First image of house"
                                                    className={propertyPageStyles.propertyImages}
                                                    onClick={() => {
                                                        setLightboxImg(image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl);
                                                        setLightboxCaption(image.caption);
                                                        setLightboxOpen(true);
                                                    }}
                                                /> */}
                                                <PrivateBlob
                                                    src={image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl}
                                                    alt="First image of house"
                                                    className={propertyPageStyles.propertyImages}
                                                    onClick={() => {
                                                        setLightboxImg(image.imageUrl.split(".").pop() !== "pdf" ? image.imageUrl : image.thumbnailImageUrl);
                                                        setLightboxCaption(image.caption);
                                                        setLightboxOpen(true);
                                                    }}
                                                />
                                                <div className={propertyPageStyles.caption}>{image.caption}</div>
                                            </Grid>
                                        ),
                                )}
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );

    const renderDevelopment = () => (
        <Accordion className={propertyPageStyles.accordion} expanded={canDisplay} onChange={onAccordionChange()}>
            <AccordionSummary className={propertyPageStyles.accordionSummary} expandIcon={canDisplay ? <RemoveCircleIcon /> : <AddCircleIcon />}>
                <Typography>{viewModel.Property.developmentName}</Typography>
            </AccordionSummary>
            <AccordionDetails>{viewModel.Property.developmentDescription}</AccordionDetails>
        </Accordion>
    );

    const renderSuccessMethod = () =>
        !paymentSuccess ? (
            <div className="paymentSuccess-box">
                <div className="paymentSuccess-box__inner">
                    <span className="paymentSuccess-box__icon">
                        <CheckCircleOutlineIcon />
                    </span>
                    <h6 className="paymentSuccess-box__title">Success</h6>
                </div>
                <Button className="paymentSuccess-box__btn" onClick={() => setFinish()}>
                    Finish
                </Button>
            </div>
        ) : (
            ""
        );

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${propertyPageStyles.root}`}>
            <Box className={dashboardPageStyles.workspace}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderTitleRow()}
                        {renderIntroductionRow()}
                        {renderDesriptionRow()}
                        {renderReservationRow()}
                        {dashboardViewModel.model.isTargetCompletionVisible && renderStatusRow()}
                        {renderPaymentSection()}
                        {renderNewPropertyImages()}
                    </React.Fragment>
                )}
                {viewModel.Property.developmentDescription != null && viewModel.Property.developmentDescription != "" && <React.Fragment>{renderDevelopment()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
            <Lightbox isOpen={lightboxOpen} imageUrl={lightboxImg} imageCaption={lightboxCaption} onClose={setLightboxOpen} />
        </Box>
    ));
};
