import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    lightboxRoot: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    lightboxPaper: {
        backgroundColor: "black",
        padding: "8px",
        boxShadow: "none",
        overflow: "hidden",
        borderRadius: 0,
    },
    lightboxImg: {
        maxWidth: "100%",
        alignSelf: "center", //iOS fix for stretched image in flex container
    },
    closeWrapper: {
        cursor: "pointer",
    },
    closeTxt: {
        fontSize: "12px",
        color: "#FFFFFF",
    },
    closeIcon: {
        fontSize: "14px",
        color: "#FFFFFF",
        marginLeft: "6px",
    },
    caption: {
        marginTop: "5px",
        padding: "5px",
        backgroundColor: "#FFFFFF",
    },
}));

export default useStyles;
