import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information for the choices area page.
 */
export const ChoicesAreaPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    titleRow: {
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexDirection: "column",
        flex: "0 0 auto",
        "& > h5": {
            fontSize: "16px",
            fontWeight: "bold",
            padding: "0 18px",
            textAlign: "center",
        },
        "& > p": {
            fontSize: "12px",
            lineHeight: 1.25,
            padding: "4px 18px 22px 18px",
            textAlign: "center",
        },
    },
    titleImageRow: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "4px",
        flex: "0 0 auto",
        "& > div:first-child": {
            flex: 1,
        },
        "& > div.MuiCardMedia-root": {
            borderRadius: "50%",
            height: "160px",
            margin: "21px",
            width: "160px",
        },
        "& > div:last-child": {
            alignItems: "flex-start",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
        },
    },
    submitButton: {
        maxWidth: "295px",
        margin: "0 auto",
        padding: "18px 0px",
        "& > button": {
            width: "100%",
        },
    },
    productGroupAccordionDetails: {
        padding: "0px",
    },
    productGroupTitleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: "1 1 auto",
        "& > p": {
            fontSize: "14px",
            fontWeight: "bold",
            marginRight: "10px",
        },
    },
    productGroupCounterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "auto",
    },
    productGroupChoiceCounterContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "20px",
        alignItems: "center",
        "& > svg": {
            fontSize: "15px",
            stroke: "black",
            strokeWidth: "0.5px",
        },
    },
    productGroupChoiceCounterContainerComplete: {
        color: "#3AAA35",
        "& > svg": {
            stroke: "3AAA35",
        },
    },
    accordion: {
        "& > div.MuiCollapse-container": {
            background: "none",
        },
    },
    accordionSummary: {},
    [theme.breakpoints.up("md")]: {
        titleRow: {
            background: "none",
            flexDirection: "row",
        },
        titleImageRow: {
            marginTop: "0px",
            "& > div.MuiCardMedia-root": {
                height: "132px",
                margin: "17px 15px",
                width: "132px",
            },
        },
        titleTextRow: {
            flex: "1",
            margin: "17px 18px",
            "& > div": {
                padding: "5px 0 0 0",
            },
            "& > h5": {
                fontSize: "16px",
                fontWeight: "bold",
                padding: "18px 0 0 0",
                textAlign: "left",
            },
            "& > p": {
                fontSize: "12px",
                lineHeight: 1.25,
                padding: "9px 0 0 0",
                textAlign: "left",
            },
        },
        submitButton: {
            padding: "30px 0",
        },
        accordion: {
            "& > div.MuiCollapse-container": {
                backgroundColor: "#F9F9F9",
            },
        },
        accordionSummary: {
            margin: "0 18px",
            "& > div.MuiAccordionSummary-content": {
                marginLeft: "-18px",
            },
            "& > div.MuiAccordionSummary-expandIcon": {
                marginRight: "-18px",
            },
        },
    },
}));
