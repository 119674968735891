// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { AreaSummaryModel } from "./AreaSummaryModel";
import { ChoicesSummaryModelDTO } from "Custom/ViewModels/DashboardSubViewModels/ChoicesSummaryViewModel";

// Custom
//import { ChoiceModel, ChoiceModelDTO } from "./ChoiceModel";

export class ChoicesSummaryModel extends ModelBase<ChoicesSummaryModel, ChoicesSummaryModelDTO> {
    // #region Properties

    @observable
    public areas: AreaSummaryModel[] = [];

    // #endregion Properties

    @action
    public fromDto = (dto: ChoicesSummaryModelDTO): void => {
        for (const areaDto of dto.areasSummary) {
            const area = new AreaSummaryModel();

            let productGroups = dto.productGroupsSummary.filter(pg => pg.areaId === areaDto.areaId);
            //let products = dto.products.filter(p => p.productGroupId === productGroupDto.id);
            //let productVariants = dto.productVariants.filter(pv => products.findIndex(p => p.id === pv.productId) !== 0)

            area.fromDto({
                areaSummary: areaDto,
                productGroupsSummary: productGroups,
                productsSummary: dto.productsSummary,
            });

            this.areas.push(area);
        }
        // for (const productGroupDto of dto.productGroups) {
        //     const productGroup = new ProductGroupModel();

        //     let products = dto.products.filter(p => p.productGroupId === productGroupDto.id);
        //     let productVariants = dto.productVariants.filter(pv => products.findIndex(p => p.id === pv.productId) !== 0)

        //     productGroup.fromDto({
        //         productGroup: productGroupDto,
        //         products: products,
        //         productVariants: productVariants
        //     });

        //     this.productGroups.push(productGroup);
        // }
    };

    public toDto = (): ChoicesSummaryModelDTO => {
        throw "toDto is not supported";
    };
}
