// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { ChoicesSummaryModel } from "Custom/Models/DashboardModels/ChoicesSummaryModel";
import { AreaSummaryModelDTO } from "Custom/Models/DashboardModels/AreaSummaryModel";
import { ProductGroupSummaryModelDTO } from "Custom/Models/DashboardModels/ProductGroupSummary";
import { ProductSummaryModelDTO } from "Custom/Models/DashboardModels/ProductSummary";
import { AreaSummaryViewModel } from "Custom/ViewModels/DashboardSubViewModels/AreaSummaryViewModel";
import { StoresInstance } from "../../Stores";
import { OrderModelDTO } from "Custom/Models/DashboardModels/ChoicesAreaModel";
import { ProductBespokeModelDTO } from "Custom/Models/DashboardModels/ProductBespoke";
import { Format } from "Custom/Utils";

export class ChoicesSummaryViewModel extends ViewModelBase<ChoicesSummaryModel> {
    @observable
    private areaSummaryViewModels: AreaSummaryViewModel[] = [];

    @observable
    private productBespokeViewModels: ProductBespokeModelDTO[] = [];

    @observable
    public choicesSummaryModels = observable<ChoicesSummaryModelDTO>([]);

    @observable
    public areaId: any[] = [];

    constructor(propertyId: string) {
        super(new ChoicesSummaryModel());
        this.setDecorators(ChoicesSummaryModel);

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId);
        }
    }

    // #region Properties

    @computed
    public get areas() {
        return this.areaSummaryViewModels;
    }

    @computed
    public get productBespoke() {
        return this.productBespokeViewModels;
    }

    @computed
    public get hasSelectedChoices() {
        return this.areaSummaryViewModels.length > 0;
    }

    @computed
    public get choicesPOACount() {
        let poaCount = 0;
        const items = this.choicesSummaryModels.find(i => i.productsSummary != undefined)!;
        const productsSummary = items && items.productsSummary;

        if (productsSummary) {
            productsSummary.forEach(item => {
                const itemC = parseInt(item.cost, undefined);
                if (item.isPOA && !item.isIncluded && itemC === 0) {
                    poaCount++;
                }
            });
        }

        const bespokeItems = this.choicesSummaryModels.find(i => i.productsBespoke != undefined)!;
        const productsBespoke = bespokeItems && bespokeItems.productsBespoke;

        productsBespoke &&
            productsBespoke.forEach((item: any) => {
                const itemC = parseInt(item.cost, undefined);
                if (item.poa && itemC === 0) {
                    poaCount++;
                }
            });

        return poaCount;
    }

    @computed
    public get choicesSubTotal() {
        const items = this.choicesSummaryModels.find(i => i.productsSummary != undefined)!;
        const productsSummary = items && items.productsSummary;
        let choicesSubTotal = 0;

        productsSummary &&
            productsSummary.forEach((item: any) => {
                if (item.cost) {
                    if (item.cost !== "-" && item.cost !== "P" && item.cost !== "PO" && item.cost !== "POA") {
                        if (item.quantity > 0) {
                            choicesSubTotal += parseFloat(item.cost) * item.quantity;
                        } else {
                            choicesSubTotal += parseFloat(item.cost);
                        }
                    }
                }
            });

        const bespokeItems = this.choicesSummaryModels.find(i => i.productsBespoke != undefined)!;
        const productsBespoke = bespokeItems && bespokeItems.productsBespoke;

        productsBespoke &&
            productsBespoke.forEach((item: any) => {
                if (item.cost) {
                    if (item.cost !== "-" && item.cost !== "P" && item.cost !== "PO" && item.cost !== "POA") {
                        choicesSubTotal += parseFloat(item.cost);
                    }
                }
            });

        return choicesSubTotal;
    }

    @computed
    public get choicesTotal() {
        const items = this.choicesSummaryModels.find(i => i.order != undefined);

        const order = items && items.order;

        let choicesTotal = this.choicesSubTotal;

        if (order && order.costRevision != undefined) {
            choicesTotal += order.costRevision;
        }

        return choicesTotal;
    }

    @computed
    public get choicesPaymentCost() {
        const items = this.choicesSummaryModels.find(i => i.order != undefined);

        const order = items && items.order;

        let choicesPaymentCost = 0;

        if (order && order.choicesPaymentCost != undefined) {
            choicesPaymentCost = order.choicesPaymentCost;
        }

        return choicesPaymentCost;
    }

    @computed
    public get outstandingBalanceTotal() {
        const items = this.choicesSummaryModels.find(i => i.productsSummary != undefined)!;
        const productsSummary = items && items.productsSummary;
        //outstanding balance = choices total - cost of all paid products

        let paidProductsCost = 0;
        let retVal = 0;
        //get total cost of all paid products
        productsSummary &&
            productsSummary.forEach(p => {
                const isCostNotANum = isNaN(parseFloat(p.cost));
                if (p.productName !== "None of the above" && p.isPaid && !isCostNotANum) {
                    const productQty = p.quantity == 0 ? 1 : p.quantity;
                    paidProductsCost += parseFloat(p.cost) * productQty;
                }
            });

        const bespokeItems = this.choicesSummaryModels.find(i => i.productsBespoke != undefined)!;
        const productsBespoke = bespokeItems && bespokeItems.productsBespoke;

        productsBespoke &&
            productsBespoke.forEach((item: any) => {
                const isCostNotANum = isNaN(parseFloat(item.cost));
                if (item.productName !== "None of the above" && item.isPaid && !isCostNotANum) {
                    paidProductsCost += parseFloat(item.cost);
                }
            });

        retVal = this.choicesTotal - paidProductsCost;

        if (this.choicesPaymentCost) retVal = retVal - this.choicesPaymentCost;
        return Format.currencyFormat(retVal.toString(), true);
    }

    @computed
    public get orderValue() {
        let formattedValue = "";

        if (this.choicesTotal) {
            formattedValue = Format.currencyFormat(this.choicesTotal.toString(), true);

            if (this.choicesPOACount) {
                if (this.choicesPOACount > 0) {
                    formattedValue = formattedValue + " + (" + this.choicesPOACount + " POA)";
                }
            }
        }
        return formattedValue;
    }

    // #endregion Properties
    public addAreaId = (id: any) => {
        this.areaId.push(id);
    };

    public navigateToChoicesArea = (propertyId: string, areaId: string) => {
        this.history.push(`/choicesarea/${propertyId}/${areaId}`);
    };

    private load = async (id: string): Promise<void> => {
        try {
            const apiResult = await this.Get<ChoicesSummaryModelDTO>(Server.Api.Choices.GetChoicesSummary + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);

                this.createViewModels();
                this.setChoicesModels(apiResult.payload);
            } else {
                // Error
            }
        } catch (exception) {
            // Exceptional error
        } finally {
            // Finally
        }
    };

    @action
    private createViewModels() {
        for (const area of this.model.areas) {
            this.areaSummaryViewModels.push(new AreaSummaryViewModel(area));
        }
    }

    @action
    private setChoicesModels(choicesSummaryViewModel: ChoicesSummaryModelDTO) {
        this.choicesSummaryModels.push(choicesSummaryViewModel);

        const choicesSummaryModel = this.choicesSummaryModels.find(i => i.productsBespoke != null);
        choicesSummaryModel &&
            choicesSummaryModel.productsBespoke &&
            choicesSummaryModel.productsBespoke.forEach(item => {
                this.productBespokeViewModels.push(item);
            });
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}

export interface ChoicesSummaryModelDTO {
    areasSummary: AreaSummaryModelDTO[];
    productGroupsSummary: ProductGroupSummaryModelDTO[];
    productsSummary: ProductSummaryModelDTO[];
    order: OrderModelDTO;
    productsBespoke: ProductBespokeModelDTO[];
}
