// Libraries
import { action, computed, observable } from "mobx";

// Core
import { StoresInstance } from "Core/Base";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class PageHeaderViewModel extends ViewModelBase<any> {
    constructor() {
        super({});
    }

    @action
    public navigateToUserDetails = (): void => {
        this.history.push("mydetails");
    };

    @computed
    public get companyName(): string {
        return "";
    }

    @computed
    public get canDisplayCompanyLogo(): boolean {
        return !isEmptyOrWhitespace(StoresInstance.domain.AccountStore.CompanyLogo);
    }

    @computed
    public get companyLogo(): string {
        return StoresInstance.domain.AccountStore.CompanyLogo;
    }

    @computed
    public get userName(): string {
        return StoresInstance.domain.AccountStore.DisplayName;
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
