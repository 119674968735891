// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { NewSnagModel, NewSnagRelatedModelDTO } from "Custom/Models/DashboardModels/NewSnagModel";
import { SnagModelDTO, SnagPhotoModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { SnagsViewModel } from "./SnagsViewModel";
import { StoresInstance } from "../../Stores";
import { SnagPhotoStore } from "Custom/Stores/Domain/SnagPhotoStore";

export class NewSnagViewModel extends ViewModelBase<NewSnagModel> {
    private snagsViewModel: SnagsViewModel;

    public imageSubDirectory: string = StoresInstance.domain!.AccountStore.ViewAsCompanyId + "/productsnag";
    public longestSideLength: number = 720;

    private snagPhotoStore: SnagPhotoStore = StoresInstance.domain.SnagPhotoStore;

    constructor(snagsViewModel: SnagsViewModel, propertyId: string) {
        super(new NewSnagModel());
        this.setDecorators(NewSnagModel);

        this.snagsViewModel = snagsViewModel;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId);
        }
    }

    // #region Server Properties and Actions

    @observable
    public haveSubmittedToServer = false;

    @action
    public setHaveSubmittedToServer = (value: boolean) => {
        this.haveSubmittedToServer = value;
    };

    @observable
    public serverValidationMessage = "";

    @action
    public setServerValidationMessage = (value: string) => {
        this.serverValidationMessage = value;
    };

    @action
    public addTmpPhoto(file: File) {
        let tmpArr = this.snagPhotoStore.getSnagPhotos.slice();

        let blob = URL.createObjectURL(file);

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            var base64data = reader.result;
            let item: SnagPhotoModelDTO = {
                id: "",
                fileName: file.name,
                azureFileName: blob,
                azureFileNameFullResolution: blob,
                azureThumbnailURL: blob,
                base64: base64data as string,
                resolvedImage: false,
                propertySnagId: "",
                uploadedIdentityId: "",
                uploadedBy: "",
                uploadedDate: new Date().toUTCString(),
                originalIsDeletedState: false,
                isDeleted: false,
                type: 0,
            };
            tmpArr.push(item);
            this.snagPhotoStore.populateSnagPhotos(tmpArr);
        };
    }

    @action
    public removePhoto(index: number) {
        let tmpObj = this.snagPhotoStore.getSnagPhotos[index];
        if (tmpObj) {
            if (tmpObj.id) {
                this.snagPhotoStore.getSnagPhotos[index].isDeleted = true;
            } else {
                this.snagPhotoStore.getSnagPhotos.splice(index, 1);
            }
        }
    }

    private load = async (id: string): Promise<void> => {
        try {
            this.setServerValidationMessage("");

            const apiResult = await this.Get<NewSnagRelatedModelDTO>(Server.Api.Snags.GetSnagRelated + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                if (apiResult.errors.length > 0) {
                    const error = apiResult.errors[0];

                    if (!isEmptyOrWhitespace(error.message)) {
                        this.setServerValidationMessage(error.message);
                    } else {
                        this.setServerValidationMessage("There was an unknown error getting data related for the new snag.");
                    }
                }
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error getting data related for the new snag.");
        } finally {
            // Finally
        }
    };

    public save = async (propertyId: string): Promise<void> => {
        try {
            const isHouseAreaValid = !isEmptyOrWhitespace(this.model.areaId);
            const isSnagTypeValid = !isEmptyOrWhitespace(this.model.title) && this.model.title.length <= 75;
            const isDetailsValid = !isEmptyOrWhitespace(this.model.details);

            if (isHouseAreaValid && isSnagTypeValid && isDetailsValid) {
                this.setTitleErrorMessage("");
                this.setAreaErrorMessage("");
                this.setDetailsErrorMessage("");

                this.setServerValidationMessage("");
                this.setHaveSubmittedToServer(true);

                let model = this.model.toDto();

                model.snag.propertyId = propertyId;
                model.snagPhotos = this.getSnagPhotos;

                const apiResult = await this.Post<SnagModelDTO>(Server.Api.Snags.Upsert, model);

                if (apiResult.wasSuccessful) {
                    this.snagsViewModel.add(apiResult.payload, propertyId);
                    this.snagPhotoStore.populateSnagPhotos([]);
                } else {
                    if (apiResult.errors.length > 0) {
                        const error = apiResult.errors[0];

                        if (!isEmptyOrWhitespace(error.message)) {
                            this.setServerValidationMessage(error.message);
                        } else {
                            this.setServerValidationMessage("There was an unknown error saving the snag.");
                        }
                    }
                }
            } else {
                this.setTitleErrorMessage("");
                this.setAreaErrorMessage("");
                this.setDetailsErrorMessage("");

                if (isEmptyOrWhitespace(this.model.title)) {
                    this.setTitleErrorMessage("You must enter a brief description");
                } else if (this.model.title.length > 75) {
                    this.setTitleErrorMessage("Brief description must be 75 characters or less");
                }

                this.setAreaErrorMessage(isEmptyOrWhitespace(this.model.areaId) ? "You must select an area" : "");
                this.setDetailsErrorMessage(isEmptyOrWhitespace(this.model.details) ? "You must enter some detail" : "");
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error saving the snag.");
        } finally {
            // Finally
        }
    };

    @observable
    public areaErrorMessage = "";

    @action
    public setAreaErrorMessage = (value: string) => {
        this.areaErrorMessage = value;
    };

    @observable
    public titleErrorMessage = "";

    @action
    public setTitleErrorMessage = (value: string) => {
        this.titleErrorMessage = value;
    };

    @observable
    public detailsErrorMessage = "";

    @action
    public setDetailsErrorMessage = (value: string) => {
        this.detailsErrorMessage = value;
    };

    public tryLoadAgain = (propertyId: string) => {
        this.load(propertyId);
    };

    public trySaveAgain = () => {
        this.setServerValidationMessage("");
        this.setHaveSubmittedToServer(false);
    };

    // #endregion Server Properties and Actions

    // #region Can Display Properties

    @observable
    public canDisplay = false;

    @action
    public setCanDisplay = (value: boolean): void => {
        this.canDisplay = value;
    };

    @computed
    public get canDisplayBusy(): boolean {
        return this.IsLoading;
    }

    @computed
    public get canDisplayLoadError(): boolean {
        return !this.haveSubmittedToServer && !this.IsLoading && !isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplaySubmittedError(): boolean {
        return this.haveSubmittedToServer && !this.IsLoading && !isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplayForm(): boolean {
        return !this.canDisplayLoadError && !this.canDisplaySubmittedError && !this.haveSubmittedToServer && !this.IsLoading;
    }

    @computed
    public get getSnagPhotos(): SnagPhotoModelDTO[] {
        return this.snagPhotoStore.getSnagPhotos.filter(sp => !sp.isDeleted && (sp.id === undefined || sp.id === ""));
    }

    @computed
    public get getSnagPhotosTmp(): string[] {
        return this.snagPhotoStore.getTmpPhotos;
    }

    // #endregion Can Display Properties

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // public isFieldValid(fieldName: keyof FieldType<any>): boolean {
    //     const { isValid, errorMessage } = this.validateDecorators(fieldName);

    //     this.setError(fieldName, errorMessage);
    //     this.setValid(fieldName, isValid);

    //     return isValid;
    // }

    // public isFieldValid(fieldName: "title" | "details" | "areaId", value: any): boolean {
    //     if (fieldName === "title" || fieldName === "details" || fieldName === "areaId") {
    //         const { isValid, errorMessage } = this.validateDecorators(fieldName);

    //         this.setError(fieldName, errorMessage);
    //         this.setValid(fieldName, isValid);

    //         return isValid;
    //     }
    //     else
    //     {
    //         return true;
    //     }
    // }

    // #endregion Boilerplate
}
