// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { OrderAreaProductGroupModelDTO, ProductGroupAndRelatedModelDTO, ProductGroupModel } from "./ProductGroupModel";
import { ChoicesAreaAndRelatedModelDTO, UpsertOrderModelDTO } from "Custom/ViewModels/DashboardSubViewModels/ChoicesAreaViewModel";
import { OrderAreaProductGroupProductModelDTO } from "./ProductModel";

// Custom
//import { ChoiceModel, ChoiceModelDTO } from "./ChoiceModel";

export class ChoicesAreaModel extends ModelBase<ChoicesAreaModel, ChoicesAreaAndRelatedModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public areaName: string = "";

    @observable
    public orderId: string = "";

    @observable
    public isOrderComplete: boolean = false;

    @observable
    public orderAreaId: string = "";

    @observable
    public isOrderAreaComplete: boolean = false;

    @observable
    public propertyId: string = "";

    @observable
    public orderCreatedDate: string = "";

    @observable
    public orderCreatedByIdentityUserId: string = "";

    @observable
    public orderLockedDate: string = "";

    @observable
    public orderCompletedDate: string = "";

    @observable
    public orderCostRevision: number = 0;

    @observable
    public orderConcurrencyToken: string = "";

    @observable
    public orderAreaNotes: string = "";

    @observable
    public contactId: string = "";

    @observable
    public imageUrl = "";

    @observable
    public originalImageUrl = "";

    @observable
    public thumbnailImageUrl = "";

    @observable
    public productGroups: ProductGroupModel[] = [];

    @observable
    public isChoicesLocked: boolean = false;

    @observable
    public choicesPaymentCost: number = 0;

    // #endregion Properties

    @action
    public fromDto = (dto: ChoicesAreaAndRelatedModelDTO): void => {
        this.id = dto.area.id;
        this.areaName = dto.area.areaName;
        this.orderId = dto.area.orderId;
        this.isOrderComplete = dto.area.isOrderComplete;
        this.orderAreaId = dto.area.orderAreaId;
        this.isOrderAreaComplete = dto.area.isOrderAreaComplete;
        this.propertyId = dto.area.propertyId;
        this.orderCreatedDate = dto.area.orderCreatedDate;
        this.orderCreatedByIdentityUserId = dto.area.orderCreatedByIdentityUserId;
        this.orderLockedDate = dto.area.orderLockedDate;
        this.orderCompletedDate = dto.area.orderCompletedDate;
        this.orderCostRevision = dto.area.orderCostRevision;
        this.orderConcurrencyToken = dto.area.orderConcurrencyToken;
        this.orderAreaNotes = dto.area.orderAreaNotes;
        this.contactId = dto.area.contactId;
        this.imageUrl = dto.area.imageUrl;
        this.originalImageUrl = dto.area.originalImageUrl;
        this.thumbnailImageUrl = dto.area.thumbnailImageUrl;
        this.isChoicesLocked = dto.isChoicesLocked;

        for (const productGroupDto of dto.productGroups) {
            const productGroup = new ProductGroupModel();

            let products = dto.products.filter(p => p.productGroupId === productGroupDto.id);
            let productVariants = dto.productVariants.filter(pv => products.findIndex(p => p.id === pv.productId) !== 0);

            productGroup.fromDto({
                productGroup: productGroupDto,
                products: products,
                productVariants: productVariants,
                specificationHouseTypeProducts: dto.specificationHouseTypeProducts,
                specificationProducts: dto.specificationProducts,
                productsSummary: dto.productsSummary,
            });

            this.productGroups.push(productGroup);
        }
    };

    public toDto = (): UpsertOrderModelDTO => {
        let order: OrderModelDTO = {
            id: this.orderId,
            contactId: this.contactId,
            createdByIdentityUserId: this.orderCreatedByIdentityUserId,
            lockedByIdentityUserId: "",
            propertyId: this.propertyId,
            createdDate: this.orderCreatedDate,
            lockedDate: this.orderLockedDate,
            isComplete: this.isOrderComplete,
            completedDate: this.orderCompletedDate,
            costRevision: this.orderCostRevision,
            concurrencyToken: this.orderConcurrencyToken,
            isDeleted: false,
            isLocked: false,
            choicesPaymentCost: this.choicesPaymentCost,
        };

        let orderArea: OrderAreaModelDTO = {
            id: this.orderAreaId,
            orderId: this.orderId,
            areaId: this.id,
            isComplete: this.isOrderAreaComplete,
            notes: this.orderAreaNotes,
        };

        let orderAreaProductGroups: OrderAreaProductGroupModelDTO[] = [];

        let orderAreaProductGroupProducts: OrderAreaProductGroupProductModelDTO[] = [];

        this.productGroups.forEach(pg => {
            let productGroup: OrderAreaProductGroupModelDTO = pg.toDto();

            productGroup.areaId = this.id;

            orderAreaProductGroups.push(productGroup);

            pg.products
                .filter(p => p.isSelected === true)
                .forEach(p => {
                    let product: OrderAreaProductGroupProductModelDTO = p.toDto();

                    let productPrice: string;

                    if (p.isQuantityAllowed && p.getPriceOnAddQuantity()) {
                        productPrice = p.getPriceOnAddQuantity();
                    } else if (p.isOptional) {
                        productPrice = p.extraPrice;
                    } else if (p.price) {
                        productPrice = p.price;
                    } else {
                        productPrice = p.productPrice;
                    }

                    if (isNaN(parseFloat(productPrice))) {
                        productPrice = "0.00";
                    }

                    product.orderAreaProductGroupId = pg.id;
                    product.orderAreaId = this.id;
                    product.isPOA = p.productPOA;
                    product.cost = productPrice;
                    product.isMailSent = p.isMailSent;

                    orderAreaProductGroupProducts.push(product);
                });
        });

        let model: UpsertOrderModelDTO = {
            order: order,
            orderArea: orderArea,
            orderAreaProductGroups: orderAreaProductGroups,
            orderAreaProductGroupProducts: orderAreaProductGroupProducts,
        };

        return model;
    };
}

export interface ChoicesAreaModelDTO {
    id: string;
    areaName: string;
    orderId: string;
    isOrderComplete: boolean;
    orderAreaId: string;
    isOrderAreaComplete: boolean;
    propertyId: string;
    orderCreatedDate: string;
    orderCreatedByIdentityUserId: string;
    orderLockedDate: string;
    orderCompletedDate: string;
    orderCostRevision: number;
    orderConcurrencyToken: string;
    orderAreaNotes: string;
    contactId: string;
    imageUrl: string;
    originalImageUrl: string;
    thumbnailImageUrl: string;
}

export interface OrderModelDTO {
    id: string;
    contactId: string;
    createdByIdentityUserId: string;
    lockedByIdentityUserId: string;
    propertyId: string;
    createdDate: string;
    lockedDate: string;
    isComplete: boolean;
    completedDate: string;
    costRevision: number;
    choicesPaymentCost: number;
    concurrencyToken: string;
    isDeleted: boolean;
    isLocked: boolean;
}

export interface OrderAreaModelDTO {
    id: string;
    orderId: string;
    areaId: string;
    isComplete: boolean;
    notes: string;
}
