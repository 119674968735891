// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { DocumentModel, DocumentModelDTO } from "./DocumentModel";

export class DocumentsModel extends ModelBase<DocumentsModel, DocumentsModelDTO> {
    // #region Properties

    @observable
    public categoryName = "";

    @observable
    public documents = observable<DocumentModel>([]);

    @observable
    public canDisplay = false;

    @action
    public setCanDisplay = (value: boolean): void => {
        this.canDisplay = value;
    };

    // #endregion Properties

    @action
    public fromDto = (dto: DocumentsModelDTO): void => {
        this.categoryName = dto.categoryName;

        for (const document of dto.documents) {
            const documentModel = new DocumentModel();

            documentModel.fromDto(document);

            this.documents.push(documentModel);
        }
    };

    public toDto = (): DocumentsModelDTO[] => {
        throw "toDto is not supported";
    };
}

export interface DocumentsModelDTO {
    categoryName: string;
    documents: DocumentModelDTO[];
}
