import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { Error } from "Custom/Components/Error";

import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { ChoicesPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/ChoicesPageStyling";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { ChoicesViewModel } from "Custom/ViewModels/DashboardSubViewModels/ChoicesViewModel";
import { ChoicesSummary } from "./ChoicesSummary";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

export const Choices: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    //console.log("CHOICESVIEW: PropertyId", router.match.params);
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const choicesPageStyles = ChoicesPageStyles();

    const [viewModel] = useState(() => new ChoicesViewModel(propertyId));

    const onChoicesAreaClick = (areaId: string) => {
        viewModel.navigateToChoicesArea(propertyId, areaId);
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching choices..." />;

    const renderError = () => <Error errorText="Failed to retrieve the choices." />;

    const renderTitleRow = () =>
        isDesktop ? (
            <React.Fragment />
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Choices</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderIntroductionRow = () => {
        const introductionText = () => {
            if (viewModel.isChoicesLocked) {
                return (
                    <>
                        <Typography className={choicesPageStyles.introductionTitle}>Your choices</Typography>

                        <Typography className={choicesPageStyles.introductionSubTitle}>
                            Currently, you can only view your choices. Please contact your sales agent if you need to make any changes.
                        </Typography>
                    </>
                );
            } else {
                return (
                    <>
                        <Typography className={choicesPageStyles.introductionTitle}>Choose how you want your house</Typography>
                        <Typography className={choicesPageStyles.introductionSubTitle}>Select an area of the house to select your options and choose your upgrades.</Typography>
                    </>
                );
            }
        };

        return (
            <Box className={choicesPageStyles.introduction}>
                <Box>{introductionText()}</Box>
                {isDesktop && <CloseButton execute={viewModel.navigateToDashboard} />}
            </Box>
        );
    };

    const renderChoicesAreas = () => (
        <Box className={choicesPageStyles.choicesAreas}>
            <Box>
                {viewModel.ChoicesAreas.map((choicesArea, index) => (
                    <Card className={choicesPageStyles.choicesArea} key={index}>
                        <CardActionArea onClick={() => onChoicesAreaClick(choicesArea.id)}>
                            <PrivateBlob src={choicesArea.areaImage} title="" cardMedia={true} />
                            <CardContent>
                                <Typography className={choicesPageStyles.choicesAreaTitle}>
                                    <b>{choicesArea.areaName}</b>
                                </Typography>
                                <Typography className={choicesPageStyles.choicesAreaSubTitle}>
                                    <b>{`${choicesArea.productGroupsUsed} of ${choicesArea.productGroupsAvailable}`}</b> choices made
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </Box>
    );

    const renderChoicesSummary = () => (
        <Box className={choicesPageStyles.choicesAreasSummaryContainer}>
            <Box>
                <ChoicesSummary canEditChoices={!viewModel.isChoicesLocked} propertyId={propertyId} />
            </Box>
        </Box>
    );

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${choicesPageStyles.root}`}>
            <Box className={dashboardPageStyles.workspace}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderTitleRow()}
                        {renderIntroductionRow()}
                        {renderChoicesAreas()}
                        {renderChoicesSummary()}
                    </React.Fragment>
                )}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
        </Box>
    ));
};
