// Libraries
import { computed } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { SnagModel } from "Custom/Models/DashboardModels/SnagModel";
import moment from "moment";
import { SnagStatusType } from "../../Models/Enums/SnagStatusType";

export class SnagViewModel extends ViewModelBase<SnagModel> {
    constructor(model: SnagModel) {
        super(new SnagModel());

        this.model = model;
    }

    @computed
    public get Details(): string {
        return this.model.details;
    }

    @computed
    public get isCompleted(): boolean {
        return this.model.resolved;
    }

    @computed
    public get isRejected(): boolean {
        return this.model.snagStatus === SnagStatusType.Rejected;
    }

    @computed
    public get FormattedSnagStatus(): string {
        if (this.isCompleted) {
            const resolvedDate = this.model.resolvedDate !== null && this.model.resolvedDate !== undefined ? moment(this.model.resolvedDate).format("DD/MM/YYYY") : "Unknown date";
            return `COMPLETED: ${resolvedDate}`;
        }

        if (this.isRejected) {
            const snagStatusUpdatedDate =
                this.model.snagStatusUpdatedDate !== null && this.model.snagStatusUpdatedDate !== undefined
                    ? moment(this.model.snagStatusUpdatedDate).format("DD/MM/YYYY")
                    : "Unknown date";
            return `REJECTED: ${snagStatusUpdatedDate}`;
        }

        // If reported or approved
        const reportedDate = this.model.reportedDate !== null && this.model.reportedDate !== undefined ? moment(this.model.reportedDate).format("DD/MM/YYYY") : "Unknown date";
        return `REPORTED: ${reportedDate}`;
    }

    @computed
    public get Number(): string {
        return this.model.snagNumber;
    }

    @computed
    public get NumberAndArea(): string {
        let areaName = this.model.areaName !== null && this.model.areaName !== undefined ? this.model.areaName.toUpperCase() : "";
        return `${this.model.snagNumber} - ${areaName}`;
    }

    @computed
    public get Title(): string {
        return this.model.title;
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
