// Libraries
import { action, computed, observable } from "mobx";
import moment, { Moment } from "moment";
import "moment-timezone";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "util";

export class ProgressActivityModel extends ModelBase<ProgressActivityModel, ProgressActivityModelDTO> {
    // #region Properties

    @observable
    public id = "";

    @observable
    public parentId = "";

    @observable
    public activityColour = "";

    @observable
    public activityName = "";

    @observable
    public completedDate: Moment | null = null;

    @observable
    public isCompleted: boolean = false;

    @observable
    public isNotApplicable: boolean = false;

    @observable
    public activities = observable<ProgressActivityModel>([]);

    // #endregion Properties

    @computed
    public get Activities() {
        return this.activities;
    }

    @computed
    public get hasActivities() {
        return this.activities !== null && this.activities !== undefined && this.activities.length > 0;
    }

    @computed
    public get completedDateFormatted() {
        return this.completedDate !== null && this.completedDate !== undefined ? this.completedDate.format("DD/MM/YYYY") : "";
    }

    @action
    public fromDto = (dto: ProgressActivityModelDTO): void => {
        this.id = dto.id;
        this.parentId = dto.parentId;
        this.activityColour = dto.activityColour;
        this.activityName = dto.activityName;
        this.completedDate = !isEmptyOrWhitespace(dto.completedDateUTC) ? moment.tz(dto.completedDateUTC, "Europe/London") : null;
        this.isCompleted = dto.isComplete;
        this.isNotApplicable = dto.isNotApplicable;

        if (!isNullOrUndefined(dto.subActivities)) {
            for (const activity of dto.subActivities) {
                const activityModel = new ProgressActivityModel();

                activityModel.fromDto(activity);

                this.activities.push(activityModel);
            }
        }
    };

    public toDto = (): ProgressActivityModelDTO => {
        throw "toDto is not supported";
    };
}

export interface ProgressActivityModelDTO {
    id: string;
    parentId: string;
    activityColour: string;
    activityName: string;
    completedDateUTC: string;
    isComplete: boolean;
    isNotApplicable: boolean;
    subActivities: ProgressActivityModelDTO[];
}
