import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const ErrorStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flex: "1",
        flexDirection: "column !important" as "column",
        justifyContent: "center",
        textAlign: "center",
    },
}));
