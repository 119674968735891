export const contentTypeHelper = (type: string) => {
    switch (type.toLocaleLowerCase()) {
        case "png":
            return "image/png";

        case "jpg":
            return "image/jpeg";

        case "jpeg":
            return "image/jpeg";

        case "mov":
            return "video/quicktime";

        case "mp4":
            return "video/mp4";

        case "docx":
            return "application/msword";

        case "xlsx":
            return "application/vnd.ms-excel";

        case "pdf":
            return "application/pdf";
    }
};
