// Libraries
import { Box, Card, CardActionArea, CardContent, Menu, MenuItem, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { useRouter } from "Core/Base";
import { Loader } from "Core/Components/Loader";

// Custom
import { Error } from "Custom/Components/Error";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { NavigationBarStyles } from "Custom/StylesAppSpecific/NavigationBarStyling";
import { MainPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { DashboardViewModel } from "Custom/ViewModels/DashboardViewModel";

export const NavigationBar: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    const navigationBarStyles = NavigationBarStyles();
    const mainPageStyles = MainPageStyles();

    //console.log("NAVIGATIONBARVIEW: PropertyId", router.match.params);

    const [viewModel] = useState(() => new DashboardViewModel((router.match.params as any).propertyId));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget.parentElement!.parentElement!);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleItemClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
        event.stopPropagation();
        setAnchorEl(null);
        viewModel.changePlot(id);
    };

    const mouseDown = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching dashboard..." />;

    const renderError = () => <Error errorText="Failed to retrieve dashboard information." />;

    const renderPropertyItemCardContent = (title: string, subTitle: string) => {
        return (
            <CardContent className={mainPageStyles.content}>
                <Box className={mainPageStyles.propertyTitleRow}>
                    <Box className={navigationBarStyles.titleParent}>
                        <Typography className={mainPageStyles.title}>{title}</Typography>
                        {viewModel.availablePlotOptions.length > 1 && (
                            <Box className={mainPageStyles.dropdownArrow} onClick={e => handleClick(e)} onMouseDown={e => mouseDown(e)}>
                                <ArrowForwardIosOutlinedIcon />
                            </Box>
                        )}
                    </Box>
                    <Box className={mainPageStyles.navigateToArrow}>
                        <ArrowForwardIosOutlinedIcon />
                    </Box>
                </Box>
                {viewModel.availablePlotOptions && (
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: "300px",
                                maxWidth: "70%",
                            },
                        }}
                        getContentAnchorEl={null}
                    >
                        {viewModel.availablePlotOptions.map((plot, index) => (
                            <MenuItem key={index} onClick={e => handleItemClick(e, plot.id)} selected={plot.id === viewModel.getCurrentPropertyId}>
                                {`${plot.plotNumber} ${plot.developmentName}`}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </CardContent>
        );
    };

    const renderPropertyItem = (image: string, title: string, subTitle: string, propertyItemPathname: string) => {
        return (
            <Card className={`${mainPageStyles.navigateToPropertyItem} ${router.location.pathname === propertyItemPathname || router.location.pathname === "/" ? "active" : ""}`}>
                <CardActionArea onClick={() => router.history.push(propertyItemPathname)}>{renderPropertyItemCardContent(title, subTitle)}</CardActionArea>
            </Card>
        );
    };

    const renderItemCardContent = (title: string, subTitle: string, date: string) => {
        return (
            <CardContent className={mainPageStyles.content}>
                <Box className={mainPageStyles.titleRow}>
                    <Typography className={mainPageStyles.title}>{title}</Typography>
                    <Box className={mainPageStyles.navigateToArrow}>
                        <ArrowForwardIosOutlinedIcon />
                    </Box>
                </Box>
            </CardContent>
        );
    };

    const renderItem = (image: string, title: string, subTitle: string, date: string, itemPathname: string) => {
        return (
            <Card className={`${mainPageStyles.navigateToItem} ${router.location.pathname === itemPathname || router.location.pathname === "/" ? "active" : ""}`}>
                <CardActionArea onClick={() => router.history.push(itemPathname)}>{renderItemCardContent(title, subTitle, date)}</CardActionArea>
            </Card>
        );
    };

    const renderItems = () => {
        return (
            <Box className="cards-container property-menu">
                {renderPropertyItem(viewModel.Summary.houseTypeImageUrl, viewModel.PropertySummary, viewModel.Summary.houseTypeName, viewModel.navigateToPropertyPathname)}
                {viewModel.canViewBuildStages &&
                    renderItem("resources/build.png", "Build", viewModel.InspectionSummary, viewModel.InspectionDate, viewModel.navigateToInspectionPathname)}
                {renderItem("resources/progress.png", "Progress", viewModel.ProgressSummary, viewModel.ProgressDate, viewModel.navigateToProgressPathname)}
                {viewModel.canViewIsChoicesToPlot &&
                    renderItem("resources/choices.png", "Choices", viewModel.ChoicesSummary, viewModel.ChoicesDate, viewModel.navigateToChoicesPathname)}
                {renderItem("resources/documents.png", "Documents", viewModel.DocumentsSummary, viewModel.DocumentsDate, viewModel.navigateToDocumentsPathname)}
                {viewModel.canViewSnags &&
                    viewModel.canViewReportSnags &&
                    renderItem("resources/snags.png", "Snags", viewModel.SnagsSummary, viewModel.SnagsDate, viewModel.navigateToSnagsPathname)}
            </Box>
        );
    };

    return useObserver(() =>
        isDesktop ? (
            <Box className={`${navigationBarStyles.root} ${mainPageStyles.root}`}>
                <Box className={`${navigationBarStyles.workspace}`}>
                    {viewModel.ApiStatus === ApiStatus.success && <React.Fragment>{renderItems()}</React.Fragment>}
                    {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                    {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
                </Box>
            </Box>
        ) : (
            <React.Fragment />
        ),
    );
};
