import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information common to the login, forgot password and change password pages.
 */
export const AccountPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    body: {},
    bodyContent: {
        margin: "40px",
    },
    submitButton: {
        width: "100%",
    },
    houseImageContainer: {
        height: "220px",
        backgroundImage: "url(resources/GenericHouse.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    customerPortalImageContainer: {
        height: "38px",
        margin: "40px 40px 0 40px",
        backgroundImage: "url(resources/logo.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        cursor: "pointer",
    },
    divider: {
        display: "none",
    },
    error: {},
    logoImageContainer: {
        height: "35px",
        margin: "40px 40px 0 40px",
        backgroundImage: "url(resources/LogoLandscape.svg)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    gutterBottom: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "10px",
        marginTop: "-5px",
        textAlign: "center",
    },
    subTitle: {
        fontSize: "12px",
        lineHeight: "1.4",
        marginBottom: "16px",
        textAlign: "center",
    },
    linkButton: {
        fontSize: "10px",
        padding: "16px 6px",
        width: "100%",
        color: "#556ED1",
        textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
        body: {
            display: "flex",
            justifyContent: "center",
            "& > div": {
                flex: "1 0 auto",
                maxWidth: "1190px",
                width: "100%",
            },
        },
        bodyContent: {
            marginTop: "30px",
            "& > form > div": {
                display: "flex",
                margin: "0 -15px",
                paddingBottom: "10px",
            },
        },
        error: {
            "& > div": {
                display: "flex",
                margin: "0 -15px",
                paddingBottom: "10px",
            },
        },
        houseImageContainer: {
            backgroundImage: "url(resources/GenericHouses.jpg)",
            minHeight: "290px",
        },
        logoImageContainer: {
            backgroundPosition: "left center",
        },
        customerPortalImageContainer: {
            backgroundPosition: "left center",
            marginTop: "60px",
        },
        divider: {
            display: "block",
        },
        formControl: {
            padding: "0 15px",
        },
        formInputControl: {
            minWidth: "255px",
        },
        submitButton: {
            minWidth: "115px",
        },
        title: {
            textAlign: "left",
        },
        subTitle: {
            marginBottom: "20px",
            textAlign: "left",
        },
        linkButton: {
            padding: "6px 0 0 0 !important",
        },
    },
}));

/**
 * Contains style information for the login page.
 */
export const LoginPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    linkButton: {
        fontSize: "10px",
        padding: "16px 6px",
        width: "100%",
        color: "#556ED1",
        textDecoration: "underline",
    },
}));

/**
 * Contains style information for the forgot password page.
 */
export const ForgotPasswordPageStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

/**
 * Contains style information for the change password page.
 */
export const ChangePasswordPageStyles = makeStyles((theme: Theme) => ({
    root: {},
}));
