// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "util";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { DashboardModel, DashboardModelDTO } from "Custom/Models/DashboardModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "Custom/Stores";

//Pass in <any> as the generic type because we are showing the use of nested classes
export class DashboardViewModel extends ViewModelBase<DashboardModel> {
    constructor(propertyId?: string) {
        super(new DashboardModel());
        this.setDecorators(DashboardModel);

        //console.log("DASHBOARDVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId ? propertyId : StoresInstance.domain.AccountStore.PropertyId ? StoresInstance.domain.AccountStore.PropertyId : "";

        if (!isEmptyOrWhitespace(this.propertyId)) {
            this.loadByPropertyId(this.propertyId!);
        } else {
            this.load();
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    @observable
    public showPlotsList = false;

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    // #endregion Properties

    // #region Computeds

    @computed
    public get getCurrentPropertyId() {
        return this.propertyId;
    }

    @computed
    public get Summary() {
        return this.model;
    }

    @computed
    public get availablePlotOptions() {
        return this.model.availablePlots;
    }

    @computed
    public get isCompleted() {
        return false;
    }

    @computed
    public get canViewSnags() {
        return StoresInstance.domain.AccountStore.IsInTenYearWarranty;
    }

    @computed
    public get canViewReportSnags() {
        return this.model.isReportSnags;
    }

    @computed
    public get canViewBuildStages() {
        return this.model.isViewBuildStages;
    }

    @computed
    public get canViewIsChoicesToPlot() {
        return this.model.isChoicesToPlot;
    }

    @computed
    public get canDisplayCompanyLogo(): boolean {
        return !isEmptyOrWhitespace(StoresInstance.domain.AccountStore.CompanyLogo);
    }

    @computed
    public get companyLogo(): string {
        return StoresInstance.domain.AccountStore.CompanyLogo;
    }

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    @computed
    public get ChoicesDate(): string {
        return isNullOrUndefined(this.model.choicesModifiedDate) ? "Not available" : this.model.choicesModifiedDate.format("DD/MM/YYYY HH:mm");
    }

    @computed
    public get ChoicesSummary(): string {
        if (this.model.isChoicesLocked) {
            return "Choices are in view only mode.";
            //todo check best phrasing with devops
        } else {
            return `${this.model.choicesMadePercentage}% complete`;
        }
    }

    @computed
    public get InspectionDate(): string {
        return isNullOrUndefined(this.model.inspectionStageModifiedDate) ? "Not available" : this.model.inspectionStageModifiedDate.format("DD/MM/YYYY HH:mm");
    }

    @computed
    public get InspectionSummary(): string {
        return `${this.model.inspectionStageName}`;
    }

    @computed
    public get ProgressDate(): string {
        return isNullOrUndefined(this.model.progressModifiedDate) ? "Not available" : this.model.progressModifiedDate.format("DD/MM/YYYY HH:mm");
    }

    @computed
    public get ProgressSummary(): string {
        return isEmptyOrWhitespace(this.model.progressName) ? "None" : this.model.progressName;
    }

    @computed
    public get PropertySummary(): string {
        return `Plot ${this.model.plotNumber} ${this.model.developmentName}`;
    }

    @computed
    public get SnagsDate(): string {
        return isNullOrUndefined(this.model.snagsModifiedDate) ? "Not available" : this.model.snagsModifiedDate.format("DD/MM/YYYY HH:mm");
    }

    @computed
    public get SnagsSummary(): string {
        return `${this.model.snagCount} snags reported`;
    }

    @computed
    public get DocumentsDate(): string {
        return isNullOrUndefined(this.model.documentsModifiedDate) ? "Not available" : this.model.documentsModifiedDate.format("DD/MM/YYYY HH:mm");
    }

    @computed
    public get DocumentsSummary(): string {
        return `${this.model.documentCount} docs uploaded`;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    @action
    public toggleShowPlotsList = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (this.model.availablePlots.length > 1) {
            e.stopPropagation();

            this.showPlotsList = !this.showPlotsList;
        }
    };

    @computed
    public get navigateToChoicesPathname() {
        return `/choices/${this.propertyId}`;
    }

    @computed
    public get navigateToDocumentsPathname() {
        return `/documents/${this.propertyId}`;
    }

    @computed
    public get navigateToProgressPathname() {
        return `/progress/${this.propertyId}`;
    }

    @computed
    public get navigateToPropertyPathname() {
        return `/property/${this.propertyId}`;
    }

    @computed
    public get navigateToSnagsPathname() {
        return `/snags/${this.propertyId}`;
    }

    @computed
    public get navigateToInspectionPathname() {
        return `/inspection/${this.propertyId}`;
    }

    @action
    public changePlot = (id: string) => {
        this.history.push(`/${id}`);
    };

    private load = async (): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<DashboardModelDTO>(Server.Api.Dashboard.Get);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                StoresInstance.domain.AccountStore.setPropertyId(apiResult.payload.id);
                StoresInstance.domain.AccountStore.setIsInTenYearWarranty(apiResult.payload.isInTenYearWarranty);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    public loadByPropertyId = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<DashboardModelDTO>(Server.Api.Dashboard.GetByPropertyId + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                StoresInstance.domain.AccountStore.setPropertyId(apiResult.payload.id);
                StoresInstance.domain.AccountStore.setIsInTenYearWarranty(apiResult.payload.isInTenYearWarranty);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
