import React, { useEffect } from "react";

interface IProps {
    title: string;
}

export const PageTitle: React.FC<IProps> = props => {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return <React.Fragment />;
};
