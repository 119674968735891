import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { Error } from "Custom/Components/Error";

import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { SnagsPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/SnagsPageStyling";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { SnagsViewModel } from "Custom/ViewModels/DashboardSubViewModels/SnagsViewModel";
import { SnagViewModel } from "Custom/ViewModels/DashboardSubViewModels/SnagViewModel";
import { NewSnag } from "Custom/Views/DashboardSubViews/NewSnag";
import { sortByString } from "Core/Utils/Utils";

export const Snags: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    //console.log("SNAGSVIEW: PropertyId", router.match.params);
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const snagsPageStyles = SnagsPageStyles();

    const [viewModel] = useState(() => new SnagsViewModel(propertyId));

    const onAccordionChange = () => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        viewModel.newSnagViewModel.setCanDisplay(!viewModel.newSnagViewModel.canDisplay);
    };

    const getSnagDateClasses = (snagViewModel: SnagViewModel): string => {
        let classes = snagsPageStyles.snagTitle;

        if (snagViewModel.isCompleted) {
            classes = `${classes} ${snagsPageStyles.resolvedSnagTitle}`;
        } else if (snagViewModel.isRejected) {
            classes = `${classes} ${snagsPageStyles.rejectedSnagTitle}`;
        }

        return classes;
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching snags..." />;

    const renderError = () => <Error errorText="Failed to retrieve the snags." />;

    const renderTitleRow = () =>
        isDesktop ? (
            <>
                <Box className={snagsPageStyles.titleRow}>
                    <Typography className={snagsPageStyles.title} variant={"h5"}>
                        Customer Care
                    </Typography>
                    <CloseButton execute={viewModel.navigateToDashboard} />
                </Box>
                <Box className={snagsPageStyles.titleBorder} />
            </>
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Customer Care</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderIntroductionRow = () => (
        <Box className={snagsPageStyles.introduction}>
            <Typography className={snagsPageStyles.introductionTitle}>Your snags</Typography>
        </Box>
    );

    const renderSnags = () => (
        <Box className={snagsPageStyles.snags}>
            {viewModel.SnagViewModels.slice()
                .sort((a, b) => sortByString(a.model.reportedDate, b.model.reportedDate))
                .map((snagViewModel, index) => (
                    <Card className={snagsPageStyles.snag} key={index} onClick={() => viewModel.naviagateToViewSnag(propertyId, snagViewModel.model.id!)}>
                        <CardActionArea>
                            <CardContent>
                                <Box className={snagsPageStyles.snagTitleRow}>
                                    <Typography className={snagsPageStyles.snagTitle} variant="body2">
                                        {console.log("SnagViewModel", snagViewModel)}
                                        {snagViewModel.NumberAndArea}
                                    </Typography>
                                    <Typography className={getSnagDateClasses(snagViewModel)} variant="body2">
                                        {snagViewModel.FormattedSnagStatus}
                                    </Typography>
                                </Box>
                                <Box className={snagsPageStyles.snagContentRow}>
                                    <Box>
                                        <Box className={snagsPageStyles.snagContentTitleRow}>
                                            <Typography className={snagsPageStyles.snagTitle} variant="body2">
                                                {snagViewModel.Title}
                                            </Typography>
                                        </Box>
                                        <Typography className={snagsPageStyles.snagDetails} variant="body2">
                                            {snagViewModel.Details}
                                        </Typography>
                                    </Box>
                                    <Box className={snagsPageStyles.snagArrow}>
                                        <ArrowForwardIosOutlinedIcon />
                                    </Box>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
        </Box>
    );

    const renderForm = () => {
        return (
            <Accordion className={snagsPageStyles.accordion} expanded={viewModel.newSnagViewModel.canDisplay} onChange={onAccordionChange()}>
                <AccordionSummary className={snagsPageStyles.accordionSummary} expandIcon={viewModel.newSnagViewModel.canDisplay ? <RemoveCircleIcon /> : <AddCircleIcon />}>
                    <Typography>Report a snag</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <NewSnag viewModel={viewModel.newSnagViewModel} />
                </AccordionDetails>
            </Accordion>
        );
    };

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${snagsPageStyles.root}`}>
            <Box className={dashboardPageStyles.workspace}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderTitleRow()}
                        {renderForm()}
                        {renderIntroductionRow()}
                        {renderSnags()}
                    </React.Fragment>
                )}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
        </Box>
    ));
};
