// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// App
import { ProductVariantModel } from "Custom/Models/DashboardModels/ProductVariantModel";

export class ProductVariantViewModel extends ViewModelBase<ProductVariantModel> {
    constructor(productVariant: ProductVariantModel, isSelected: boolean) {
        super(productVariant);
        this.setDecorators(ProductVariantModel);

        this.setInitialValues(productVariant, isSelected);
    }

    @action
    private setInitialValues(productVariant: ProductVariantModel, isSelected: boolean) {
        if (isSelected) {
            this.model.isSelected = true;
        }
    }

    @computed
    public get productVariantTitle() {
        return this.model.productVariantCode + " - " + this.model.productVariantName;
    }

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get productId() {
        return this.model.productId;
    }

    @computed
    public get code() {
        return this.model.productVariantCode;
    }

    @computed
    public get name() {
        return this.model.productVariantName;
    }

    @computed
    public get isHidden() {
        return String(this.model.productVariantIsHidden);
    }

    @computed
    public get productVariantThumbnailImage() {
        return this.model.productVariantThumbnailImageUrl;
    }

    @computed
    public get productVariantOriginalImage() {
        return this.model.productVariantOriginalImageUrl;
    }

    @action
    public setIsSelected(val: boolean) {
        this.model.isSelected = val;
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
