// Libraries
import { Box, CardMedia, Divider, Typography, Link } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components";

// Custom
import { StandardButton } from "Custom/Components/Buttons/StandardButton";
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import { ForgotPasswordModel } from "Custom/Models/ForgotPasswordModel";
import { AccountPageStyles, ForgotPasswordPageStyles } from "Custom/StylesAppSpecific/AccountPagesStyling";
import { ForgotPasswordViewModel } from "Custom/ViewModels/ForgotPasswordViewModel";
import { runInAction } from "mobx";

export const ForgotPasswordPage = () => {
    // #region Code Behind

    const accountPageStyles = AccountPageStyles();
    const forgotPasswordPageStyles = ForgotPasswordPageStyles();
    const query = window.location.pathname;
    const email = query.split("/")[2];

    const [viewModel] = useState(() => new ForgotPasswordViewModel());

    const onSubmit = async (event: any) => {
        event.preventDefault();

        viewModel.forgotPassword();
    };

    runInAction(() => {
        email ? (viewModel.model.emailAddress = email) : "";
    });

    // #endregion Code Behind

    const renderBusy = () => <Loader />;

    const renderError = () => (
        <React.Fragment>
            <Box className={accountPageStyles.error}>
                <Typography className={accountPageStyles.title} gutterBottom>
                    There seems to have been a problem
                </Typography>
                <Typography className={accountPageStyles.subTitle} gutterBottom>
                    {viewModel.serverValidationMessage}
                </Typography>
                <Box>
                    <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                        <StandardButton className={accountPageStyles.submitButton} displayName="Try again" execute={viewModel.tryAgain} />
                    </Box>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </React.Fragment>
    );

    const renderForm = () => (
        <form onSubmit={onSubmit}>
            <Typography className={accountPageStyles.title} gutterBottom>
                You've forgotten your password
            </Typography>
            <Typography className={accountPageStyles.subTitle} gutterBottom>
                Please enter the email address you use to log in below and click on the "Forgot password" button. We will then send you an email containing a link for you to reset
                your password.
            </Typography>
            <Box>
                <EditableInput<ForgotPasswordModel>
                    className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl} ${accountPageStyles.formInputControl}`}
                    fieldName="emailAddress"
                    inputProps={{
                        placeholder: "Your email address",
                    }}
                    type="email"
                    validateOnBlur={true}
                    viewModel={viewModel}
                />
                <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                    <StandardButton canExecute={!viewModel.IsLoading} className={accountPageStyles.submitButton} displayName="Forgot password" isSubmit={true} />
                </Box>
                <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                    <Link className={accountPageStyles.linkButton} component="button" onClick={viewModel.navigateToLogin}>
                        Go back
                    </Link>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </form>
    );

    const renderSuccess = () => (
        <React.Fragment>
            <Box className={accountPageStyles.error}>
                <Typography className={accountPageStyles.title}>An email has been sent to your inbox with a link to change your password.</Typography>
                <Box>
                    <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                        <StandardButton className={accountPageStyles.submitButton} displayName="Log in" execute={viewModel.navigateToLogin} />
                    </Box>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </React.Fragment>
    );

    return useObserver(() => (
        <Box className={`${accountPageStyles.root} ${forgotPasswordPageStyles.root}`}>
            <Box className={accountPageStyles.houseImageContainer} />
            <Box className={accountPageStyles.body}>
                <Box>
                    <Box className={accountPageStyles.customerPortalImageContainer} />
                    <Box className={accountPageStyles.bodyContent}>
                        {viewModel.canDisplayBusy && renderBusy()}
                        {viewModel.canDisplayError && renderError()}
                        {viewModel.canDisplayForm && renderForm()}
                        {viewModel.canDisplaySuccess && renderSuccess()}
                    </Box>
                    <Box className={accountPageStyles.logoImageContainer} />
                </Box>
            </Box>
        </Box>
    ));
};
