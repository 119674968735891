import * as MobX from "mobx";

import { IsEmail, IsNotEmpty } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class ResetPasswordModel extends ModelBase<ResetPasswordModel, ResetPasswordModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "Email is required" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public emailAddress: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    public password: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Confirm password is required" })
    public confirmPassword: string = "";

    @MobX.observable
    public token: string = "";

    fromDto(model: ResetPasswordModelDTO): void {}

    toDto(model: ResetPasswordModel): void {}
}

export interface ResetPasswordModelDTO {}
