// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
//import { ChoiceModel, ChoiceModelDTO } from "./ChoiceModel";

export class ProductSummaryModel extends ModelBase<ProductSummaryModel, ProductSummaryModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public orderId: string = "";

    @observable
    public productId: string = "";

    @observable
    public productName: string = "";

    @observable
    public productCode: string = "";

    @observable
    public productVariantId: string = "";

    @observable
    public productVariantName: string = "";

    @observable
    public productVariantCode: string = "";

    @observable
    public orderAreaId: string = "";

    @observable
    public orderAreaProductGroupId: string = "";

    @observable
    public isIncluded: boolean = false;

    @observable
    public isUpgrade: boolean = false;

    @observable
    public isOptional: boolean = false;

    @observable
    public cost: string = "";

    @observable
    public isPOA: boolean = false;

    @observable
    public quantity: number = 0;

    // #endregion Properties

    @action
    public fromDto = (dto: ProductSummaryModelDTO): void => {
        this.id = dto.id;
        this.orderId = dto.orderId;
        this.productId = dto.productId;
        this.productName = dto.productName;
        this.productCode = dto.productCode;
        this.productVariantId = dto.productVariantId;
        this.productVariantName = dto.productVariantName;
        this.productVariantCode = dto.productVariantCode;
        this.orderAreaId = dto.orderAreaId;
        this.orderAreaProductGroupId = dto.orderAreaProductGroupId;
        this.isIncluded = dto.isIncluded;
        this.isUpgrade = dto.isUpgrade;
        this.isOptional = dto.isOptional;
        this.cost = dto.cost;
        this.isPOA = dto.isPOA;
        this.quantity = dto.quantity;
    };

    public toDto = (): ProductSummaryModelDTO => {
        throw "toDto is not supported";
    };
}

export interface ProductSummaryModelDTO {
    id: string;
    orderId: string;
    productId: string;
    productName: string;
    productCode: string;
    productVariantId: string;
    productVariantName: string;
    productVariantCode: string;
    orderAreaId: string;
    orderAreaProductGroupId: string;
    isIncluded: boolean;
    isUpgrade: boolean;
    isOptional: boolean;
    cost: string;
    isPOA: boolean;
    quantity: number;
    isPaid: boolean;
}
