import { darken, lighten, makeStyles, Theme } from "@material-ui/core";

export const CloseButtonStyle = makeStyles((theme: Theme) => ({
    root: {
        color: "#dc3546",
        "& svg": {
            fontSize: "40px",
        },
    },
}));

export const CancelButtonStyle = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: "#d1556e",
        color: "white",
        fontSize: "10px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: darken("#d1556e", 0.2),
        },
    },
}));

export const StandardButtonStyle = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: "#556ed1",
        color: "white",
        fontSize: "10px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: darken("#556ed1", 0.2),
        },
    },
}));

export const NavButtonStyle = makeStyles((theme: Theme) => ({
    root: {
        color: "#556ED1",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "0",
        textDecoration: "underline",
        textTransform: "none",
        "&:hover": {
            color: darken("#556ED1", 0.2),
        },
    },
}));
