// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom

export class ProductVariantModel extends ModelBase<ProductVariantModel, ProductVariantModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public productId: string = "";

    @observable
    public productVariantCode: string = "";

    @observable
    public productVariantName: string = "";

    @observable
    public productVariantThumbnailImageUrl: string = "";

    @observable
    public productVariantOriginalImageUrl: string = "";

    @observable
    public isSelected: boolean = false;

    @observable
    public productVariantIsHidden: boolean = false;

    // #endregion Properties

    @action
    public fromDto = (dto: ProductVariantModelDTO): void => {
        this.id = dto.id;
        this.productId = dto.productId;
        this.productVariantCode = dto.productVariantCode;
        this.productVariantName = dto.productVariantName;
        this.productVariantThumbnailImageUrl = dto.productVariantThumbnailImageUrl;
        this.productVariantOriginalImageUrl = dto.productVariantOriginalImageUrl;
        this.productVariantIsHidden = dto.productVariantIsHidden;
    };

    public toDto = (): ProductVariantModelDTO[] => {
        throw "toDto is not supported";
    };
}

export interface ProductVariantModelDTO {
    id: string;
    productId: string;
    productVariantCode: string;
    productVariantName: string;
    productVariantThumbnailImageUrl: string;
    productVariantOriginalImageUrl: string;
    productVariantIsHidden: boolean;
}

export interface ProductVariantToOrderDTO {
    id: string;
    productId: string;
    productVariantCode: string;
    productVariantName: string;
    isSelected: boolean;
}
