import { darken, lighten, makeStyles, Theme } from "@material-ui/core";

export const NavigationBarStyles = makeStyles((theme: Theme) => ({
    // Root defines the workspace container.
    // This is scrollable.
    root: {
        backgroundColor: "#F9F9F9",
        borderBottom: "1px solid #ced4da",
        display: "flex",
        justifyContent: "center",
    },
    // The direct child of root is the workpace.
    // This is limited in width for desktops.
    workspace: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        marginBottom: "-1px",
        maxWidth: "1146px",
        "& > div": {
            display: "flex",
            flexDirection: "row",
            margin: "55px 13px -5px 13px",
            "&.cards-container": {},
            "&.company-logo-container": {
                margin: "60px 0 48px",
                padding: 0,
            },
        },
    },
    titleRow: {
        alignItems: "center",
        backgroundColor: "#F9F9F9",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "72px",
        "& > h5": {
            fontSize: "16px",
            fontWeight: "bold",
            padding: "12px 18px",
        },
    },
    plotsListDropdown: {
        position: "absolute",
        backgroundColor: "#ffffff",
        top: "56px",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "5px 5px 5px rgb(0 0 0 / 40%)",
        border: "1px solid #000000",
        "> div": {
            padding: "10px 0px",
        },
    },
    titleParent: {
        display: "flex",
    },
}));
