import { Box, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React from "react";

import { ChoicesProductGroupStyles } from "Custom/StylesAppSpecific/DashboardSubViews/ChoicesProductGroupStyling";
import { ProductGroupViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProductGroupViewModel";

import { ChoicesProduct } from "Custom/Views/DashboardSubViews/ChoicesProduct";

interface IProps {
    viewModel: ProductGroupViewModel;
    accordionLabel: string;
    isDisabled: boolean;
}

export const ChoicesProductGroup: React.FC<IProps> = props => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    const choicesProductGroupStyles = ChoicesProductGroupStyles();

    const renderChoices = () => {
        const isDisabled = props.isDisabled;

        return props.viewModel.sortedProducts.map((product, index) => (
            <ChoicesProduct
                key={`${product.model.productId}-${props.viewModel.model.id}`}
                viewModel={product}
                productGroupViewModel={props.viewModel}
                index={index}
                isExtra={false}
                isDisabled={isDisabled}
            />
        ));
    };

    const renderExtras = () => {
        const isDisabled = props.isDisabled;

        return props.viewModel.sortedExtras.map((product, index) => (
            <ChoicesProduct
                key={`${product.model.productId}-${props.viewModel.model.id}`}
                viewModel={product}
                productGroupViewModel={props.viewModel}
                index={index}
                isExtra={true}
                isDisabled={isDisabled}
            />
        ));
    };

    const renderSubTitle = (subtitle: string) => (
        <Box className={choicesProductGroupStyles.subtitle}>
            <Typography>{subtitle}</Typography>
        </Box>
    );

    const inputCSSClassNames = (defaultCSS: string) => {
        let CSSRetVal = defaultCSS;

        if (props.isDisabled) {
            CSSRetVal += " choices-product__input--disabled";
        }

        return CSSRetVal;
    };

    const renderNoneOfTheAbove = () => (
        <Box className={choicesProductGroupStyles.noneoftheabovecontainer}>
            <Box>
                <FormControlLabel
                    control={<Radio className={choicesProductGroupStyles.radioButton} />}
                    checked={props.viewModel.model.isNoneOfTheAbove}
                    label={<Typography variant="body2">None of the above</Typography>}
                    labelPlacement={isDesktop ? "start" : "end"}
                    name={`${props.viewModel.model.productGroupName}-${`nonoftheabove`}`}
                    onChange={() => !props.isDisabled && props.viewModel.setNoneOfTheAbove()}
                    className={inputCSSClassNames(
                        props.viewModel.model.isNoneOfTheAbove ? choicesProductGroupStyles.productRadioChecked : choicesProductGroupStyles.productRadioNoneOfTheAbove,
                    )}
                />
            </Box>
        </Box>
    );

    return useObserver(() => (
        <Box className={choicesProductGroupStyles.root}>
            {renderSubTitle(props.accordionLabel)}
            {renderChoices()}
            {renderNoneOfTheAbove()}
            {props.viewModel.hasExtras && (
                <React.Fragment>
                    {renderSubTitle("...and any extras (if required)")}
                    {renderExtras()}
                </React.Fragment>
            )}
        </Box>
    ));
};

export default ChoicesProductGroup;
