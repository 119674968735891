import * as AppUrls from "../../Globals/AppUrls";
import React from "react";
import { useEffect, useState } from "react";
import { PrivateImageViewModel } from "Custom/ViewModels/PrivateImageViewModel";
import { BlobImageModel } from "Custom/Models";
import { Box, Card, CardActionArea, CardContent, CardMedia, Menu, MenuItem, Typography } from "@material-ui/core";

interface Props {
    src: string;
    alt?: string;
    className?: string;
    title?: string;
    static?: boolean;
    asDiv?: boolean;
    cardMedia?: boolean;
    height?: number | string;
    width?: number | string;
    onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
    style?: React.CSSProperties;
}
export const PrivateBlob = (props: Props) => {
    const [state, setState] = useState<any>({ src: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" });

    const [viewModel] = useState(() => new PrivateImageViewModel());

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const loadImage = async () => {
            if (props.static || (!props.src.startsWith("http") && !props.src.startsWith("url") && !props.src.startsWith("data"))) {
                if (isMounted) {
                    setState({ src: props.src });
                }
                return;
            }

            let urlSrc = props.src;
            if (props.asDiv) {
                urlSrc = props.src.replace("url(", "").replace(")", "");
            }

            try {
                const blob = await viewModel.loadImageByURL(urlSrc);

                if (isMounted && blob != undefined) {
                    setState({ src: `${blob}` });
                }
            } catch (error) {
                console.error("Failed to load image", error);
            }
        };

        loadImage();

        return () => {
            isMounted = false; // Set flag to false when the component unmounts
        };
    }, [props.src, props.static, props.asDiv]);

    if (props.asDiv) {
        return <div className={props.className} style={{ backgroundImage: `url(${state.src})`, ...props.style }} onClick={props.onClick} />;
    }

    if (props.cardMedia) {
        return <CardMedia className={props.className} image={state.src} title={props.title} onClick={props.onClick} style={props.style} />;
    }

    return (
        <img
            height={props.height}
            width={props.width}
            className={props.className}
            alt={props.alt}
            title={props.title}
            src={state.src}
            onClick={props.onClick}
            style={props.style}
        />
    );
};
