import { darken, lighten, makeStyles, Theme } from "@material-ui/core";

export const PageHeaderStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: "center",
        //borderBottom: "1px solid #ced4da",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "0 18px",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        maxWidth: "75px",
        "& > img": {
            width: "100%",
            minWidth: "60px",
        },
    },
    textContainer: {
        textAlign: "right",
    },
    title: {
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1",
        marginLeft: "10px",
        ["@media (max-width:399px)"]: {
            fontSize: "12px",
        },
    },
    link: {},
}));
