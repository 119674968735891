// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// App
import { ProductGroupSummaryModel } from "../../Models/DashboardModels/ProductGroupSummary";
import { ProductSummaryViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProductSummaryViewModel";

export class ProductGroupSummaryViewModel extends ViewModelBase<ProductGroupSummaryModel> {
    @observable
    private productSummaryViewModels: ProductSummaryViewModel[] = [];

    constructor(productGroup: ProductGroupSummaryModel) {
        super(productGroup);
        this.setDecorators(ProductGroupSummaryModel);

        this.createViewModels();
    }

    @computed
    public get products() {
        return this.productSummaryViewModels;
    }

    @computed
    public get options() {
        return this.products.filter(p => p.isOption === true);
    }

    @computed
    public get extras() {
        return this.products.filter(p => p.isExtra === true);
    }

    @computed
    public get isNoneOfTheAbove() {
        return this.model.isNoneOfTheAbove;
    }

    @action
    private createViewModels() {
        for (const product of this.model.products) {
            this.productSummaryViewModels.push(new ProductSummaryViewModel(product));
        }
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
