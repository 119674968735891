import * as GoogleAnalytics from "Core/Utils/App/GoogleAnalyticsUtils";
import * as Models from "../Models";
import { action, computed, observable, runInAction } from "mobx";
import * as MobX from "mobx";
import { AccountStore, StripeStore } from "./Domain";
import { EditableCopy } from "../Models/EditableCopy";
import { EditableCopyRoute } from "../Models/EditableCopyRoute";
import { RoleStore } from "./Domain/Admin";
import { Stores } from "./Stores";
import { UserStore } from "./Domain/Admin";

import { SnagTypeStore } from "./Domain/SnagTypeStore";
import { HouseAreaStore } from "./Domain/HouseAreaStore";
import { SnagPhotoStore } from "./Domain/SnagPhotoStore";

export class DomainStores {
    @observable public openSnackBar = false;
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public snackBarContent: string = "";
    @observable public editableCopies = MobX.observable<EditableCopy>([]);
    @observable public editableCopyRoutes = MobX.observable<EditableCopyRoute>([]);
    public AccountStore: AccountStore = new AccountStore();
    public StripeStore: StripeStore = new StripeStore();
    public UserStore = new UserStore();
    public RoleStore = new RoleStore();
    public SnagTypeStore = new SnagTypeStore();
    public HouseAreaStore = new HouseAreaStore();
    public SnagPhotoStore = new SnagPhotoStore();

    public init(stores: Stores, initialState: Models.InitialState) {
        this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        // initialState.editableCopies.forEach(editableCopy => {
        //   this.editableCopies.push(editableCopy);
        // });
        // initialState.editableCopyRoutes.forEach(editableCopyRoute => {
        //   this.editableCopyRoutes.push(editableCopyRoute);
        // });
        this.AccountStore.init(stores, initialState);
        this.StripeStore.init(stores, initialState);
    }

    @action
    public ShowSnackBar(content: string) {
        this.snackBarContent = content;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}
