// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { AreaSummaryModel } from "../../Models/DashboardModels/AreaSummaryModel";
import { ProductGroupSummaryViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProductGroupSummaryViewModel";

export class AreaSummaryViewModel extends ViewModelBase<AreaSummaryModel> {
    @observable
    private productGroupSummaryViewModels: ProductGroupSummaryViewModel[] = [];

    constructor(areaSummary: AreaSummaryModel) {
        super(areaSummary);
        this.setDecorators(AreaSummaryModel);

        this.createViewModels();
    }

    // #region Properties

    @computed
    public get productGroupsSummary() {
        return this.productGroupSummaryViewModels;
    }

    // #endregion Properties

    @action
    private createViewModels() {
        for (const productGroup of this.model.productGroups) {
            this.productGroupSummaryViewModels.push(new ProductGroupSummaryViewModel(productGroup));
        }
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
