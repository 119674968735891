// Libraries
import { Box, Button, Grid, Modal, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { UploadableImage } from "Core/Components/Forms";
import { Loader } from "Core/Components/Loader";
import { KeyValuePair } from "Core/Models/KeyValuePair";

// Custom
import { NewSnagFormStyles } from "Custom/StylesAppSpecific/DashboardSubViews/NewSnagFormStyling";

import { StandardButton } from "Custom/Components/Buttons/StandardButton";
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import { NewSnagViewModel } from "Custom/ViewModels/DashboardSubViewModels/NewSnagViewModel";
import { useRouter } from "Core/Utils";
import { SnagPhotoModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import Lightbox from "Custom/Components/Popups/Lightbox";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

interface IProps {
    viewModel: NewSnagViewModel;
}

export const NewSnag: React.FC<IProps> = props => {
    // #region  Code Behind
    const { match } = useRouter();
    const { propertyId } = match.params as any;

    const [lightboxImg, setLightboxImg] = React.useState("");
    const [lightboxImgName, setLightboxImgName] = React.useState("");
    const [lightboxOpen, setLightboxOpen] = React.useState(false);

    const newSnagFormStyles = NewSnagFormStyles();

    const getImageFileUrl = () => {
        const imageFile = props.viewModel.model.imageFile;

        if (imageFile) {
            return imageFile.name;
        } else {
            return "";
        }
    };

    const canDisplayThumbnails = () => {
        return props.viewModel.getSnagPhotos.length > 0;
    };

    const addPhoto = (file: File) => {
        props.viewModel.addTmpPhoto(file);
    };

    const deletePhoto = (index: number) => {
        props.viewModel.removePhoto(index);
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader />;

    const renderLoadError = () => (
        <Box>
            <Typography>There seems to have been a problem.</Typography>
            <Typography>{props.viewModel.serverValidationMessage}</Typography>
            <StandardButton displayName="Try again" execute={() => props.viewModel.tryLoadAgain(propertyId)} />
        </Box>
    );

    const renderSubmittedError = () => (
        <Box>
            <Typography>There seems to have been a problem.</Typography>
            <Typography>{props.viewModel.serverValidationMessage}</Typography>
            <StandardButton displayName="Try again" execute={props.viewModel.trySaveAgain} />
        </Box>
    );

    const renderForm = () => (
        <Box className={newSnagFormStyles.root}>
            <form>
                <Box className={newSnagFormStyles.selectionCol}>
                    <EditableInput
                        className={`${newSnagFormStyles.formControl} ${newSnagFormStyles.formInputControl}`}
                        fieldName={"areaId"}
                        inputProps={{
                            displayEmpty: true,
                        }}
                        label="House area"
                        selectItems={props.viewModel.model.houseAreas as KeyValuePair[]}
                        type="select"
                        viewModel={props.viewModel}
                    />
                    {props.viewModel.areaErrorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red", margin: "0px 0px 15px 15px" }}>
                            {props.viewModel.areaErrorMessage}
                        </Typography>
                    )}
                </Box>

                <Box className={newSnagFormStyles.textAreaCol}>
                    <EditableInput
                        className={`${newSnagFormStyles.formControl} ${newSnagFormStyles.formTextAreaControl}`}
                        fieldName={"title"}
                        inputProps={{
                            multiline: false,
                            placeholder: "Add a brief description",
                            InputProps: {
                                notched: false,
                            },
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        validateOnBlur
                        label="Brief description"
                        viewModel={props.viewModel}
                    />

                    {props.viewModel.titleErrorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red", margin: "0px 0px 15px 15px" }}>
                            {props.viewModel.titleErrorMessage}
                        </Typography>
                    )}
                </Box>

                <Box className={newSnagFormStyles.textAreaCol}>
                    <EditableInput
                        className={`${newSnagFormStyles.formControl} ${newSnagFormStyles.formTextAreaControl}`}
                        fieldName={"details"}
                        inputProps={{
                            multiline: true,
                            placeholder: "Add some detail",
                            InputProps: {
                                notched: false,
                            },
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        label="Details"
                        viewModel={props.viewModel}
                    />

                    {props.viewModel.detailsErrorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red", margin: "0px 0px 15px 15px" }}>
                            {props.viewModel.detailsErrorMessage}
                        </Typography>
                    )}
                </Box>
                <UploadableImage imageUrl={getImageFileUrl()} onImageSelected={addPhoto} />
                {canDisplayThumbnails() && (
                    <Grid container spacing={4}>
                        {props.viewModel.getSnagPhotos.map((photo: SnagPhotoModelDTO, index: number) => {
                            return (
                                <Grid item xs={6} key={`tmp-photo-${index}`}>
                                    {/* <img
                                        src={photo.azureFileName}
                                        alt={`Snag photo ${index}`}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "120px",
                                        }}
                                        onClick={() => {
                                            setLightboxImg(photo.azureFileName);
                                            setLightboxImgName(photo.fileName);
                                            setLightboxOpen(true);
                                        }}
                                    /> */}
                                    <PrivateBlob
                                        src={photo.azureFileName}
                                        alt={`Snag photo ${index}`}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "120px",
                                        }}
                                        onClick={() => {
                                            setLightboxImg(photo.azureFileName);
                                            setLightboxImgName(photo.fileName);
                                            setLightboxOpen(true);
                                        }}
                                    />
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        style={{
                                            marginTop: "4px",
                                        }}
                                    >
                                        {/* <img
                                            src="resources/delete.svg"
                                            alt="Delete icon"
                                            style={{
                                                marginRight: "4px",
                                            }}
                                            onClick={() => deletePhoto(index)}
                                        /> */}
                                        <PrivateBlob
                                            src="resources/delete.svg"
                                            alt="Delete icon"
                                            style={{
                                                marginRight: "4px",
                                            }}
                                            onClick={() => deletePhoto(index)}
                                        />
                                        <Typography
                                            variant="body2"
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {photo.fileName}
                                        </Typography>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                <Box className={newSnagFormStyles.buttonRow}>
                    <StandardButton className={newSnagFormStyles.submitButton} displayName="Report snag" execute={() => props.viewModel.save(propertyId)} />
                </Box>
            </form>
        </Box>
    );

    return useObserver(() => (
        <React.Fragment>
            {props.viewModel.canDisplayBusy && renderBusy()}
            {props.viewModel.canDisplayLoadError && renderLoadError()}
            {props.viewModel.canDisplaySubmittedError && renderSubmittedError()}
            {props.viewModel.canDisplayForm && renderForm()}
            <Lightbox isOpen={lightboxOpen} imageUrl={lightboxImg} onClose={setLightboxOpen} fileName={lightboxImgName} />
        </React.Fragment>
    ));
};
