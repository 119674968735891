// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

export class PropertyDrawingDocumentModel extends ModelBase<PropertyDrawingDocumentModel, PropertyDrawingDocumentModelDTO> {
    // #region Properties

    @observable
    public id = "";

    @observable
    public fileName = "";

    @observable
    public documentUrl = "";

    @observable
    public systemFileName = "";

    @observable
    public revision = "";

    // #endregion Properties

    @computed
    public get DocumentName() {
        return this.revision ? `${this.systemFileName} - ${this.revision}` : this.systemFileName;
    }

    @action
    public fromDto = (dto: PropertyDrawingDocumentModelDTO): void => {
        this.id = dto.id;
        this.fileName = dto.fileName;
        this.documentUrl = dto.documentUrl;
        this.systemFileName = dto.systemFileName;
        this.revision = dto.revision;
    };

    public toDto = (): PropertyDrawingDocumentModelDTO => {
        throw "toDto is not supported";
    };
}

export interface PropertyDrawingDocumentModelDTO {
    id: string;
    fileName: string;
    documentUrl: string;
    systemFileName: string;
    revision: string;
}
