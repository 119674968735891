// Libraries
import { Box, Link, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Custom
import { PageHeaderStyles } from "Custom/StylesAppSpecific/PageHeaderStyling";
import { PageHeaderViewModel } from "Custom/ViewModels/PageHeaderViewModel";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

export const PageHeader: React.FC = () => {
    // #region Code Behind

    const pageHeaderStyles = PageHeaderStyles();

    const [viewModel] = useState(() => new PageHeaderViewModel());

    const onMyDetailsClick = () => {
        viewModel.navigateToUserDetails();
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={pageHeaderStyles.root}>
            <Box className={pageHeaderStyles.logoContainer}>** {viewModel.canDisplayCompanyLogo && <PrivateBlob alt={viewModel.companyName} src={viewModel.companyLogo} />}</Box>
            <Box className={pageHeaderStyles.textContainer}>
                <Typography className={pageHeaderStyles.title}>Hi, {viewModel.userName}</Typography>
                {/* No page has been defined to edit user details, so disable for now.
                <Link
                    className={pageHeaderStyles.link}
                    onClick={onMyDetailsClick}>
                    my details
                </Link>
                */}
            </Box>
        </Box>
    ));
};
