import { Box, Grid } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import { BuyModelDTO, UpdateStripePaymentRequestDTO } from "Custom/Models/BuyModel";
import { CardPaymentModel } from "Custom/Models/CardPaymentModel";
import { BuyViewModel } from "Custom/ViewModels/BuyViewModel";
import { CardPaymentViewModel, CARD_TYPE } from "Custom/ViewModels/CardPaymentViewModel";
import { CardPaymentStyles } from "./CardPayment.styling";
import { CancelButton } from "../Buttons/CancelButton";
import { StandardButton } from "../Buttons/StandardButton";

interface IProps {
    cardType: CARD_TYPE;
    totalPrice: number;
    propertyToProcessId: string;
    reservationRequestId: string | undefined;
    orderRequestId: string | undefined;
    currentUserId: string | undefined;
    onCancel: () => void;
}

export const CardPayment: React.FC<IProps> = ({ cardType, totalPrice, propertyToProcessId, reservationRequestId, orderRequestId, currentUserId, onCancel }) => {
    const [viewModel] = React.useState(() => new CardPaymentViewModel(cardType));
    const [buyViewModel] = React.useState(() => BuyViewModel.Instance);
    const bind = viewModel.getContext();
    const cardPaymentStyles = CardPaymentStyles();
    const [showStripeAction, setShowStripeAction] = React.useState(false);
    const [showStripeUrl, setShowStripeUrl] = React.useState("");

    React.useEffect(() => {
        buyViewModel.setErrorMessage("");
    }, []);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // set the paymentDTO
        const requests: UpdateStripePaymentRequestDTO[] = [];
        if (reservationRequestId) {
            const reservationRequest: UpdateStripePaymentRequestDTO = {
                id: reservationRequestId,
                paidByIdentityUserId: currentUserId ? currentUserId : "",
            };
            requests.push(reservationRequest);
        }
        if (orderRequestId) {
            const reservationRequest: UpdateStripePaymentRequestDTO = {
                id: orderRequestId,
                paidByIdentityUserId: currentUserId ? currentUserId : "",
            };
            requests.push(reservationRequest);
        }

        const model: BuyModelDTO = {
            name: viewModel.model.name,
            type: viewModel.model.type,
            expiry: viewModel.model.expiry,
            security: viewModel.model.security,
            cardNumber: viewModel.model.cardNumber,
            propertyId: propertyToProcessId,
            updatedStripePaymentRequests: requests,
            paymentType: "card",
            price: totalPrice,
            paymentSuccess: false,
            actionUrl: "",
            intentId: "",
            stripeResponse: "",
        };

        await buyViewModel.cardPayment(model);
        const stripeResponse: string = buyViewModel.getValue("stripeResponse");
        if (stripeResponse !== "") {
            let stripeResponseArray = stripeResponse.split("||");
            setShowStripeAction(true);
            setShowStripeUrl(stripeResponseArray[0]);
            const retryStripe = setInterval(async () => {
                model.intentId = stripeResponseArray[1];
                await buyViewModel.cardPayment(model);
                const stripeResponse: string = buyViewModel.getValue("stripeResponse");
                if (stripeResponse === "") {
                    clearInterval(retryStripe);
                    setShowStripeAction(false);
                    setShowStripeUrl("");
                }
            }, 5000);
        } else {
            setShowStripeAction(false);
            setShowStripeUrl("");
        }
    };

    return useObserver(() => (
        <form className={cardPaymentStyles.paymentForm} onSubmit={onSubmit}>
            <Grid container spacing={4}>
                <Grid item md={3} sm={6} xs={12}>
                    <Box>
                        <EditableInput
                            fieldName={bind.name}
                            inputProps={{
                                autoComplete: "cc-name",
                                id: "card-input-name",
                                placeholder: "Name on card",
                                InputProps: {
                                    notched: false,
                                },
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            label="Name on card"
                            validateOnBlur={false}
                            viewModel={viewModel}
                        />
                    </Box>
                </Grid>
                <Grid className={`${cardPaymentStyles.rowGridItemSpacing} ${cardPaymentStyles.rowOverrideSmGridItemSpacing}`} item md={3} sm={6} xs={12}>
                    <Box>
                        <EditableInput
                            fieldName={bind.cardNumber}
                            inputProps={{
                                autoComplete: "cc-number",
                                id: "card-input-card",
                                placeholder: "Payment card number",
                                type: "tel",
                                InputProps: {
                                    notched: false,
                                },
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            label="Card number"
                            validateOnBlur={false}
                            viewModel={viewModel}
                        />
                    </Box>
                </Grid>
                <Grid className={`${cardPaymentStyles.rowGridItemSpacing} ${cardPaymentStyles.rowOverrideSmSmallGridItemSpacing}`} item md={3} xs={6}>
                    <Box>
                        <EditableInput<CardPaymentModel>
                            className={cardPaymentStyles.dateControl}
                            fieldName={"expiry"}
                            inputProps={{
                                autoComplete: "cc-exp",
                                autoOk: true,
                                disablePast: true,
                                format: "MM/YY",
                                id: "card-input-exp",
                                mask: "__/__",
                                minDateMessage: "Card is expired",
                                openTo: "month",
                                placeholder: "MM/YY",
                                type: "tel",
                                views: ["year", "month"],
                                InputProps: {
                                    notched: false,
                                },
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            label="Expiry date"
                            type="date"
                            validateOnBlur={false}
                            viewModel={viewModel}
                        />
                    </Box>
                </Grid>
                <Grid className={`${cardPaymentStyles.rowGridItemSpacing} ${cardPaymentStyles.rowOverrideSmSmallGridItemSpacing}`} item md={3} xs={6}>
                    <Box>
                        <EditableInput
                            fieldName={bind.security}
                            inputProps={{
                                autoComplete: "cc-csc",
                                id: "card-input-security",
                                placeholder: "3 digit code",
                                type: "tel",
                                InputProps: {
                                    notched: false,
                                },
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            label="Security code"
                            validateOnBlur={false}
                            viewModel={viewModel}
                        />
                    </Box>
                </Grid>
                {showStripeAction && (
                    <>
                        <Grid item xs={12}>
                            <Box>
                                <Grid spacing={2} container>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <iframe
                                            name="paymentIFrame"
                                            src={showStripeUrl}
                                            id="paymentIFrame"
                                            style={{ height: "400px", minWidth: "600px", margin: "0px auto", display: "block" }}
                                            title="Payment IFrame"
                                        ></iframe>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <div style={{ width: "100%", textAlign: "center" }}>
                                            <label>Processing - please wait</label>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </>
                )}
                {!showStripeAction && (
                    <>
                        <Grid item xs={12}>
                            <Grid spacing={2} container>
                                {!isEmptyOrWhitespace(buyViewModel.errorMessage) && (
                                    <Grid item xs={12}>
                                        <p style={{ color: "red", textAlign: "center" }}>{buyViewModel.errorMessage}</p>
                                    </Grid>
                                )}
                                {buyViewModel.IsLoading && (
                                    <Grid item xs={12}>
                                        <p style={{ textAlign: "center" }}>Please stand by...</p>
                                    </Grid>
                                )}
                                <Grid className={cardPaymentStyles.buttonRowGridItemSpacing} item md={3} sm={6} xs={12} />
                                <Grid item md={3} sm={6} xs={12}>
                                    <StandardButton
                                        canExecute={!buyViewModel.IsLoading && viewModel.isModelValid()}
                                        className={cardPaymentStyles.payNowButton}
                                        displayName="Pay now"
                                        isSubmit={true}
                                    />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <CancelButton canExecute={!buyViewModel.IsLoading} className={cardPaymentStyles.cancelButton} displayName="Cancel" execute={onCancel} />
                                </Grid>
                                <Grid className={cardPaymentStyles.buttonRowGridItemSpacing} item md={3} sm={6} xs={12} />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </form>
    ));
};
