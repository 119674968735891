import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information for the choices page.
 */
export const ChoicesPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    introduction: {
        margin: "18px 18px 15px 18px",
    },
    introductionTitle: {
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "1.25",
        marginBottom: "14px",
    },
    introductionSubTitle: {
        fontSize: "12px",
        lineHeight: "1.25",
    },
    choicesAreas: {
        borderBottom: "1px solid #ced4da",
        borderTop: "1px solid #ced4da",
        margin: "0 18px",
        padding: "15px 0",
        "& > div": {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill,minmax(120px, 1fr))",
            margin: "0 -5px",
        },
    },
    choicesArea: {
        border: "1px solid #ced4da",
        borderRadius: "0",
        boxShadow: "none",
        margin: "5px",
        "& > button": {
            "& > div.MuiCardMedia-root": {
                minHeight: "90px",
            },
            "& > div.MuiCardContent-root": {
                padding: "10px",
                textAlign: "center",
            },
        },
    },
    choicesAreaTitle: {
        fontSize: "14px",
    },
    choicesAreaSubTitle: {
        fontSize: "10px",
    },
    choicesAreasSummaryContainer: {
        margin: "0px 18px 30px 18px",
        padding: "0 0 16px 0",
    },
    choicesAreasSummaryTitle: {
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "1.25",
        margin: "14px 0px",
    },
    choicesAreasSummary: {
        "& > div": {},
    },
    choicesAreaSummary: {
        border: "1px solid #CED4DA",
        borderRadius: "0px",
        padding: "12px 10px",
        margin: "0px 0px 10px 0px",
        boxShadow: "0px 3px 3px #0000001a",
        "& > .MuiCardContent-root": {
            padding: "0px",
        },
        "&:last-child": {
            marginBottom: "0",
        },
    },
    choicesAreaSummaryTitleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "& button": {
            width: "auto",
            "& img": {
                maxWidth: "30px",
                maxHeight: "30px",
            },
        },
    },
    choicesAreaSummaryTitle: {
        fontSize: "14px",
    },
    choicesAreaSummarySubTitle: {
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    choicesAreaSummaryOptionsContainer: {
        fontSize: "12px",
    },
    choicesAreaSummaryExtrasContainer: {
        fontSize: "12px",
    },
    editIcon: {},
    productSummaryContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "10px",
        "& > div:first-child": {
            paddingRight: "30px",
        },
    },
    [theme.breakpoints.up("md")]: {
        introduction: {
            margin: "28px 18px 15px 18px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flex: "0 0 auto", // Fix for MacBook Safari - Catalina
            "& > button": {
                padding: "0",
            },
            "& .MuiSvgIcon-root": {
                margin: "-7px",
                fontSize: "35px",
            },
        },
        introductionTitle: {
            fontSize: "20px",
            marginBottom: "8px",
        },
        choicesAreas: {
            borderTop: "none",
            marginTop: "-3px",
            paddingTop: "0px",
            "& > div": {
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill,minmax(180px, 1fr))",
                margin: "0 -13px",
            },
        },
        choicesArea: {
            margin: "13px",
        },
        choicesAreaSummary: {
            margin: "0px 0px 6px 0px",
            padding: "24px 30px 18px 30px",
            "& > .MuiCardContent-root": {
                display: "flex",
                flexDirection: "column",
            },
        },
        choicesAreasSummaryTitle: {
            fontSize: "16px",
            marginTop: "27px",
        },
        choicesAreaSummaryTitle: {
            fontSize: "16px",
            marginBottom: "12px",
        },
        choicesAreaSummarySubTitle: {
            marginBottom: "8px",
        },
        choicesAreaSummaryOptionsContainer: {
            margin: "4px 0",
        },
        productSummaryContainer: {
            marginBottom: "5px",
        },
    },
    choicesAreasSummarySubTotal: {
        display: "flex",
        fontSize: "12px",
        "& > div:first-child": {
            fontWeight: "bold",
            marginRight: "12px",
        },
        ["@media (max-width:365px)"]: {
            display: "block",
        },
    },
    choicesAreasMainSummaryTitle: {
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
    },
    choicesAreasSummarySubtitle: {
        display: "flex",
        gap: "10px 30px",
    },
    choicesAreasMainHeadingBlock: {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
        gap: "80px",
        ["@media (max-width:610px)"]: {
            display: "block",
        },
    },
}));
