// Libraries
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import React from "react";
import { hot, setConfig } from "react-hot-loader";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// Core
import { GlobalHistory } from "Core/CoreIndex";

// Custom
import { AppRoutes } from "./AppRoutes";
import { Layout } from "Custom/Views/Layout";
import { defaultTheme } from "Custom/StylesAppSpecific/AppStyling";

setConfig({
    showReactDomPatchNotification: false,
});

const App: React.FC = () => {
    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={defaultTheme}>
                <ThemeProvider theme={defaultTheme}>
                    <CssBaseline>
                        <Router history={GlobalHistory}>
                            <Layout>
                                <AppRoutes />
                            </Layout>
                        </Router>
                    </CssBaseline>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
};

export default hot(module)(App);
