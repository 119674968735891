import * as MobX from "mobx";

// Custom
import { CPItemModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { BaseStore } from "./BaseStore";
import { Stores } from "../Stores";

export class HouseAreaStore extends BaseStore {
    @MobX.observable
    private houseAreas: CPItemModelDTO[] = [];

    @MobX.action
    public populateHouseAreas(houseAreas: CPItemModelDTO[]) {
        this.houseAreas = houseAreas;
    }

    @MobX.computed
    public get getHouseAreas(): CPItemModelDTO[] {
        return this.houseAreas;
    }

    public Init(stores: Stores) {}
}
