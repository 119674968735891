import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information for the new snag form.
 */
export const NewSnagFormStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        "& div.MuiFormControl-root": {
            marginBottom: "16px",
            width: "100%",
        },
        "& div.MuiOutlinedInput-multiline": {
            padding: "8px 10px",
            "& textarea": {
                minHeight: "70px",
            },
        },
        "& button": {
            width: "100%",
        },
    },
    selectionRow: {},
    textAreaRow: {},
    buttonRow: {},
    formControl: {},
    formInputControl: {},
    formTextAreaControl: {},
    submitButton: {},
    [theme.breakpoints.up("md")]: {
        root: {
            "& > form > div.form-group > div": {
                backgroundColor: "#F9F9F9",
            },
        },
        selectionRow: {
            display: "flex",
            flex: "1",
            margin: "0 -15px",
            paddingBottom: "10px",
        },
        selectionCol: {
            display: "flex",
            flex: "1",
            margin: "0 -15px",
            paddingBottom: "10px",
            flexDirection: "column",
        },
        textAreaRow: {
            display: "flex",
            flex: "1",
            margin: "0 -15px",
            paddingBottom: "10px",
            "& > div": {
                flex: 1,
            },
        },
        textAreaCol: {
            display: "flex",
            flex: "1",
            margin: "0 -15px",
            paddingBottom: "10px",
            flexDirection: "column",
            "& > div": {
                flex: 1,
            },
        },
        buttonRow: {
            paddingTop: "8px",
        },
        formControl: {
            padding: "0 15px",
        },
        formInputControl: {
            minWidth: "285px !important",
        },
        formTextAreaControl: {
            minWidth: "100%",
        },
        submitButton: {
            maxWidth: "255px",
        },
    },
}));
