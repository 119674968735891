// Libraries
import { action, observable } from "mobx";
import "moment-timezone";

// Core
import { ModelBase } from "Core/Models/ModelBase";

export class SnagModel extends ModelBase<SnagModel, SnagModelDTO> {
    // #region Properties

    @observable
    public id: string | undefined = "";

    @observable
    public propertyId: string = "";

    @observable
    public title: string = "";

    @observable
    public dueDate: string | undefined = "";

    @observable
    public areaId: string = "";

    @observable
    public details: string = "";

    @observable
    public snagNumber: string = "";

    @observable
    public resolved: boolean = false;

    @observable
    public actionTaken: string = "";

    @observable
    public isDeleted: boolean = false;

    @observable
    public propertySnagCategoryId: string | undefined = "";

    @observable
    public propertySnagDepartmentId: string | undefined = "";

    @observable
    public concurrencyToken: string = "";

    @observable
    public reportedDate: string = "";

    @observable
    public resolverIdentityUserId: string | undefined = "";

    @observable
    public resolvedDate: string | undefined = "";

    @observable
    public isCreatedByCustomer: boolean = false;

    @observable
    public snagStatus: number = 0;

    @observable
    public snagStatusUpdatedDate: string | undefined = "";

    @observable
    public rejectedReason: string | undefined = "";

    @observable
    public resolvedBy: string = "";

    @observable
    public areaName: string = "";

    @observable
    public houseAreas: CPItemModelDTO[] = [];

    @observable
    public snagTypes: CPItemModelDTO[] = [];

    @observable
    public snagPhotos: SnagPhotoModelDTO[] = [];

    // #endregion Properties

    @action
    public fromDto = (dto: SnagModelDTO): void => {
        this.id = dto.id;
        this.propertyId = dto.propertyId;
        this.title = dto.title;
        this.dueDate = dto.dueDate;
        this.areaId = dto.areaId;
        this.details = dto.details;
        this.snagNumber = dto.snagNumber;
        this.resolved = dto.resolved;
        this.actionTaken = dto.actionTaken;
        this.isDeleted = dto.isDeleted;
        this.propertySnagCategoryId = dto.propertySnagCategoryId;
        this.propertySnagDepartmentId = dto.propertySnagDepartmentId;
        this.concurrencyToken = dto.concurrencyToken;
        this.reportedDate = dto.reportedDate;
        this.resolverIdentityUserId = dto.resolverIdentityUserId;
        this.resolvedDate = dto.resolvedDate;
        this.isCreatedByCustomer = dto.isCreatedByCustomer;
        this.snagStatus = dto.snagStatus;
        this.snagStatusUpdatedDate = dto.snagStatusUpdatedDate;
        this.rejectedReason = dto.rejectedReason;
        this.resolvedBy = dto.resolvedBy;
        this.areaName = dto.areaName;
    };

    public toDto = (): SnagFormModelDTO => {
        let isCreatedByCustomer = true;

        let snag: SnagModelDTO = {
            id: this.id,
            propertyId: this.propertyId,
            title: this.title,
            dueDate: this.dueDate,
            areaId: this.areaId,
            details: this.details,
            snagNumber: this.snagNumber,
            resolved: this.resolved,
            actionTaken: this.actionTaken,
            isDeleted: this.isDeleted,
            propertySnagCategoryId: this.propertySnagCategoryId,
            propertySnagDepartmentId: this.propertySnagDepartmentId,
            concurrencyToken: this.concurrencyToken,
            reportedDate: this.reportedDate,
            resolverIdentityUserId: this.resolverIdentityUserId,
            resolvedDate: this.resolvedDate,
            isCreatedByCustomer: isCreatedByCustomer,
            snagStatus: this.snagStatus,
            snagStatusUpdatedDate: this.snagStatusUpdatedDate,
            rejectedReason: this.rejectedReason,
            resolvedBy: this.resolvedBy,
            areaName: this.areaName,
        };

        // Photos retrived in parent call.
        let snagPhotos: SnagPhotoModelDTO[] = [];

        let model: SnagFormModelDTO = {
            snag: snag,
            snagPhotos: snagPhotos,
        };

        return model;
    };
}

export interface SnagModelDTO {
    id: string | undefined;
    propertyId: string;
    title: string;
    dueDate?: string;
    areaId: string;
    details: string;
    snagNumber: string;
    resolved: boolean;
    actionTaken: string;
    isDeleted: boolean;
    propertySnagCategoryId?: string;
    propertySnagDepartmentId?: string;
    concurrencyToken: string;
    reportedDate: string;
    resolverIdentityUserId?: string;
    resolvedDate?: string;
    isCreatedByCustomer: boolean;
    snagStatus: number;
    snagStatusUpdatedDate: string | undefined;
    rejectedReason?: string;
    resolvedBy: string;
    areaName: string;
}

export interface SnagFormModelDTO {
    snag: SnagModelDTO;
    snagPhotos: SnagPhotoModelDTO[];
}

export interface ViewSnagDTO {
    houseAreas: CPItemModelDTO[];
    snag: SnagModelDTO;
    snagPhotos: SnagPhotoModelDTO[];
}

export interface CPItemModelDTO {
    id: string;
    name: string;
}

export interface SnagPhotoModelDTO {
    id: string;
    fileName: string;
    azureFileName: string;
    base64: string;
    azureFileNameFullResolution: string;
    azureThumbnailURL: string;
    resolvedImage: boolean;
    propertySnagId?: string | undefined;
    uploadedIdentityId: string | undefined;
    uploadedBy: string;
    uploadedDate: string;
    originalIsDeletedState: boolean;
    isDeleted: boolean;
    type: number;
}
