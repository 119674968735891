import { Box, darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import styled from "styled-components";

/**
 * Contains style information for the choices product group component.
 */
export const ChoicesProductGroupStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
    },
    productContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        "& > div": {
            padding: "10px",
        },
        "&:not(:last-child)": {
            borderBottom: "1px dashed #ced4da",
        },
    },
    productContentRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "calc(100% - 135px)",
    },
    productContentContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        alignItems: "center",
        gap: "10px",
        "& > div:first-child": {
            flex: "1",
            flexDirection: "column",
        },
        "& > div:last-child": {
            display: "flex",
            "& > div": {
                flex: "1",
                "& > div": {
                    wordBreak: "break-all",
                    whiteSpace: "normal",
                },
            },
        },
    },
    productImageContainer: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 20px 0px 0px",
        maxWidth: "91px",
        width: "100%",
        maxHeight: "91px",
        height: "100%",
        minHeight: "100%",
        minWidth: "91px",

        "& > img": {
            maxWidth: "91px",
            maxHeight: "91px",
            width: "100%",
            height: "100%",
            minHeight: "100%",
            minWidth: "91px",
        },
    },
    productTitleDesc: {
        minWidth: "500px",
    },
    productTitle: {
        fontWeight: "bold",
    },
    productDescription: {},
    productVariantsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    productRadio: {
        border: "1px solid #CED4DA",
        borderRadius: "5px",
        padding: "3px 10px",
        width: "100%",
        margin: "0px",
        maxHeight: "41px",
    },
    productRadioChecked: {
        border: "1px solid #CED4DA",
        borderRadius: "5px",
        padding: "3px 10px",
        width: "100%",
        margin: "0px",
        backgroundColor: "#50C14A",
        color: "#FFFFFF",
        "& > span > span > div > svg": {
            color: "#FFFFFF",
        },
    },
    radioButton: {
        color: "#7F7F7F",
        padding: "0 5px 0 0",
        "& > span > div > svg": {
            height: "22px",
            width: "22px",
        },
    },
    productCheckbox: {
        border: "1px solid #CED4DA",
        borderRadius: "5px",
        padding: "3px 10px",
        width: "100%",
        margin: "0px",
        maxHeight: "41px",
    },
    productCheckboxChecked: {
        border: "1px solid #CED4DA",
        borderRadius: "5px",
        padding: "3px 10px",
        width: "100%",
        margin: "0px",
        backgroundColor: "#50C14A",
        color: "#FFFFFF",
        "& > span > span > svg": {
            color: "#FFFFFF",
        },
    },
    checkboxButton: {
        color: "#7F7F7F",
        padding: "0 5px 0 0",
        "& > span > svg": {
            height: "22px",
            width: "22px",
        },
    },
    subtitle: {
        backgroundColor: "#3C3C3B",
        color: "#FFFFFF",
        padding: "7px 18px",
    },
    productVariantSelect: {
        marginTop: "auto",
        maxWidth: "255px",
    },
    noneoftheabovecontainer: {
        padding: "10px",
        "& > div": {
            padding: "10px",
        },
    },
    productRadioNoneOfTheAbove: {
        border: "1px solid transparent",
        borderRadius: "5px",
        padding: "3px 10px",
        width: "100%",
        margin: "0px",
    },
    [theme.breakpoints.up("md")]: {
        productContainer: {
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        productContentContainer: {
            flexDirection: "row",
            minWidth: "750px",
        },
        productContentRow: {
            alignItems: "center",
        },
        productRadioNoneOfTheAbove: {
            border: "none",
            color: "#191919",
            margin: "0px",
            "& > span": {
                padding: "0 0 0 10px",
            },
        },
        productRadio: {
            border: "none",
            color: "#191919",
            margin: "0px",
            "& > span": {
                padding: "0 0 0 10px",
            },
        },
        productRadioChecked: {
            background: "none",
            color: "#191919",
            border: "none",
            margin: "0px",
            "& > span": {
                padding: "0 0 0 10px",
            },
            "& > span > span > div > svg": {
                color: "#7F7F7F",
            },
        },
        productCheckbox: {
            border: "none",
            color: "#191919",
            margin: "0px",
            "& > span": {
                padding: "0 0 0 10px",
            },
        },
        productCheckboxChecked: {
            background: "none",
            color: "#191919",
            border: "none",
            margin: "0px",
            "& > span": {
                padding: "0 0 0 10px",
            },
            "& > span > span > svg": {
                color: "#7F7F7F",
            },
        },
        productVariantSelect: {
            minWidth: "255px",
            "& .MuiSelect-icon": {
                top: "auto !important",
            },
        },
    },
    counterButton: {
        color: "black",
        width: "30px",
        height: "30px",
        maxWidth: "30px",
        maxHeight: "30px",
        background: "#ffffff 0% 0% no-repeat padding-box",
        border: "1px solid #ced4da",
        cursor: "pointer",
    },
    counterLabel: {
        marginRight: "5px",
        fontWeight: "bold",
    },
    counterInput: {
        width: "112px",

        "& #orderQuantity-input": {
            height: "33px",
            boxSizing: "border-box",
        },
    },
}));

export const GridContainerCounter: any = styled(Box)`
    display: flex;
    align-items: center;
`;
