// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "util";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { BlobImageModel, BlobImageModelDTO } from "Custom/Models/BlobImageModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "Custom/Stores";
import { BlankModel } from "Core/Models/BlankModel";
import { AppUrls } from "Custom/Globals";

export class PrivateImageViewModel extends ViewModelBase<BlobImageModel> {
    constructor(propertyId?: string) {
        super(new BlobImageModel());
        this.setDecorators(BlobImageModel);
    }

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    public loadImageByURL = async (url: string) => {
        console.log("loadImageByURL", url);
        try {
            const apiResult = await this.Get<string>(AppUrls.Server.Api.Image.GetBlob + "/?url=" + url);
            return apiResult;
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
