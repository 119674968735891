// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// App
import { ProductSummaryModel } from "Custom/Models/DashboardModels/ProductSummary";
import { Format } from "Custom/Utils";

export class ProductSummaryViewModel extends ViewModelBase<ProductSummaryModel> {
    constructor(product: ProductSummaryModel) {
        super(product);
        this.setDecorators(ProductSummaryModel);
    }

    @computed
    public get productTitle() {
        if (this.hasVariant) {
            return this.model.productVariantCode + " - " + this.model.productVariantName;
        }

        return this.model.productCode + " - " + this.model.productName;
    }

    @computed
    public get productQuantity() {
        return this.model.quantity == 0 || this.model.quantity == null ? 1 : this.model.quantity;
    }

    @computed
    public get hasVariant() {
        if (this.model.productVariantId !== "" && this.model.productVariantId !== null && this.model.productVariantId !== undefined) {
            return true;
        }

        return false;
    }

    @computed
    public get cost() {
        if (this.model.isPOA) {
            return "POA";
        }

        if (this.model.isIncluded) {
            return "Included";
        }

        return `£${Format.currencyFormat(this.model.cost)}`;
    }

    @computed
    public get isOption() {
        return this.model.isIncluded || this.model.isUpgrade;
    }

    @computed
    public get isExtra() {
        return this.model.isOptional;
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
