// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { ProductSummaryModel, ProductSummaryModelDTO } from "./ProductSummary";

// Custom
//import { ChoiceModel, ChoiceModelDTO } from "./ChoiceModel";

export class ProductGroupSummaryModel extends ModelBase<ProductGroupSummaryModel, ProductGroupSummaryAndRelatedModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public isCompleted: boolean = false;

    @observable
    public orderId: string = "";

    @observable
    public productGroupId: string = "";

    @observable
    public productGroupName: string = "";

    @observable
    public areaId: string = "";

    @observable
    public isNoneOfTheAbove: boolean = false;

    @observable
    public products: ProductSummaryModel[] = [];

    // #endregion Properties

    @action
    public fromDto = (dto: ProductGroupSummaryAndRelatedModelDTO): void => {
        this.id = dto.productGroupSummary.id;
        this.isCompleted = dto.productGroupSummary.isComplete;
        this.orderId = dto.productGroupSummary.orderId;
        this.productGroupId = dto.productGroupSummary.productGroupId;
        this.productGroupName = dto.productGroupSummary.productGroupName;
        this.areaId = dto.productGroupSummary.areaId;
        this.isNoneOfTheAbove = dto.productGroupSummary.isNoneOfTheAbove;

        for (const productDto of dto.productsSummary) {
            const product = new ProductSummaryModel();

            product.fromDto(productDto);

            this.products.push(product);
        }
    };

    public toDto = (): ProductGroupSummaryAndRelatedModelDTO => {
        throw "toDto is not supported";
    };
}

export interface ProductGroupSummaryModelDTO {
    id: string;
    isComplete: boolean;
    orderId: string;
    productGroupId: string;
    productGroupName: string;
    areaId: string;
    isNoneOfTheAbove: boolean;
}

export interface ProductGroupSummaryAndRelatedModelDTO {
    productGroupSummary: ProductGroupSummaryModelDTO;
    productsSummary: ProductSummaryModelDTO[];
}
