// Libraries
import { action, computed, observable } from "mobx";
import moment, { Moment } from "moment";
import "moment-timezone";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { StripePaymentRequest } from "../../../Custom/Models/StripePaymentRequest";
import { propertyHouseTypeImages } from "../PropertyHouseTypeImages";

export class PropertyModel extends ModelBase<PropertyModel, PropertyModelDTO> {
    // #region Properties

    @observable
    public id = "";

    @observable
    public agreedPurchasePrice = 0.0;

    @observable
    public reservationFeePaid = 0.0;

    @observable
    public tempChoicesTargetDate: Moment | null = null;

    @observable
    public tempChoicesActualDate: Moment | null = null;

    @observable
    public addressLine1 = "";

    @observable
    public addressLine2 = "";

    @observable
    public developmentContactEmail = "";

    @observable
    public developmentContactNumber = "";

    @observable
    public developmentName = "";

    @observable
    public developmentDescription = "";

    @observable
    public houseTypeImageUrl = "";

    @observable
    public houseTypeImageUrlTwo = "";

    @observable
    public houseTypeName = "";

    @observable
    public houseTypeDescription = "";

    @observable
    public numberOfBedrooms = 0;

    @observable
    public propertyNumberOfBedrooms = 0;

    @observable
    public plotNumber = 0;

    @observable
    public plotDescription = "";

    @observable
    public postcode = "";

    @observable
    public propertyTypeName = "";

    @observable
    public plotTypeName = "";

    @observable
    public salesAgentName = "";

    // Build dates
    @observable
    public buildActualDate: Moment | null = null;

    @observable
    public buildTargetDate: Moment | null = null;

    @observable
    public buildTargetDateMonth: string | null = null;

    @observable
    public buildTargetDateYear: string | null = null;

    // Choice dates
    @observable
    public choicesActualDate: Moment | null = null;

    @observable
    public choicesTargetDate: Moment | null = null;

    // Contract dates
    @observable
    public contractActualDate: Moment | null = null;

    @observable
    public contractTargetDate: Moment | null = null;

    // Legal dates
    @observable
    public legalActualDate: Moment | null = null;

    @observable
    public legalTargetMonth = "";

    @observable
    public legalTargetYear = "";

    @observable
    public stripePaymentRequests: StripePaymentRequest[] = [];

    @observable
    public hasReservationType1: boolean = false;

    @observable
    public hasOrder: boolean = false;

    @observable
    public orderCompletedDate: Moment | null = null;

    @observable
    public developmentId: string = "";

    @observable
    public houseTypeId: string = "";

    @observable
    public propertyHouseTypeImages: propertyHouseTypeImages[] = [];

    // #endregion Properties

    @action
    public fromDto = (dto: PropertyModelDTO): void => {
        this.id = dto.id;
        this.agreedPurchasePrice = dto.agreedPurchasePrice;
        this.reservationFeePaid = dto.reservationFeePaid;
        this.tempChoicesActualDate = !isEmptyOrWhitespace(dto.tempChoicesActualDate) ? moment.tz(dto.tempChoicesActualDate, "Europe/London") : null;
        this.tempChoicesTargetDate = !isEmptyOrWhitespace(dto.tempChoicesTargetDate) ? moment.tz(dto.tempChoicesTargetDate, "Europe/London") : null;
        this.addressLine1 = dto.addressLine1;
        this.addressLine2 = dto.addressLine2;
        this.developmentContactEmail = dto.developmentContactEmail;
        this.developmentContactNumber = dto.developmentContactNumber;
        this.developmentName = dto.developmentName;
        this.developmentDescription = dto.developmentDescription;
        this.houseTypeImageUrlTwo = dto.houseTypeImageUrlTwo;
        this.houseTypeName = dto.houseTypeName;
        this.houseTypeDescription = dto.houseTypeDescription;
        this.numberOfBedrooms = dto.numberOfBedrooms;
        this.propertyNumberOfBedrooms = dto.propertyNumberOfBedrooms;
        this.plotNumber = dto.plotNumber;
        this.plotDescription = dto.plotDescription;
        this.postcode = dto.postcode;
        this.propertyTypeName = dto.propertyTypeName;
        this.plotTypeName = dto.plotTypeName;
        this.salesAgentName = dto.salesAgentName;
        this.houseTypeId = dto.houseTypeId;
        this.developmentId = dto.developmentId;

        // Build dates
        this.buildActualDate = !isEmptyOrWhitespace(dto.buildActualDateUTC) ? moment.tz(dto.buildActualDateUTC, "Europe/London") : null;
        this.buildTargetDate = !isEmptyOrWhitespace(dto.buildTargetDateUTC) ? moment.tz(dto.buildTargetDateUTC, "Europe/London") : null;
        this.buildTargetDateMonth = dto.buildTargetDateMonth;
        this.buildTargetDateYear = dto.buildTargetDateYear;
        // Choice dates
        this.choicesActualDate = !isEmptyOrWhitespace(dto.choicesActualDateUTC) ? moment.tz(dto.choicesActualDateUTC, "Europe/London") : null;
        this.choicesTargetDate = !isEmptyOrWhitespace(dto.choicesTargetDateUTC) ? moment.tz(dto.choicesTargetDateUTC, "Europe/London") : null;

        // Contract dates
        this.contractActualDate = !isEmptyOrWhitespace(dto.contractActualDateUTC) ? moment.tz(dto.contractActualDateUTC, "Europe/London") : null;
        this.contractTargetDate = !isEmptyOrWhitespace(dto.contractTargetDateUTC) ? moment.tz(dto.contractTargetDateUTC, "Europe/London") : null;

        // Legal dates
        this.legalActualDate = !isEmptyOrWhitespace(dto.legalActualDateUTC) ? moment.tz(dto.legalActualDateUTC, "Europe/London") : null;
        this.legalTargetMonth = dto.legalTargetMonth;
        this.legalTargetYear = dto.legalTargetYear;

        this.stripePaymentRequests = dto.stripePaymentRequests;

        this.hasReservationType1 = dto.hasReservationType1;
        this.hasOrder = dto.hasOrder;
        this.orderCompletedDate = !isEmptyOrWhitespace(dto.orderCompletedDate) ? moment.tz(dto.orderCompletedDate, "Europe/London") : null;
        this.propertyHouseTypeImages = dto.propertyHouseTypeImages;
    };

    public toDto = (): PropertyModelDTO => {
        throw "toDto is not supported";
    };
}

export interface PropertyModelDTO {
    id: string;
    agreedPurchasePrice: number;
    reservationFeePaid: number;
    tempChoicesTargetDate: string;
    tempChoicesActualDate: string;
    addressLine1: string;
    addressLine2: string;
    developmentContactEmail: string;
    developmentContactNumber: string;
    developmentName: string;
    developmentDescription: string;
    houseTypeImageUrl: string;
    houseTypeImageUrlTwo: string;
    houseTypeName: string;
    houseTypeDescription: string;
    numberOfBedrooms: number;
    propertyNumberOfBedrooms: number;
    plotNumber: number;
    plotDescription: string;
    postcode: string;
    propertyTypeName: string;
    plotTypeName: string;
    salesAgentName: string;
    houseTypeId: string;
    developmentId: string;

    // Build dates
    buildActualDateUTC: string;
    buildTargetDateUTC: string;
    buildTargetDateMonth: string;
    buildTargetDateYear: string;

    // Choice dates
    choicesActualDateUTC: string;
    choicesTargetDateUTC: string;

    // Contract dates
    contractActualDateUTC: string;
    contractTargetDateUTC: string;

    // Legal dates
    legalActualDateUTC: string;
    legalTargetMonth: string;
    legalTargetYear: string;

    stripePaymentRequests: StripePaymentRequest[];

    hasReservationType1: boolean;
    hasOrder: boolean;
    orderCompletedDate: string;
    propertyHouseTypeImages: propertyHouseTypeImages[];
}
