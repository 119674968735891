// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";

export class Property extends ModelBase<Property, PropertyDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public houseTypeId = "";

    @MobX.observable
    public developmentId = "";

    @MobX.observable
    public buildStageId = "";

    @MobX.observable
    public salesStatusId = "";

    @MobX.observable
    public addressLine1 = "";

    @MobX.observable
    public addressLine2 = "";

    @MobX.observable
    public postcode = "";

    @MobX.observable
    public plotNumber = "";

    @MobX.observable
    public basePurchasePrice = "";

    @MobX.observable
    public concurrencyToken = "";

    @MobX.observable
    public isDeleted = false;

    @MobX.observable
    public targetBuildDate = "";

    @MobX.observable
    public targetBuildDateMonth = "";

    @MobX.observable
    public targetBuildDateYear = "";

    @MobX.observable
    public certificateGenerated = false;

    @MobX.action
    public fromDto = (model: PropertyDTO): void => {
        this.id = model.id;
        this.houseTypeId = model.houseTypeId;
        this.developmentId = model.developmentId;
        this.buildStageId = model.buildStageId;
        this.salesStatusId = model.salesStatusId;
        this.addressLine1 = model.addressLine1;
        this.addressLine2 = model.addressLine2;
        this.postcode = model.postcode;
        this.plotNumber = model.plotNumber;
        this.basePurchasePrice = model.basePurchasePrice;
        this.concurrencyToken = model.concurrencyToken;
        this.isDeleted = model.isDeleted;
        this.targetBuildDate = model.targetBuildDate;
        this.targetBuildDateMonth = model.targetBuildDateMonth;
        this.targetBuildDateYear = model.targetBuildDateYear;
        this.certificateGenerated = model.certificateGenerated;
    };

    public toDto = (): PropertyDTO => {
        throw "toDto is not supported";
    };

    @MobX.action
    public reset = (): void => {
        this.id = "";
        this.houseTypeId = "";
        this.developmentId = "";
        this.buildStageId = "";
        this.salesStatusId = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.postcode = "";
        this.plotNumber = "";
        this.basePurchasePrice = "";
        this.concurrencyToken = "";
        this.isDeleted = false;
        this.targetBuildDate = "";
        this.targetBuildDateMonth = "";
        this.targetBuildDateYear = "";
        this.certificateGenerated = false;
    };
}

export interface PropertyDTO {
    id: string;
    houseTypeId: string;
    developmentId: string;
    buildStageId: string;
    salesStatusId: string;
    addressLine1: string;
    addressLine2: string;
    postcode: string;
    plotNumber: string;
    basePurchasePrice: string;
    concurrencyToken: string;
    isDeleted: boolean;
    targetBuildDate: string;
    targetBuildDateMonth: string;
    targetBuildDateYear: string;
    certificateGenerated: boolean;
}
