// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { ChoiceModel, ChoiceModelDTO } from "./ChoiceModel";

export class ChoicesModel extends ModelBase<ChoicesModel> {
    // #region Properties

    @observable
    public choicesAreas = observable<ChoiceModel>([]);

    @observable
    public isChoicesLocked: boolean = false;

    // #endregion Properties

    @action
    public fromDto = (dto: ChoiceModelDTO[]): void => {
        for (const choice of dto) {
            const choiceModel = new ChoiceModel();

            choiceModel.fromDto(choice);

            this.choicesAreas.push(choiceModel);
        }
    };

    public toDto = (): ChoiceModelDTO[] => {
        throw "toDto is not supported";
    };
}
