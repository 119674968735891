// Libraries
import { action, computed, observable } from "mobx";

// Core
import { StoresInstance } from "Core/Base";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { Client, Server } from "Custom/Globals/AppUrls";

export class HeaderLogoModel extends ViewModelBase<any> {
    constructor() {
        super({});
    }

    @action
    public handleNavBarLogoClick = async (): Promise<void> => {
        const propertyId = StoresInstance.domain.AccountStore.PropertyId;

        this.history.push("/:propertyId?".replace(":propertyId", propertyId!));
    };

    @action
    public handleResetLogoClick = async (): Promise<void> => {
        this.history.push(Client.Path.Account.Login);
    };

    // #region Bolierplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
