// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { ProductGroupSummaryModel, ProductGroupSummaryModelDTO } from "./ProductGroupSummary";
import { ProductSummaryModelDTO } from "./ProductSummary";

// Custom
//import { ChoiceModel, ChoiceModelDTO } from "./ChoiceModel";

export class AreaSummaryModel extends ModelBase<AreaSummaryModel, AreaSummaryAndRelatedModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public isComplete: boolean = false;

    @observable
    public orderId: string = "";

    @observable
    public areaId: string = "";

    @observable
    public areaName: string = "";

    @observable
    public productGroups: ProductGroupSummaryModel[] = [];

    // #endregion Properties

    @action
    public fromDto = (dto: AreaSummaryAndRelatedModelDTO): void => {
        this.id = dto.areaSummary.id;
        this.isComplete = dto.areaSummary.isComplete;
        this.orderId = dto.areaSummary.orderId;
        this.areaId = dto.areaSummary.areaId;
        this.areaName = dto.areaSummary.areaName;

        for (const productGroupDto of dto.productGroupsSummary) {
            const productGroup = new ProductGroupSummaryModel();

            let products = dto.productsSummary.filter(p => p.orderAreaProductGroupId === productGroupDto.productGroupId && p.orderAreaId === productGroupDto.areaId);

            productGroup.fromDto({
                productGroupSummary: productGroupDto,
                productsSummary: products,
            });

            this.productGroups.push(productGroup);
        }
    };

    public toDto = (): AreaSummaryAndRelatedModelDTO => {
        throw "toDto is not supported";
    };
}

export interface AreaSummaryModelDTO {
    id: string;
    isComplete: boolean;
    orderId: string;
    areaId: string;
    areaName: string;
}

export interface AreaSummaryAndRelatedModelDTO {
    areaSummary: AreaSummaryModelDTO;
    productGroupsSummary: ProductGroupSummaryModelDTO[];
    productsSummary: ProductSummaryModelDTO[];
}
