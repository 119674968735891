import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";

export class BlobImageModel extends ModelBase<BlobImageModel, BlobImageModelDTO> {
    @MobX.observable
    public image: string = "";

    @MobX.observable
    public fileType: string = "";

    // #endregion Properties
    @MobX.action
    public fromDto = (dto: BlobImageModelDTO): void => {
        this.image = dto.image;
        this.fileType = dto.fileType;
    };

    public toDto = (): BlobImageModelDTO => {
        throw "toDto is not supported";
    };
}

export interface BlobImageModelDTO {
    image: string;
    fileType: string;
}
