// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// App
import { ProductVariantViewModel } from "./ProductVariantViewModel";
import { ProductModel } from "Custom/Models/DashboardModels/ProductModel";
import { Format } from "../../Utils";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import HouseType1Svg from "../../Content/Images/noImageDefault.svg";

export class ProductViewModel extends ViewModelBase<ProductModel> {
    @observable
    public productVariantViewModels: ProductVariantViewModel[] = [];

    constructor(product: ProductModel) {
        super(product);
        this.setDecorators(ProductModel);

        this.setInitialValues(product);
        this.createViewModels(this.getIsValid(product.productVariantId));
    }

    private getIsValid(val: string) {
        if (val !== null && val !== undefined && val !== "") {
            return val;
        }

        return "";
    }

    @action
    private setInitialValues(product: ProductModel) {
        if (this.getIsValid(product.orderAreaProductGroupProductId)) {
            this.model.isSelected = true;
        }
    }

    @computed
    public get OrderId() {
        return this.model.orderId;
    }

    @computed
    public get productImage() {
        if (this.hasVariants) {
            const variantId = this.getSelectedVariant ? this.getSelectedVariant : "";
            let variant = this.productVariantViewModels.find(pv => pv.model.id === variantId);
            if (variant && variant.productVariantThumbnailImage) {
                return variant.productVariantThumbnailImage;
            }
        }
        if (this.model.productThumbnailImageUrl !== null && this.model.productThumbnailImageUrl !== undefined && this.model.productThumbnailImageUrl !== "") {
            return this.model.productThumbnailImageUrl;
        }

        return HouseType1Svg;
    }

    @computed
    public get productOriginalImage() {
        if (this.hasVariants) {
            const variantId = this.getSelectedVariant ? this.getSelectedVariant : "";
            let variant = this.productVariantViewModels.find(pv => pv.model.id === variantId);
            if (variant && variant.productVariantOriginalImage) {
                return variant.productVariantOriginalImage;
            }
        }
        if (this.model.productOriginalImageUrl !== null && this.model.productOriginalImageUrl !== undefined && this.model.productOriginalImageUrl !== "") {
            return this.model.productOriginalImageUrl;
        }

        return HouseType1Svg;
    }

    @computed
    public get productTitle() {
        return this.model.productCode + " - " + this.model.productName;
    }

    @computed
    public get productVariants() {
        return this.productVariantViewModels;
    }

    @computed
    public get productsSummary() {
        return this.model.productsSummary;
    }

    @computed
    public get productVariantsOptions() {
        let options: KeyValuePair[] = [];

        let productSummary = this.productsSummary.find(
            item => item.orderAreaId === this.model.areaId && item.orderAreaProductGroupId === this.model.productGroupId && item.productId === this.model.productId,
        );
        let variants = this.productVariants.slice().sort((a, b) => a.model.productVariantName.localeCompare(b.model.productVariantName));

        if (variants.length > 0) {
            for (let variant of variants.filter(v => v.isHidden == "False")) {
                options.push({
                    key: variant.productVariantTitle,
                    value: variant.id,
                });
            }
        }

        if (productSummary !== undefined) {
            if (options.findIndex(o => o.value === productSummary!.productVariantId) === -1) {
                let variant = this.productVariants.find(pv => pv.productId === this.model.productId && pv.id === productSummary!.productVariantId);

                if (variant) {
                    options.push({
                        key: variant.productVariantTitle,
                        value: variant.id,
                    });
                }
            }
        }

        return options;
    }

    @computed
    public get hasVariants() {
        // If ProductVariants > 0
        // If product.hasVariants
        // If order already has variant selected

        return this.productVariants.length > 0;
    }

    @computed
    public get isOption() {
        return this.model.isIncluded || this.model.isUpgrade;
    }

    @computed
    public get isExtra() {
        return this.model.isOptional;
    }

    @computed
    public get getSelectedVariant() {
        let variant = this.productVariantViewModels.find(pv => pv.model.isSelected === true);

        return variant ? variant.model.id : "";
    }

    @action
    public setIsSelected(val: boolean) {
        this.model.isSelected = val;

        if (val === false) {
            for (const productVariant of this.productVariantViewModels) {
                productVariant.setIsSelected(false);
            }
        }
    }

    @action
    private createViewModels(productVariantId: string) {
        let isSelected: boolean = false;
        for (const productVariant of this.model.productVariants) {
            if (productVariant.id === productVariantId) {
                isSelected = true;
                this.productVariantViewModels.push(new ProductVariantViewModel(productVariant, isSelected));
            } else {
                this.productVariantViewModels.push(new ProductVariantViewModel(productVariant, isSelected));
            }
        }
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
