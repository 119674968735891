import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const ViewSnagStyle = makeStyles((theme: Theme) => ({
    root: {
        "& > div": {
            backgroundColor: "#F9F9F9",
        },
    },
    header: {},
    headerRowA: {
        backgroundColor: "#F9F9F9",
        borderBottom: "1px solid #CED4DA",
        display: "flex",
        justifyContent: "space-between",
        "& > div:first-child": {
            padding: "17px 9px 24px 9px",
        },
        "& > div:last-child": {
            paddingTop: "4px",
        },
    },
    headerRowB: {
        display: "flex",
        justifyContent: "space-between",
        padding: "17px 9px 0px 9px",
    },
    headerRowC: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 9px 0px 9px",
    },
    headerRowD: {
        display: "flex",
        flexDirection: "column",
        padding: "9px 9px 0px 9px",
    },
    resolvedSnagTitle: {
        color: "#3AAA35",
    },
    rejectedSnagTitle: {
        color: "#DC3545",
    },
    title: {
        padding: "10px 9px 0px 9px",
        "& > h5": {
            fontSize: "16px",
            fontWeight: "bold",
            textTransform: "uppercase",
        },
    },
    date: {
        padding: "0 10px 5px",
        "& > h5": {
            fontSize: "10px",
            fontWeight: "bold",
            textTransform: "uppercase",
        },
    },
    navSnag: {
        "& > button": {
            fontSize: "10px",
        },
    },
    navSnags: {},
    detailsTitle: {
        padding: "0 10px 10px 10px",
        "& > h5": {
            fontSize: "14px",
            fontWeight: "bold",
        },
    },
    details: {
        padding: "0 10px",
        "& > p": {
            fontSize: "12px",
            textAlign: "justify",
            // whiteSpace: "pre",
        },
    },
    rejectedReason: {
        padding: "10px 10px 6px",
        "& > p": {
            fontSize: "12px",
            whiteSpace: "pre-wrap",
            color: "#DC3545",
        },
    },
    completedBy: {
        padding: "0 10px 5px",
        "& > h5": {
            fontSize: "10px",
            whiteSpace: "pre-wrap",
        },
    },
    selectionRow: {},
    textAreaRow: {},
    buttonRow: {},
    formControl: {},
    formInputControl: {},
    formTextAreaControl: {},
    submitButton: {},
    editRoot: {
        marginLeft: "20px",
        marginRight: "20px",
        paddingBottom: "20px",
        "& div.MuiFormControl-root": {
            marginBottom: "16px",
            width: "100%",
        },
        "& div.MuiOutlinedInput-multiline": {
            padding: "8px 10px",
            "& textarea": {
                minHeight: "70px",
            },
        },
        "& button": {
            width: "100%",
        },
    },
    deleteBtn: {
        marginTop: "10px",
        backgroundColor: "#3C3C3B",
    },
    saveBtn: {
        marginTop: "24px",
    },
    paddedBox: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "17px",
        "& h5": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    dashedBorderBox: {
        borderTop: "1px dashed #ced4da",
        paddingTop: "13px",
    },
    linkTxt: {
        color: "#556ED1",
        textDecoration: "underline",
        "&:hover": {
            cursor: "pointer",
        },
    },
    [theme.breakpoints.up("md")]: {
        root: {
            "& > div": {
                background: "none",
            },
        },
        header: {},
        headerRowA: {
            background: "none",
            borderBottom: "none",
            padding: "17px 9px 0px 9px",
            "& > div": {
                padding: "6px 9px 0px 9px !important",
            },
        },
        headerRowB: {
            padding: "17px 9px 9px 9px",
        },
        headerRowC: {
            padding: "0px 9px 7px 9px",
        },
        headerRowD: {
            padding: "9px 9px 7px 9px",
        },
        title: {
            padding: "0px 9px 0px 9px",
        },
        dateContainer: {
            textAlign: "right",
        },
        date: {
            "& > h5": {
                fontSize: "20px",
            },
        },
        completedBy: {
            "& > h5": {
                fontSize: "12px",
            },
        },
        navSnag: {
            "& > button": {
                fontSize: "16px",
            },
        },
        navSnags: {},
        detailsTitle: {
            padding: "0 10px 8px 10px",
            "& > h5": {
                fontSize: "16px",
            },
        },
        selectionRow: {
            display: "flex",
            flex: "1",
            margin: "0 -15px",
            paddingBottom: "10px",
        },
        textAreaRow: {
            display: "flex",
            flex: "1",
            margin: "0 -15px",
            paddingBottom: "10px",
            "& > div": {
                flex: 1,
            },
        },
        buttonRow: {
            paddingTop: "8px",
            margin: "0 -15px",
        },
        formControl: {
            padding: "0 15px",
        },
        formInputControl: {
            minWidth: "285px !important",
        },
        formTextAreaControl: {
            minWidth: "100%",
        },
        deleteBtn: {
            margin: "0 15px",
            maxWidth: "255px",
        },
        saveBtn: {
            margin: "0 15px",
            maxWidth: "255px",
        },
        editRoot: {
            borderTop: "1px dashed #ced4da",
            padding: "30px 0",
            "& > form > div >  div.form-group > div": {
                backgroundColor: "#F9F9F9",
            },
        },
        rejectedReason: {
            padding: "0 10px",
            "& > p": {
                fontSize: "12px",
                whiteSpace: "pre-wrap",
                color: "#DC3545",
            },
        },
    },
}));
