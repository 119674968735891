import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information for the snags page.
 */
export const SnagsPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    introduction: {
        borderBottom: "1px solid #ced4da",
        padding: "18px 18px 15px 18px",
    },
    introductionTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "1.25",
    },
    snags: {},
    snag: {
        "& > button": {
            "& > div.MuiCardContent-root": {
                padding: "16px 18px",
            },
        },
        "&:not(:last-child)": {
            borderBottom: "1px dashed #ced4da",
        },
    },
    snagTitleRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "3px",
    },
    snagTitle: {
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    resolvedSnagTitle: {
        color: "#3AAA35",
    },
    rejectedSnagTitle: {
        color: "#DC3545",
    },
    snagContentRow: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
    },
    snagContentTitleRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "3px",
    },
    snagDetails: {
        boxOrient: "vertical",
        display: "-webkit-box",
        lineClamp: 2,
        marginRight: "18px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    snagArrow: {
        alignItems: "center",
        display: "flex",
        "& svg": {
            fill: "#3aaa35",
            marginLeft: "-6px",
            marginRight: "-6px",
        },
    },
    accordion: {
        margin: "0 !important",
        "& > div.MuiCollapse-container": {},
    },
    accordionSummary: {},
    [theme.breakpoints.up("md")]: {
        titleRow: {
            padding: "27px 18px 15px 18px",
            display: "flex",
            justifyContent: "space-between",
            flex: "0 0 auto",
            "& > button": {
                padding: "0",
            },
            "& > h5": {
                padding: "0px",
            },
            "& .MuiSvgIcon-root": {
                margin: "-7px",
                fontSize: "35px",
            },
        },
        title: {
            fontSize: "20px",
            fontWeight: "bold",
        },
        titleBorder: {
            borderBottom: "1px solid #CED4DA",
            margin: "0 18px 16px",
        },
        accordion: {
            "& > div.MuiCollapse-container": {
                background: "none",
                borderTop: "1px dashed #ced4da",
                "& div.MuiAccordionDetails-root": {
                    padding: "30px 0px",
                },
            },
        },
        accordionSummary: {
            margin: "0 18px",
            "& > div.MuiAccordionSummary-content": {
                marginLeft: "-18px",
                "& > p": {
                    fontSize: "14px",
                },
            },
            "& > div.MuiAccordionSummary-expandIcon": {
                marginRight: "-18px",
            },
        },
        introduction: {
            margin: "0 18px",
            padding: "18px 0 15px 0",
        },
        introductionTitle: {
            fontSize: "14px",
        },
        snag: {
            margin: "0 18px",
            boxShadow: "none",
            borderBottom: "1px solid #ced4da",
            borderRadius: "0",
            "& > button": {
                "& > div.MuiCardContent-root": {
                    padding: "16px 0",
                },
            },
        },
    },
}));
