// Libraries
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { StoresInstance } from "Core/Base";
import { CSSGrid, Cell } from "Core/Components/Styles";

// Custom
import { Header } from "./Header";

import { NavigationBar } from "Custom/Components/NavigationBar";
import { PageHeader } from "Custom/Components/PageHeader";

//EN: use empty generic type to get default react props
export const Layout: React.FC<{}> = props => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    return useObserver(() => {
        const canDisplayHeader = StoresInstance.domain.AccountStore.IsLoggedIn;
        const areas = canDisplayHeader ? ["header", "content"] : ["content"];

        const renderUserRow = () => (isDesktop ? null : <PageHeader />);

        return (
            <CSSGrid areas={areas} columns={"1fr"} gap={"0px"} rows={"42px"}>
                {canDisplayHeader && (
                    <Cell area={"header"} style={{ maxHeight: "42px" }}>
                        <Header />
                    </Cell>
                )}
                <Cell area={"content"} id={"content"}>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        {canDisplayHeader && renderUserRow()}
                        {props.children}
                    </div>
                </Cell>
            </CSSGrid>
        );
    });
};
