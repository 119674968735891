// Libraries
import { action, computed, observable } from "mobx";
import moment, { Moment } from "moment";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { isNullOrUndefined } from "util";
import { AccountStore } from "../../Stores/Domain/AccountStores";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { PropertyModel, PropertyModelDTO } from "Custom/Models/DashboardModels/PropertyModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "Custom/Stores/Stores";
import { GlobalSetting } from "Custom/Models";

export class PropertyViewModel extends ViewModelBase<PropertyModel> {
    constructor(propertyId: string) {
        super(new PropertyModel());
        this.setDecorators(PropertyModel);

        //console.log("PROPERTYVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId);
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    // #endregion Properties

    // #region Computeds

    @computed
    public get Property() {
        return this.model;
    }

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    @computed
    public get PropertyAddress() {
        let address = [this.model.addressLine1, this.model.addressLine2, this.model.postcode].filter(i => !isEmptyOrWhitespace(i)).join(", ");

        return isEmptyOrWhitespace(address) ? "No address available for this plot" : address;
    }

    @computed
    public get showStripePaymentRequest() {
        const setting = StoresInstance.domain.AccountStore.getSettingByType(22);

        if (setting) {
            if (setting.settingValue === "0") {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    @computed
    public get currentUserId() {
        return StoresInstance.domain.AccountStore.Id;
    }

    @computed
    public get hasStripePaymentRequest() {
        const hasPendingPaymentRequests = this.model.stripePaymentRequests.filter(p => p.hasPaid === false && p.propertyId === this.model.id);

        if (hasPendingPaymentRequests) {
            return hasPendingPaymentRequests.length > 0;
        }
        return false;
    }

    @computed
    public get hasPaidStripeReservationPaymentRequest() {
        const hasPaidPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === true && p.propertyId === this.model.id && p.type === 1);

        if (hasPaidPendingPaymentRequest) {
            return true;
        }
        return false;
    }

    @computed
    public get PaidReservationDate() {
        const hasPaidPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === true && p.propertyId === this.model.id && p.type === 1);

        if (hasPaidPendingPaymentRequest) {
            let paidDate = moment(hasPaidPendingPaymentRequest.paidDate);
            return paidDate.format("DD/MM/YYYY");
        }

        return "";
    }

    @computed
    public get PaidOrderDate() {
        const hasPaidPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === true && p.propertyId === this.model.id && p.type === 2);

        if (hasPaidPendingPaymentRequest) {
            let paidDate = moment(hasPaidPendingPaymentRequest.paidDate);
            return paidDate.format("DD/MM/YYYY");
        }

        return "";
    }

    @computed
    public get hasPaidStripeOrderPaymentRequest() {
        const hasPaidPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === true && p.propertyId === this.model.id && p.type === 2);

        if (hasPaidPendingPaymentRequest) {
            return true;
        }
        return false;
    }

    @computed
    public get getReservationStripePaymentRequest() {
        const hasPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.propertyId === this.model.id && p.type === 1);

        if (hasPendingPaymentRequest) {
            return `£${hasPendingPaymentRequest.payment.toLocaleString("en-GB", { minimumFractionDigits: 2 })}`;
        }
        return "";
    }

    @computed
    public get getPropertyHouseTypeGeneralImages() {
        const images = this.model.propertyHouseTypeImages.filter(image => image.imageTypeName === "General");

        let i = 1;
        let imageLength = images.sort((a, b) => b.ordinal - a.ordinal)[0];
        let HouseTypeDevelopmentImage = [
            {
                imageUrl: "",
                caption: "",
                thumbnailImageUrl: "",
            },
        ];

        if (imageLength && imageLength.ordinal) {
            while (i <= imageLength.ordinal) {
                if (images && images.length > 0) {
                    const image = this.getHouseTypeImageWithOrdinal(i, "General");
                    if (image && image.imageUrl.length > 0) {
                        HouseTypeDevelopmentImage.push({ ...image, imageUrl: image.imageUrl, caption: image.caption, thumbnailImageUrl: image.thumbnailImageUrl });
                    }
                    const propertyImage = this.getPropertyImage("General", i);

                    if (propertyImage && propertyImage.imageUrl) {
                        HouseTypeDevelopmentImage.push({
                            imageUrl: propertyImage.imageUrl,
                            caption: propertyImage.caption,
                            thumbnailImageUrl: propertyImage.thumbnailImageUrl,
                        });
                    }
                }
                i++;
            }
        }

        return HouseTypeDevelopmentImage;
    }

    @computed
    public get getPropertyHouseTypeFloorplanImages() {
        const images = this.model.propertyHouseTypeImages.filter(image => image.imageTypeName === "Floorplan");

        let i = 1;
        let imageLength = images.sort((a, b) => b.ordinal - a.ordinal)[0];
        let HouseTypeDevelopmentImage = [
            {
                imageUrl: "",
                caption: "",
                thumbnailImageUrl: "",
            },
        ];

        if (imageLength && imageLength.ordinal) {
            while (i <= imageLength.ordinal) {
                if (images && images.length > 0) {
                    const image = this.getHouseTypeImageWithOrdinal(i, "Floorplan");
                    if (image && image.imageUrl.length > 0) {
                        HouseTypeDevelopmentImage.push({ ...image, imageUrl: image.imageUrl, caption: image.caption, thumbnailImageUrl: image.thumbnailImageUrl });
                    }

                    const propertyImage = this.getPropertyImage("Floorplan", i);

                    if (propertyImage && propertyImage.imageUrl) {
                        HouseTypeDevelopmentImage.push({
                            imageUrl: propertyImage.imageUrl,
                            caption: propertyImage.caption,
                            thumbnailImageUrl: propertyImage.thumbnailImageUrl,
                        });
                    }
                }
                i++;
            }
        }

        return HouseTypeDevelopmentImage;
    }

    @action
    private getHouseTypeImageWithOrdinal(ordinal: number, imageType: string) {
        const { propertyHouseTypeImages, houseTypeId, developmentId } = this.model;
        let imageUrl = "";
        let caption = "";
        let thumbnailImageUrl = "";

        if (propertyHouseTypeImages && propertyHouseTypeImages.length > 0) {
            const developmentImages = propertyHouseTypeImages.filter(
                htdi =>
                    htdi.houseTypeId === houseTypeId &&
                    htdi.developmentId === developmentId &&
                    htdi.ordinal === ordinal &&
                    htdi.imageTypeName === imageType &&
                    htdi.imageUrl.length > 0,
            );
            if (developmentImages && developmentImages.length > 0) {
                imageUrl = developmentImages[0].imageUrl;
                caption = developmentImages[0].caption;
                thumbnailImageUrl = developmentImages[0].thumbnailImageUrl;
                if (imageUrl) return { imageUrl, caption, thumbnailImageUrl };
            }
            const houseTypeWithAllImage: { imageUrl: string; caption: string; thumbnailImageUrl: string } = this.gethouseTypeAllImages(ordinal, imageType);
            imageUrl = houseTypeWithAllImage.imageUrl;
            caption = houseTypeWithAllImage.caption;
            thumbnailImageUrl = houseTypeWithAllImage.thumbnailImageUrl;
        }

        return { imageUrl, caption, thumbnailImageUrl };
    }

    @action
    private gethouseTypeAllImages(ordinal: number, imageType: string) {
        const { propertyHouseTypeImages, houseTypeId } = this.model;
        let imageUrl = "",
            caption = "",
            thumbnailImageUrl = "";
        if (propertyHouseTypeImages && propertyHouseTypeImages.length > 0) {
            const housetypeImages = propertyHouseTypeImages.filter(
                htdi => htdi.houseTypeId === houseTypeId && htdi.developmentId === null && htdi.ordinal === ordinal && htdi.imageTypeName === imageType,
            );
            if (housetypeImages && housetypeImages.length > 0) {
                imageUrl = housetypeImages[0].imageUrl;
                caption = housetypeImages[0].caption;
                thumbnailImageUrl = housetypeImages[0].thumbnailImageUrl;
            }
        }
        return { imageUrl, caption, thumbnailImageUrl };
    }

    @action
    private getPropertyImage(imageType: string, ordinal: number) {
        const { propertyHouseTypeImages } = this.model;
        let imageUrl = "",
            caption = "",
            thumbnailImageUrl = "";

        if (propertyHouseTypeImages && propertyHouseTypeImages.length > 0) {
            const propertyImages = propertyHouseTypeImages
                .filter(htdi => isNullOrUndefined(htdi.houseTypeId) && htdi.imageTypeName == imageType && htdi.ordinal == ordinal && htdi.imageUrl.length > 0)
                .sort((a, b) => a.ordinal - b.ordinal);
            if (propertyImages && propertyImages.length > 0) {
                imageUrl = propertyImages[0].imageUrl;
                caption = propertyImages[0].caption;
                thumbnailImageUrl = propertyImages[0].thumbnailImageUrl;

                if (imageUrl) return { imageUrl, caption, thumbnailImageUrl };
            }
        }

        return { imageUrl, caption, thumbnailImageUrl };
    }

    @computed
    public get getReservationStripePaymentRequestId() {
        const hasPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === false && p.propertyId === this.model.id && p.type === 1);

        if (hasPendingPaymentRequest) {
            return hasPendingPaymentRequest.id;
        }
        return "";
    }

    @computed
    public get getChoicesStripePaymentRequestId() {
        const hasPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === false && p.propertyId === this.model.id && p.type === 2);

        if (hasPendingPaymentRequest) {
            return hasPendingPaymentRequest.id;
        }
        return "";
    }

    @computed
    public get getChoicesStripePaymentRequest() {
        const hasPendingPaymentRequest = this.model.stripePaymentRequests.find(p => p.propertyId === this.model.id && p.type === 2);

        if (hasPendingPaymentRequest) {
            return `£${hasPendingPaymentRequest.payment.toLocaleString("en-GB", { minimumFractionDigits: 2 })}`;
        }
        return "";
    }

    @computed
    public get getPaymentPrice() {
        const hasPendingReservationPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === false && p.propertyId === this.model.id && p.type === 1);
        const hasPendingChoicesPaymentRequest = this.model.stripePaymentRequests.find(p => p.hasPaid === false && p.propertyId === this.model.id && p.type === 2);
        let totalPrice: number = 0;
        if (hasPendingReservationPaymentRequest) {
            totalPrice += hasPendingReservationPaymentRequest.payment;
        }
        if (hasPendingChoicesPaymentRequest) {
            totalPrice += hasPendingChoicesPaymentRequest.payment;
        }
        return totalPrice;
    }

    @computed
    public get PropertyOverview() {
        return `${this.model.houseTypeName}, ${this.propertyTypeName} with ${this.numberOfBeds} bedrooms`;
    }

    @computed
    public get PropertyTitle() {
        return `Plot ${this.model.plotNumber} ${this.model.developmentName}`;
    }

    @computed
    public get CanDisplaySalesAgent() {
        return !isEmptyOrWhitespace(this.model.salesAgentName);
    }

    @computed
    public get CanDisplayAgreedPurchasePrice() {
        return this.model.agreedPurchasePrice > 0.0;
    }

    @computed
    public get AgreedPurchasePrice() {
        return `£${this.model.agreedPurchasePrice.toLocaleString("en-GB", { minimumFractionDigits: 2 })}`;
    }

    @computed
    public get CanDisplayReservationFeePaid() {
        return this.model.reservationFeePaid > 0.0;
    }

    @computed
    public get ReservationFeePaid() {
        return `£${this.model.reservationFeePaid.toLocaleString("en-GB", { minimumFractionDigits: 2 })}`;
    }

    // Build dates.
    @computed
    public get propertyTypeName() {
        let propertyTypeName = "";

        if (this.model.plotTypeName != "" && this.model.plotTypeName != null) {
            propertyTypeName = this.model.plotTypeName;
        } else {
            propertyTypeName = this.model.propertyTypeName;
        }

        return propertyTypeName;
    }

    @computed
    public get Description() {
        let description = "";

        if (this.model.plotDescription != "" && this.model.plotDescription != null) {
            description = this.model.plotDescription;
        } else {
            description = this.model.houseTypeDescription;
        }

        return description;
    }

    @computed
    public get numberOfBeds() {
        let numberOfBeds = 0;

        if (this.model.propertyNumberOfBedrooms != 0 && this.model.propertyNumberOfBedrooms != null) {
            numberOfBeds = this.model.propertyNumberOfBedrooms;
        } else {
            numberOfBeds = this.model.numberOfBedrooms;
        }

        return numberOfBeds;
    }

    @computed
    public get BuildActualDate() {
        return isNullOrUndefined(this.model.buildActualDate) ? "-" : this.model.buildActualDate.format("DD/MM/YYYY");
    }

    @computed
    public get showDatePicker() {
        const setting: GlobalSetting | undefined = StoresInstance.domain.AccountStore.getSettingByType(14);

        if (setting) {
            if (setting.settingValue === "0") {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }
    @computed
    public get BuildTargetDate() {
        let retVal: string = "";
        if (this.showDatePicker) {
            retVal = isNullOrUndefined(this.model.buildTargetDate) ? "-" : this.model.buildTargetDate.format("DD/MM/YYYY");
        } else {
            const month = this.model.buildTargetDateMonth ? this.model.buildTargetDateMonth : "";
            const year = this.model.buildTargetDateYear ? this.model.buildTargetDateYear : "";
            retVal = `${month} ${year}`;
        }
        return retVal;
    }

    // Choice dates
    @computed
    public get ChoicesActualDate() {
        if (this.model.hasOrder) {
            return isNullOrUndefined(this.model.orderCompletedDate) ? "-" : this.model.orderCompletedDate.format("DD/MM/YYYY");
        } else {
            return isNullOrUndefined(this.model.tempChoicesActualDate) ? "-" : this.model.tempChoicesActualDate.format("DD/MM/YYYY");
        }
    }

    @computed
    public get ChoicesTargetDate() {
        if (!this.model.hasReservationType1) {
            return isNullOrUndefined(this.model.tempChoicesTargetDate) ? "-" : this.model.tempChoicesTargetDate.format("DD/MM/YYYY");
        } else {
            return isNullOrUndefined(this.model.choicesTargetDate)
                ? isNullOrUndefined(this.model.tempChoicesTargetDate)
                    ? "-"
                    : this.model.tempChoicesTargetDate.format("DD/MM/YYYY")
                : this.model.choicesTargetDate.format("DD/MM/YYYY");
        }
    }

    // Contract dates
    @computed
    public get ContractActualDate() {
        return isNullOrUndefined(this.model.contractActualDate) ? "-" : this.model.contractActualDate.format("DD/MM/YYYY");
    }

    @computed
    public get ContractTargetDate() {
        return isNullOrUndefined(this.model.contractTargetDate) ? "-" : this.model.contractTargetDate.format("DD/MM/YYYY");
    }

    // Legal dates
    @computed
    public get LegalActualDate() {
        return isNullOrUndefined(this.model.legalActualDate) ? "-" : this.model.legalActualDate.format("DD/MM/YYYY");
    }

    @computed
    public get LegalTargetDate() {
        return isEmptyOrWhitespace(this.model.legalTargetMonth) || isEmptyOrWhitespace(this.model.legalTargetYear)
            ? "-"
            : `${this.model.legalTargetMonth} ${this.model.legalTargetYear}`;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    @action
    public setPaymentComplete() {
        const hasPaidPendingPaymentRequests = this.model.stripePaymentRequests.filter(p => p.hasPaid === false && p.propertyId === this.model.id);
        if (hasPaidPendingPaymentRequests) {
            hasPaidPendingPaymentRequests.forEach(hasPaidPendingPaymentRequest => {
                hasPaidPendingPaymentRequest.hasPaid = true;
            });
        }
    }

    public navigateToDashboard = () => {
        this.history.push(`/${this.propertyId}`);
    };

    private load = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<PropertyModelDTO>(Server.Api.Dashboard.GetProperty + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
