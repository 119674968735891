// Libraries
import { action, computed, observable } from "mobx";

// Core
import { StoresInstance } from "Core/Base";
import { LoginComplete } from "Core/Models";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { LoginModel } from "../Models";
import { Client, Server } from "Custom/Globals/AppUrls";

export class LoginViewModel extends ViewModelBase<LoginModel> {
    constructor() {
        super(new LoginModel());
        this.setDecorators(LoginModel);
    }

    // #region Server Properties and Actions

    @observable
    public haveSubmittedToServer = false;

    @action
    public setHaveSubmittedToServer = (value: boolean) => {
        this.haveSubmittedToServer = value;
    };

    @observable
    public serverValidationMessage = "";

    @action
    public setServerValidationMessage = (value: string) => {
        this.serverValidationMessage = value;
    };

    @action
    public login = async (): Promise<void> => {
        try {
            if (this.isModelValid()) {
                this.setServerValidationMessage("");
                this.setHaveSubmittedToServer(true);

                const apiResult = await this.Post<LoginComplete>(Server.Api.Account.Login, this.getModel);

                if (apiResult.wasSuccessful) {
                    StoresInstance.domain.AccountStore.setLoginState(apiResult.payload);

                    const { from } = (this.history.location.state as any) || { from: { pathname: `/${apiResult.payload.propertyId}` } };

                    if (from) {
                        this.history.replace(from.pathname);
                    }
                } else {
                    if (apiResult.errors.length > 0) {
                        const error = apiResult.errors[0];

                        if (!isEmptyOrWhitespace(error.message)) {
                            this.setServerValidationMessage(error.message);
                        } else {
                            this.setServerValidationMessage("There was an unknown error trying to log in.");
                        }
                    }
                }
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error trying to log in.");
        } finally {
            // Finally
        }
    };

    public tryAgain = () => {
        this.setHaveSubmittedToServer(false);
        this.setServerValidationMessage("");
    };

    // #endregion Server Properties and Actions

    // #region Can Display Properties

    @computed
    public get canDisplayBusy(): boolean {
        return this.IsLoading;
    }

    @computed
    public get canDisplayError(): boolean {
        return this.haveSubmittedToServer && !this.IsLoading && !isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplayForm(): boolean {
        return !this.haveSubmittedToServer && !this.IsLoading;
    }

    // #endregion Can Display Properties

    // #region Navigation Actions

    public navigateToForgotPassword = () => {
        this.model.emailAddress
            ? this.history.push(Client.Path.Account.ForgotPasswordEmail.replace(":emailAddress", this.model.emailAddress))
            : this.history.push(Client.Path.Account.ForgotPassword);
    };

    // #endregion Navigation Actions

    @computed
    public get isEmailAddressValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue<string>("emailAddress"))) return "Email address is required";
        if (this.getValue<string>("emailAddress").length < 4) return "Email address must be greater than 4 characters";

        return retval;
    }

    @computed
    public get isPasswordValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue("password") as string)) return "Password is required";
        if (this.getValue<string>("password").length < 4) return "Password must be greater than 4 characters";

        return retval;
    }

    // #region Bolierplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: "emailAddress" | "password", value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #endregion Boilerplate
}
