// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { ChoiceModelDTO, ChoicesAndRelatedModelDTO } from "Custom/Models/DashboardModels/ChoiceModel";
import { ChoicesModel } from "Custom/Models/DashboardModels/ChoicesModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "../../Stores";

export class ChoicesViewModel extends ViewModelBase<ChoicesModel> {
    constructor(propertyId: string) {
        super(new ChoicesModel());
        this.setDecorators(ChoicesModel);

        //console.log("CHOICESSVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            // this.load(propertyId);
            this.loadChoicesAndRelated(propertyId);
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    @observable
    private isChoicesLockedValue: boolean = false;

    @computed
    public get isChoicesLocked() {
        return this.isChoicesLockedValue;
    }

    // #endregion Properties

    // #region Computeds

    @computed
    public get ChoicesAreas() {
        return this.model.choicesAreas;
    }

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    public navigateToChoicesArea = (propertyId: string, areaId: string) => {
        this.history.push(`/choicesarea/${propertyId}/${areaId}`);
    };

    public navigateToDashboard = () => {
        this.history.push(`/${this.propertyId}`);
    };

    private load = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<ChoiceModelDTO[]>(Server.Api.Dashboard.GetChoices + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    private loadChoicesAndRelated = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<ChoicesAndRelatedModelDTO>(Server.Api.Dashboard.GetChoicesAndRelated + "/" + id);

            if (apiResult.wasSuccessful) {
                const choicesAndRelated: ChoicesAndRelatedModelDTO = apiResult.payload;

                this.isChoicesLockedValue = choicesAndRelated.isChoicesLocked;
                this.model.fromDto(choicesAndRelated.choices);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
