// Libraries
import { action, computed, observable, runInAction } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { ProductVariantModel, ProductVariantModelDTO, ProductVariantToOrderDTO } from "./ProductVariantModel";
import { SpecificationHouseTypeProductModel, SpecificationHouseTypeProductModelDTO } from "./SpecificationHouseTypeProductModel";
import { SpecificationProductModel, SpecificationProductModelDTO } from "./SpecificationProductModel";
import { Format } from "Custom/Utils";
import { isNaN, multiply } from "lodash-es";
import { GlobalSettingType } from "../Enums/GlobalSettingType";
import { ProductSummaryModel, ProductSummaryModelDTO } from "./ProductSummary";

export class ProductModel extends ModelBase<ProductModel, ProductAndRelatedModelDTO> {
    // #region Properties

    @observable
    public id: string = "";

    @observable
    public productId: string = "";

    @observable
    public productGroupId: string = "";

    @observable
    public orderAreaProductGroupProductId: string = "";

    @observable
    public productCode: string = "";

    @observable
    public productName: string = "";

    @observable
    public productDescription: string = "";

    @observable
    public productThumbnailImageUrl: string = "";

    @observable
    public productOriginalImageUrl: string = "";

    @observable
    public productPrice: string = "";

    @observable
    public houseTypeProductPrice: string = "";

    @observable
    public orderPrice: string = "";

    @observable
    public isIncluded: boolean = false;

    @observable
    public isUpgrade: boolean = false;

    @observable
    public isOptional: boolean = false;

    @observable
    public productPOA: boolean = false;

    @observable
    public orderPOA: boolean = false;

    @observable
    public productVariantId: string = "";

    @observable
    public orderId: string = "";

    @observable
    public specificationId: string = "";

    @observable
    public houseTypeId: string = "";

    @observable
    public areaId: string = "";

    @observable
    public isOverride: boolean = false;

    @observable
    public productGropuId: string = "";

    @observable
    public highestIncludedProductCost: string = "";

    @observable
    public productVariants = observable<ProductVariantModel>([]);

    @observable
    public products = observable<ProductModelDTO>([]);

    @observable
    public specificationProducts = observable<SpecificationProductModel>([]);

    @observable
    public specificationHouseTypeProducts = observable<SpecificationHouseTypeProductModel>([]);

    @observable
    public productsSummary = observable<ProductSummaryModel>([]);

    @observable
    public isSelected: boolean = false;

    @observable
    public orderQuantity: number = 0;

    @observable
    public isQuantityAllowed: boolean = false;

    @observable
    public isMailSent?: boolean = false;

    @action
    private setNewOrderQuantity = (value: any) => {
        this.orderQuantity = value;
    };

    // #endregion Properties
    public onQuantityChange = (event: any) => {
        if (event.target.value) {
            this.setNewOrderQuantity(event.target.value);
        }
    };

    private specificationProductHasPrice = () => {
        if (this.isOverride) {
            if (this.houseTypeProductPrice !== undefined && this.houseTypeProductPrice !== null && this.houseTypeProductPrice !== "") {
                return true;
            }
        } else {
            if (this.houseTypeProductPrice !== undefined && this.houseTypeProductPrice !== null && this.houseTypeProductPrice !== "" && this.houseTypeProductPrice !== "0.00") {
                return true;
            }
        }

        return false;
    };

    @computed
    private get showChoicesSum() {
        //TODO implement
        return true;
        // const setting = this.props.stores!.domain.globalSetting.getSettingByType(GlobalSettingType.ShowChoicesSum);

        // if (setting) {
        //     if (setting.settingValue === "0") {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }

        // return true;
    }

    @computed
    public get getProductPrice() {
        if (this.orderPrice !== null && this.orderPrice !== undefined && this.orderPrice !== "") {
            return this.orderPrice;
        }

        if (this.highestIncludedProductCost !== null && this.highestIncludedProductCost !== undefined && this.highestIncludedProductCost !== "") {
            return this.highestIncludedProductCost;
        }

        if (this.houseTypeProductPrice !== null && this.houseTypeProductPrice !== undefined && this.houseTypeProductPrice !== "") {
            return this.houseTypeProductPrice;
        }

        return this.productPrice;
    }

    @computed
    public get extraPrice() {
        let retVal = "";
        if (this.isOptional) {
            if (!this.isOverride) {
                if (this.productPOA) {
                    retVal = "£POA";
                } else {
                    retVal = `£${Format.currencyFormat(this.productPrice, true)}`;
                }
            } else {
                const specificationHouseTypeProduct = this.specificationHouseTypeProducts.find(
                    p =>
                        p.specificationId === this.specificationId &&
                        p.houseTypeId === this.houseTypeId &&
                        p.areaId === this.areaId &&
                        p.productGroupId === this.productGroupId &&
                        p.productId === this.productId,
                );

                if (specificationHouseTypeProduct) {
                    if (specificationHouseTypeProduct.defaultOverridePrice.toUpperCase() === "POA") {
                        retVal = "£POA";
                    } else {
                        retVal = `£${Format.currencyFormat(specificationHouseTypeProduct.defaultOverridePrice, true)}`;
                    }
                } else {
                    if (this.houseTypeProductPrice !== undefined && this.houseTypeProductPrice !== null && this.houseTypeProductPrice !== "") {
                        if (this.houseTypeProductPrice.toUpperCase() === "POA") {
                            retVal = "£POA";
                        } else {
                            retVal = `£${Format.currencyFormat(this.houseTypeProductPrice, true)}`;
                        }
                    }
                }
            }
        }
        return retVal;
    }

    @action
    public getPriceOnAddQuantity = () => {
        if (this.orderPrice !== null && this.orderPrice !== undefined) {
            if (this.isIncluded) {
                return "INCLUDED";
            }
            if (this.productPOA) {
                return "£POA";
            }

            let productCost = this.orderPrice;
            if (this.orderQuantity) {
                productCost = multiply(parseInt(this.orderPrice), this.orderQuantity).toString();
            }
            return `£${Format.currencyFormat(productCost, true)}`;
        }
        return "";
    };

    @computed
    public get price() {
        //SW rules for checking price
        //1) Order table price first check - OrderAreaProductGroupProduct
        //2) Then House type override second check - SpecificationHouseTypeProduct
        //3) Then Default override third check - SpecificationProduct
        //4) Then retail price as the final check - Product

        let displayCost: string = "";

        //1) Order table price first check
        if (this.orderPrice !== null && this.orderPrice !== undefined && this.orderPrice !== "") {
            if (this.isIncluded) {
                return "INCLUDED";
            }
            if (this.productPOA) {
                return "£POA";
            }

            displayCost = this.orderPrice;
        }

        //2) Then House type override second check
        const specificationHouseTypeProduct = this.specificationHouseTypeProducts.find(
            p =>
                p.specificationId === this.specificationId &&
                p.houseTypeId === this.houseTypeId &&
                p.areaId === this.areaId &&
                p.productGroupId === this.productGroupId &&
                p.productId === this.productId,
        );

        if (specificationHouseTypeProduct) {
            displayCost = specificationHouseTypeProduct.defaultOverridePrice;
        }

        //3) Then Default override third check
        if (this.isIncluded) {
            return "included";
        }

        if (!this.isOverride) {
            displayCost = this.productPrice;
        }

        //4) Then retail price as the final check
        if (this.isIncluded) {
            return "included";
        }

        // Handle non upgrade
        if (this.specificationProductHasPrice() && !this.isUpgrade) {
            if (this.productPrice.toUpperCase() === "POA") {
                return "£POA";
            } else {
                displayCost = this.houseTypeProductPrice;
            }
        }

        if (this.productPOA) {
            return "£POA";
        }

        if (!this.showChoicesSum) {
            return `£${Format.currencyFormat(displayCost, true)}`;
        }

        // Handle upgrade
        if (this.isUpgrade && this.showChoicesSum) {
            // SW the value is always the value minus the highest 'Included' product cost
            // within the product group
            let highestCost: number = 0;
            const productCosts: number[] = [];
            const includedProducts = this.specificationProducts.filter(
                p => p.specificationId === this.specificationId && p.areaId === this.areaId && p.productGroupId === this.productGroupId && p.isIncluded === true,
            );

            console.log("product: " + this.productName + " this.specificationProducts: " + this.specificationProducts);

            // Get a list of the prices for all included products in this group
            includedProducts.forEach(specificationProductIncluded => {
                const productWithCost = this.products.find(p => p.productId === specificationProductIncluded.productId);

                // Get the SpecificationHouseTypeProduct for the current included product in the loop.
                const specificationHouseTypeProductIncluded = this.specificationHouseTypeProducts.find(
                    p =>
                        p.specificationId === specificationProductIncluded.specificationId &&
                        p.houseTypeId === this.houseTypeId &&
                        p.areaId === specificationProductIncluded.areaId &&
                        p.productGroupId === specificationProductIncluded.productGroupId &&
                        p.productId === specificationProductIncluded.productId &&
                        specificationProductIncluded.productId !== "",
                );

                // Use HTP price if it exists, else use the specification product, otherwise use the product price.
                // TODO: Refactor this to reduce code duplication like the code below this foreach loop.
                if (
                    specificationHouseTypeProductIncluded &&
                    specificationProductIncluded.isOverride &&
                    specificationHouseTypeProductIncluded.defaultOverridePrice !== undefined &&
                    specificationHouseTypeProductIncluded.defaultOverridePrice !== null &&
                    specificationHouseTypeProductIncluded.defaultOverridePrice !== ""
                ) {
                    var unformattedHTPPrice: number = Number(Format.currencyFormatForUpsert(specificationHouseTypeProductIncluded.defaultOverridePrice, true));
                    productCosts.push(unformattedHTPPrice);
                } else if (this.specificationProductHasPrice() && specificationProductIncluded.price.toUpperCase() !== "POA" && specificationProductIncluded.isOverride) {
                    var unformattedHTPPrice: number = Number(Format.currencyFormatForUpsert(specificationProductIncluded.price, true));
                    productCosts.push(unformattedHTPPrice);
                } else if (productWithCost) {
                    if (productWithCost.productPrice !== "0.00" && productWithCost.productPrice !== undefined) {
                        var unformattedProductPrice: number = Number(Format.currencyFormatForUpsert(productWithCost.productPrice, true));

                        productCosts.push(unformattedProductPrice);
                    }
                }
            });

            var processedPrice: number = 0;

            // Calculate the cost of the current product based on the highest price of the included products.
            // Use HTP price if it exists, else use the specification product, otherwise use the product price.
            if (
                specificationHouseTypeProduct &&
                this.isOverride &&
                specificationHouseTypeProduct.defaultOverridePrice !== undefined &&
                specificationHouseTypeProduct.defaultOverridePrice !== null &&
                specificationHouseTypeProduct.defaultOverridePrice !== ""
            ) {
                processedPrice = Number(Format.currencyFormatForUpsert(specificationHouseTypeProduct.defaultOverridePrice, true));
            } else if (this.specificationProductHasPrice() && this.houseTypeProductPrice.toUpperCase() !== "POA" && this.isOverride) {
                processedPrice = Number(Format.currencyFormatForUpsert(this.houseTypeProductPrice, true));
            } else {
                processedPrice = Number(Format.currencyFormatForUpsert(this.productPrice, true));
            }

            if (productCosts.length > 0) {
                highestCost = Math.max.apply(Math, productCosts);
                displayCost = (processedPrice - highestCost).toFixed(2);
            } else {
                displayCost = processedPrice.toFixed(2);
            }

            return this.productPOA ? "£POA" : `£${Format.currencyFormat(displayCost, true)}`;
        }

        // let retVal = "";

        // if (this.isUpgrade) {
        //     if (this.highestIncludedProductCost) {
        //         retVal = `£${Format.currencyFormat(this.highestIncludedProductCost, true)}`;
        //     } else {
        //         retVal = `£${Format.currencyFormat(this.getProductPrice, true)}`;
        //     }
        // }

        // if (this.houseTypeProductPrice !== undefined && this.houseTypeProductPrice !== null && this.houseTypeProductPrice !== "") {
        //     if (this.houseTypeProductPrice.toUpperCase() === "POA") {
        //         retVal = "£POA";
        //     } else {
        //         retVal = `£${Format.currencyFormat(this.houseTypeProductPrice, true)}`;
        //     }
        // }

        // if (this.isOptional) {
        //     retVal = `£${Format.currencyFormat(this.getProductPrice, true)}`;
        // }

        // return retVal;
    }

    @action
    public fromDto = (dto: ProductAndRelatedModelDTO): void => {
        this.id = dto.product.id;
        this.productId = dto.product.productId;
        this.productGroupId = dto.product.productGroupId;
        this.orderAreaProductGroupProductId = dto.product.orderAreaProductGroupProductId;
        this.productCode = dto.product.productCode;
        this.productName = dto.product.productName;
        this.productDescription = dto.product.productDescription;
        this.productThumbnailImageUrl = dto.product.productThumbnailImageUrl;
        this.productOriginalImageUrl = dto.product.productOriginalImageUrl;
        this.productPrice = dto.product.productPrice;
        this.houseTypeProductPrice = dto.product.houseTypeProductPrice;
        this.orderPrice = dto.product.orderPrice;
        this.isIncluded = dto.product.isIncluded;
        this.isUpgrade = dto.product.isUpgrade;
        this.isOptional = dto.product.isOptional;
        this.productPOA = dto.product.productPOA;
        this.orderPOA = dto.product.orderPOA;
        this.productVariantId = dto.product.productVariantId;
        this.orderId = dto.product.orderId;
        this.specificationId = dto.product.specificationId;
        this.houseTypeId = dto.product.houseTypeId;
        this.areaId = dto.product.areaId;
        this.isOverride = dto.product.isOverride;
        this.highestIncludedProductCost = dto.product.highestIncludedProductCost;
        this.orderQuantity = dto.product.orderQuantity;
        this.isQuantityAllowed = dto.product.isQuantityAllowed;

        for (const productVariantDto of dto.productVariants) {
            const productVariant = new ProductVariantModel();

            productVariant.fromDto(productVariantDto);

            this.productVariants.push(productVariant);
        }

        for (const specificationHouseTypeProductDto of dto.specificationHouseTypeProducts) {
            const specificationHouseTypeProduct = new SpecificationHouseTypeProductModel();

            specificationHouseTypeProduct.fromDto(specificationHouseTypeProductDto);

            this.specificationHouseTypeProducts.push(specificationHouseTypeProduct);
        }

        for (const specificationProductDto of dto.specificationProducts) {
            const specificationProduct = new SpecificationProductModel();

            specificationProduct.fromDto(specificationProductDto);

            this.specificationProducts.push(specificationProduct);
        }

        for (const productsSummaryDto of dto.productsSummary) {
            const productsSummary = new ProductSummaryModel();

            productsSummary.fromDto(productsSummaryDto);

            this.productsSummary.push(productsSummary);
        }

        for (const product of dto.products) {
            this.products.push(product);
        }
    };

    public toDto = (): OrderAreaProductGroupProductModelDTO => {
        let cost = this.price !== undefined ? this.price : "";

        const productCost = Number.isNaN(parseFloat(cost.replace("£", "").replace(",", ""))) ? 0 : parseFloat(cost.replace("£", "").replace(",", ""));

        let orderAreaProductGroupProduct: OrderAreaProductGroupProductModelDTO = {
            id: this.orderAreaProductGroupProductId,
            orderId: this.orderId,
            orderAreaId: "",
            orderAreaProductGroupId: this.productGroupId,
            productId: this.productId,
            cost: productCost.toString(),
            isIncluded: this.isIncluded,
            isUpgrade: this.isUpgrade,
            isOptional: this.isOptional,
            isBespoke: false,
            notes: "",
            bespokeName: "",
            bespokeNotes: "",
            isPOA: this.orderId !== null && this.orderId !== "" ? this.orderPOA : this.productPOA,
            productVariantId: this.productVariants.length > 0 ? this.productVariants.find(pv => pv.isSelected === true)!.id : "",
            quantity: this.orderQuantity,
            isMailSent: this.isMailSent,
        };

        return orderAreaProductGroupProduct;
    };
}

export interface ProductModelDTO {
    id: string;
    productId: string;
    productGroupId: string;
    orderAreaProductGroupProductId: string;
    productCode: string;
    productName: string;
    productDescription: string;
    productThumbnailImageUrl: string;
    productOriginalImageUrl: string;
    productPrice: string;
    houseTypeProductPrice: string;
    orderPrice: string;
    isIncluded: boolean;
    isUpgrade: boolean;
    isOptional: boolean;
    productPOA: boolean;
    orderPOA: boolean;
    productVariantId: string;
    orderId: string;
    specificationId: string;
    houseTypeId: string;
    areaId: string;
    isOverride: boolean;
    highestIncludedProductCost: string;
    orderQuantity: number;
    isQuantityAllowed: boolean;
}

export interface ProductAndRelatedModelDTO {
    product: ProductModelDTO;
    productVariants: ProductVariantModelDTO[];
    specificationHouseTypeProducts: SpecificationHouseTypeProductModelDTO[];
    specificationProducts: SpecificationProductModelDTO[];
    productsSummary: ProductSummaryModelDTO[];
    products: ProductModelDTO[];
}

export interface OrderAreaProductGroupProductModelDTO {
    id: string;
    orderId: string;
    orderAreaId: string;
    orderAreaProductGroupId: string;
    productId: string;
    cost: string;
    isIncluded: boolean;
    isUpgrade: boolean;
    isOptional: boolean;
    isBespoke: boolean;
    notes: string;
    bespokeName: string;
    bespokeNotes: string;
    isPOA: boolean;
    productVariantId: string;
    quantity: number;
    isMailSent?: boolean;
}
