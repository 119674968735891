// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Content
import HouseType1Svg from "../../Content/Images/noImageDefault.svg";

export class ChoiceModel extends ModelBase<ChoiceModel, ChoiceModelDTO> {
    // #region Properties

    @observable
    public id = "";

    @observable
    public areaName = "";

    @observable
    public productGroupsAvailable = 0;

    @observable
    public productGroupsUsed = 0;

    @observable
    public imageUrl = "";

    @observable
    public originalImageUrl = "";

    @observable
    public thumbnailImageUrl = "";

    // #endregion Properties

    @computed
    public get areaImage() {
        if (this.thumbnailImageUrl !== null && this.thumbnailImageUrl !== undefined && this.thumbnailImageUrl !== "") {
            return this.thumbnailImageUrl;
        }

        return HouseType1Svg;
    }

    @action
    public fromDto = (dto: ChoiceModelDTO): void => {
        this.id = dto.id;
        this.areaName = dto.areaName;
        this.productGroupsAvailable = dto.productGroupsAvailable;
        this.productGroupsUsed = dto.productGroupsUsed;
        this.imageUrl = dto.imageUrl;
        this.originalImageUrl = dto.originalImageUrl;
        this.thumbnailImageUrl = dto.thumbnailImageUrl;
    };

    public toDto = (): ChoiceModelDTO => {
        throw "toDto is not supported";
    };
}

export interface ChoiceModelDTO {
    id: string;
    areaName: string;
    productGroupsAvailable: number;
    productGroupsUsed: number;
    imageUrl: string;
    originalImageUrl: string;
    thumbnailImageUrl: string;
}

export interface ChoicesAndRelatedModelDTO {
    choices: ChoiceModelDTO[];
    isChoicesLocked: boolean;
}
