/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import "./Error.scss";
import Typography from "@material-ui/core/Typography";

type Props = {
    error?: string;
};
export const Error2: React.FC<Props> = (props: Props) => {
    return (
        <div className="errorPage">
            <div className="errorPage__num">500</div>
            <Typography variant="h5" className="errorPage__message">
                Unfortunately the server has experienced an error
            </Typography>
            <a className="errorPage__link" href={"/"}>
                Return to dashboard
            </a>
        </div>
    );
};
Error2.defaultProps = {
    error: "Unknown error",
};
