import { makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
    border-top: 1px solid #ced4da;
    padding: 10px 50px 20px;

    input:-webkit-autofill {
        background-color: #ffffff !important;
    }

    p:not(.MuiFormHelperText-root) {
        margin-top: 18px;
        margin-bottom: 7px;
    }

    p.MuiFormHelperText-root:not(.Mui-Error) {
        margin-left: 14px;
        margin-right: 14px;
    }

    .MuiFormControl-marginDense {
        margin: 0;
    }

    .exp-date {
        .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
                width: 50%;
            }
        }

        .MuiOutlinedInput-adornedEnd {
            padding-right: 5px;
        }

        .MuiButtonBase-root {
            padding: 0;
        }

        .muiinput-underline: before {
            border-bottom: 0px;
        }

        .muiinput-underline.mui-error: after {
            border-bottom: 0px;
        }

        .MuiInputBase-input {
            border: 1px solid #ced4da !important;
            padding: 10px;
        }
    }

    div.btns {
        margin-top: 60px;
        /* display: flex;
    flex-direction: column; */

        button {
            margin-bottom: 15px;
        }
    }

    #card-input-exp-helper-text {
        color: red;
    }
`;

export const CardPaymentStyles = makeStyles((theme: Theme) => ({
    paymentForm: {
        backgroundColor: "#F9F9F9",
        borderTop: "1px solid #D6D6D6",
        margin: "0 18px",
        padding: "16px 18px 24px 18px",
    },
    dateControl: {
        "& > div > div": {
            "& > .MuiIconButton-root": {
                marginRight: "-20px",
                "& > .MuiIconButton-label": {
                    marginTop: "3px",
                },
            },
        },
    },
    // Grid row spacing override - see note 1
    rowGridItemSpacing: {
        marginTop: "-14px",
    },
    buttonRowGridItemSpacing: {
        display: "none",
    },
    payNowButton: {
        width: "100%",
    },
    cancelButton: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        // Grid row spacing override - see note 1
        rowOverrideSmGridItemSpacing: {
            marginTop: "0",
        },
        // Grid row spacing override - see note 1
        rowOverrideSmSmallGridItemSpacing: {
            marginTop: "-14px",
        },
    },
    [theme.breakpoints.up("md")]: {
        paymentForm: {
            margin: "0 18px",
        },
        // Grid row spacing override - see note 1
        rowOverrideSmSmallGridItemSpacing: {
            marginTop: "0",
        },
        buttonRowGridItemSpacing: {
            display: "block",
            visibility: "hidden",
        },
    },
}));

// Note 1
// MUI Grid v4 does not support separate spacing for rows and columns.
// Therefore some override styling has been added on the breakpoints
// xs, sm and md. The overrides need to be applied on a per control
// basis to ensure the alignment works as the layout changes.
//
// This issue is resolved in MUI 5, but that requires a React upgrade
// to v17 which I'm not willing to risk at this time.
