// Libraries
import { action, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

export class NewSnagModel extends ModelBase<NewSnagModel, NewSnagRelatedModelDTO> {
    // #region Properties

    @observable
    public id: string | undefined = "";

    @observable
    public propertyId: string = "";

    @observable
    public title: string = "";

    @observable
    public dueDate: string | undefined = "";

    @observable
    public areaId: string = "";

    @observable
    public details: string = "";

    @observable
    public snagNumber: string = "";

    @observable
    public resolved: boolean = false;

    @observable
    public actionTaken: string = "";

    @observable
    public isDeleted: boolean = false;

    @observable
    public propertySnagCategoryId: string | undefined = "";

    @observable
    public propertySnagDepartmentId: string | undefined = "";

    @observable
    public concurrencyToken: string = "";

    @observable
    public reportedDate: string = "";

    @observable
    public resolverIdentityUserId: string | undefined = "";

    @observable
    public resolvedDate: string | undefined = "";

    @observable
    public assignedToIdentityUserId: string | undefined = undefined;

    @observable
    public assignedToIndustryContactId: string | undefined = undefined;

    @observable
    public assignedToIndustryContactAdditionalContactId: string | undefined = undefined;

    // @observable
    // public createdByIdentityUserId: string | undefined = "";

    @observable
    public isCreatedByCustomer: boolean = false;

    // @observable
    // public snagStatus: number = 0;

    // @observable
    // public reasonForRejection: string = "";

    // @observable
    // public notes: string = "";

    @observable
    public snagPhotos: NewSnagPhotoModelDTO[] = [];

    //

    @observable
    public imageFile: File | undefined = undefined;

    @observable
    public imageBase64: string = "";

    @observable
    public houseAreas = observable([
        {
            key: "Select area",
            value: "",
        },
    ]);

    @action
    public setSnagTitle = (value: string) => {
        this.title = value;
    };

    @action
    public setHouseArea = (value: string) => {
        this.areaId = value;
    };

    // #endregion Properties

    @action
    public fromDto = (dto: NewSnagRelatedModelDTO): void => {
        for (const houseAreaDto of dto.houseAreas) {
            this.houseAreas.push({
                key: houseAreaDto.name,
                value: houseAreaDto.id,
            });
        }
    };

    public toDto = (): NewSnagFormModelDTO => {
        let isCreatedByCustomer = true;

        let snag: NewSnagModelDTO = {
            id: this.id,
            propertyId: this.propertyId,
            title: this.title,
            dueDate: this.dueDate,
            areaId: this.areaId,
            details: this.details,
            snagNumber: this.snagNumber,
            resolved: this.resolved,
            actionTaken: this.actionTaken,
            isDeleted: this.isDeleted,
            propertySnagCategoryId: this.propertySnagCategoryId,
            propertySnagDepartmentId: this.propertySnagDepartmentId,
            concurrencyToken: this.concurrencyToken,
            reportedDate: this.reportedDate,
            resolverIdentityUserId: this.resolverIdentityUserId,
            resolvedDate: this.resolvedDate,
            assignedToIdentityUserId: this.assignedToIdentityUserId,
            assignedToIndustryContactId: this.assignedToIndustryContactId,
            assignedToIndustryContactAdditionalContactId: this.assignedToIndustryContactAdditionalContactId,
            //createdByIdentityUserId: this.createdByIdentityUserId,
            isCreatedByCustomer: isCreatedByCustomer,
            //snagStatus: this.snagStatus,
            //reasonForRejection: this.reasonForRejection,
            //notes: this.notes
        };

        let snagPhotos: NewSnagPhotoModelDTO[] = [];

        let model: NewSnagFormModelDTO = {
            snag: snag,
            snagPhotos: snagPhotos,
        };

        return model;
    };
}

export interface NewSnagRelatedModelDTO {
    houseAreas: any[];
}

export interface NewSnagFormModelDTO {
    snag: NewSnagModelDTO;
    snagPhotos: NewSnagPhotoModelDTO[];
}

export interface NewSnagModelDTO {
    id: string | undefined;
    propertyId: string;
    title: string;
    dueDate?: string;
    areaId: string;
    details: string;
    snagNumber: string;
    resolved: boolean;
    actionTaken: string;
    isDeleted: boolean;
    propertySnagCategoryId?: string;
    propertySnagDepartmentId?: string;
    concurrencyToken: string;
    reportedDate: string;
    resolverIdentityUserId?: string;
    resolvedDate?: string;
    assignedToIdentityUserId?: string;
    assignedToIndustryContactId?: string;
    assignedToIndustryContactAdditionalContactId?: string;
    //createdByIdentityUserId?: string;
    isCreatedByCustomer: boolean;
    //snagStatus: number;
    //reasonForRejection?: string;
    //notes?: string;
}

export interface NewSnagPhotoModelDTO {
    // id: string;
    // fileName: string;
    // azureFileName: string;
    // base64: string;
    // isDeleted: boolean;
    id: string | undefined;
    fileName: string;
    azureFileName: string;
    azureFileNameFullResolution: string;
    azureThumbnailURL: string;
    resolvedImage: boolean;
    propertySnagId?: string | undefined;
    uploadedIdentityId: string | undefined;
    uploadedBy: string;
    uploadedDate: string;
    originalIsDeletedState: boolean;
    isDeleted: boolean;
    type: number;
}
