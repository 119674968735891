import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information for the progress page.
 */
export const ProgressPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    progresses: {
        padding: "20px 0 16px 0",
        overflowY: "auto",
        paddingBottom: "150px",
    },
    progress: {
        display: "flex",
        margin: "0 18px",
        "& > div:first-child": {
            minHeight: "80px",
            minWidth: "80px",
            position: "relative",
            "& > div": {
                position: "absolute",
            },
            // Link element.
            "& > div:first-child": {
                backgroundColor: "#ced4da",
                minHeight: "100%",
                minWidth: "8px",
                transform: "translate(36px, 0px)",
            },
        },
        // First progress element.
        "&:first-child": {
            "& > div:first-child": {
                // Link element.
                "& > div:first-child": {
                    bottom: "0",
                    minHeight: "50%",
                },
            },
        },
        // Last progress element.
        "&:last-child": {
            marginBottom: "40vh",

            "& > div:first-child": {
                // Link element.
                "& > div:first-child": {
                    minHeight: "50%",
                    top: "0",
                },
            },
        },
        // Latest
        "&.latest": {
            border: "1px solid #CED4DA",
            margin: "-1px 17px",
            borderRadius: "10px",
        },
    },
    progressTextRow: {
        color: "#808080",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    progressTitle: {
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    progressSubTitle: {
        fontSize: "12px",
    },
    majorActivityIcon: {
        alignItems: "center",
        backgroundColor: "#ced4da",
        border: "5px solid white",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        "& > svg": {
            fill: "white",
        },
        minHeight: "60px",
        minWidth: "60px",
        transform: "translate(10px, 10px)",
    },
    minorActivityIcon: {
        backgroundColor: "#ced4da",
        border: "5px solid white",
        borderRadius: "50%",
        minHeight: "26px",
        minWidth: "26px",
        transform: "translate(27px, 27px)",
    },
    gradient: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: "36px",
        background: "linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 25%)",
        pointerEvents: "none",
        zIndex: 1,
    },
    workspace: {
        position: "relative",
    },
    [theme.breakpoints.up("md")]: {
        progresses: {
            padding: "0 0 16px 0",
            marginRight: "18px",
            paddingBottom: "150px",
        },
        titleRow: {
            padding: "27px 18px",
            display: "flex",
            justifyContent: "space-between",
            flex: "1 0 auto", // Fix for MacBook Safari - Catalina
            "& > h4": {
                padding: "0px",
            },
            "& > button": {
                padding: "0",
            },
            "& .MuiSvgIcon-root": {
                margin: "-7px",
                fontSize: "35px",
            },
        },
        title: {
            fontSize: "22px",
            fontWeight: "bold",
        },
    },
}));
