// Libraries
import { Box, Link } from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { DocumentLinkStyle } from "./LinksStyling";
import { DocumentType } from "Custom/Models/Enums/DocumentTypes";
import { DocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/DocumentsViewModel";
import { DevelopmentDocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/DevelopmentDocumentsViewModel";
import { PropertyDrawingDocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/PropertyDrawingDocumentsViewModel";

interface IProps {
    id: string;
    className?: string;
    displayName: string;
    link: string;
    type: DocumentType;
    viewModel: DocumentsViewModel | PropertyDrawingDocumentsViewModel | DevelopmentDocumentsViewModel;
}

export const DocumentLink: React.FC<IProps> = props => {
    // #region Code Behind

    const documentLinkStyle = DocumentLinkStyle();

    const getClassNames = (): string => {
        let classNames = documentLinkStyle.root;

        if (!isEmptyOrWhitespace(props.className)) {
            classNames = `${classNames} ${props.className}`;
        }

        return classNames;
    };

    const handleClick = () => {
        props.viewModel.getDocument(props.id, props.displayName);
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Link className={getClassNames()} onClick={handleClick} target="_blank" underline="none">
            <Box style={{ marginRight: "50px" }}>
                <InsertDriveFileOutlinedIcon />
                {props.displayName}
            </Box>
        </Link>
    ));
};
