// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { InspectionStageModelDTO } from "Custom/Models/DashboardModels/InspectionStageModel";
import { InspectionModel } from "Custom/Models/DashboardModels/InspectionModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "../../Stores";

export class InspectionViewModel extends ViewModelBase<InspectionModel> {
    constructor(propertyId: string) {
        super(new InspectionModel());
        this.setDecorators(InspectionModel);

        //console.log("BUILDVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId);
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    // #endregion Properties

    // #region Computeds

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    @computed
    public get inspectionStages() {
        return this.model.inspectionStages;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    public navigateToDashboard = () => {
        this.history.push(`/${this.propertyId}`);
    };

    private load = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<InspectionStageModelDTO[]>(Server.Api.Dashboard.GetInspection + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
