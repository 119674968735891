// Libraries
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "util";

// Custom
import { CloseButtonStyle } from "./ButtonStyling";

interface IProps {
    canExecute?: boolean;
    className?: string;
    execute: () => void;
}

export const CloseButton: React.FC<IProps> = props => {
    // #region Code Behind

    const closeButtonStyle = CloseButtonStyle();

    const getClassNames = (): string => {
        let classNames = closeButtonStyle.root;

        if (!isEmptyOrWhitespace(props.className)) {
            classNames = `${classNames} ${props.className}`;
        }

        return classNames;
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const onClick = (): void => {
        props.execute();
    };

    // #endregion Code Behind

    return useObserver(() => (
        <IconButton aria-label="close" className={getClassNames()} disabled={isDisabled()} onClick={onClick}>
            <CloseIcon />
        </IconButton>
    ));
};
