import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information common to the dashboard sub-pages.
 */
export const DashboardPageStyles = makeStyles((theme: Theme) => ({
    // Root defines the workspace container.
    // This is scrollable.
    root: {
        display: "flex",
        //flex: "1",
        justifyContent: "center",
        overflowY: "auto",
    },
    // The direct child of root is the workpace.
    // This is limited in width for desktops.
    workspace: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        maxWidth: "1146px",
        minHeight: "1px",
    },
    titleRow: {
        alignItems: "center",
        backgroundColor: "#F9F9F9",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "72px",
        "& > h5": {
            fontSize: "16px",
            fontWeight: "bold",
            padding: "12px 18px",
        },
    },
    plotsListDropdown: {
        position: "absolute",
        backgroundColor: "#ffffff",
        top: "56px",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "5px 5px 5px rgb(0 0 0 / 40%)",
        border: "1px solid #000000",
        "> div": {
            padding: "10px 0px",
        },
    },
    titleParent: {
        display: "flex",
    },
}));

/**
 * Contains style information for the main page.
 */
export const MainPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    workspace: {},
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "13px",
        flex: "1 0 auto",
        "& > img": {
            height: "auto",
            maxWidth: "100%",
            maxHeight: "80px",
            [theme.breakpoints.down("sm")]: {
                maxHeight: "50px",
            },
        },
    },
    navigateToItem: {
        minHeight: "52px",
        "& > button": {
            "& > div.MuiCardContent-root": {
                padding: "12px 18px",
            },
        },
        "&:not(:last-child)": {
            borderBottom: "1px dashed #ced4da",
        },
    },
    navigateToPropertyItem: {
        minHeight: "52px",
        "& > button": {
            "& > div.MuiCardMedia-root": {
                minHeight: "274px",
            },
            "& > div.MuiCardContent-root": {
                padding: "12px 18px",
            },
        },
        "&:not(:last-child)": {
            borderBottom: "1px dashed #ced4da",
        },
    },
    navigateToArrow: {
        alignItems: "center",
        display: "flex",
        "& svg": {
            fill: "#3aaa35",
            marginLeft: "-6px",
            marginRight: "-6px",
        },
    },
    dropdownArrow: {
        alignItems: "center",
        display: "flex",
        transform: "rotate(90deg)",
        marginLeft: "10px",
        "& svg": {
            fill: "#3aaa35",
            marginLeft: "-6px",
            marginRight: "-6px",
            fontSize: "16px",
        },
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
    },
    propertyTitleRow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRight: "18px",
    },
    titleRow: {
        display: "flex",
        alignItems: "center",
        flex: "1",
        justifyContent: "space-between",
        marginRight: "18px",
        "& > div": {
            textAlign: "right",
        },
    },
    title: {
        fontSize: "16px",
        fontWeight: "bold",
    },
    subTitleRow: {},
    subTitle: {
        fontSize: "10px",
    },
    propertySubTitle: {
        fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
        workspace: {
            "& > div": {
                display: "flex",
                flexDirection: "row",
                margin: "55px 13px -5px 13px",

                "&.cards-container": {
                    height: "100%",
                    minHeight: "400px",
                    maxHeight: "750px",
                },

                "&.company-logo-container": {
                    margin: "60px 0 48px",
                    padding: 0,
                },
            },
        },
        navigateToItem: {
            boxShadow: "none",
            border: "1px solid #ced4da !important",
            flex: "1",
            margin: "5px",
            borderRadius: "0",
            backgroundColor: "#F9F9F9",
            "&.active": {
                backgroundColor: "white",
            },

            "& > button": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",

                "& > div.MuiCardMedia-root": {
                    minHeight: "274px",
                    flexGrow: 1,
                },
                "& > div.MuiCardContent-root": {
                    flexDirection: "column",
                },
            },
        },
        navigateToPropertyItem: {
            boxShadow: "none",
            border: "1px solid #ced4da !important",
            flex: "2",
            margin: "5px",
            borderRadius: "0",
            backgroundColor: "#F9F9F9",
            "&.active": {
                backgroundColor: "white",
            },

            "& > button": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",

                "& > div.MuiCardContent-root": {
                    flexDirection: "column",
                },

                "& > div.MuiCardMedia-root": {
                    flexGrow: 1,
                },
            },
        },
        propertyTitleRow: {
            alignItems: "flex-start",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "0",
        },
        titleRow: {
            alignItems: "flex-start",
            flexDirection: "row",
            marginRight: "0",
        },
        subTitleRow: {
            marginTop: "10px",
            minHeight: "48px",
            "& > p:first-child": {
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    },
}));

export const usePageAnimationStyles = makeStyles((theme: Theme) => ({
    container: {
        position: "absolute",
        top: "110px",
        right: 0,
        left: 0,
        bottom: 0,
        borderTop: "1px solid #CED4DA",
        backgroundColor: "white",
        transitionTimingFunction: "ease !important",
        "& > div": {
            height: "100%",
        },
    },
    pageWrapper: {
        overflowX: "hidden",
        position: "relative",
        [theme.breakpoints.up("md")]: {
            backgroundColor: "#F9F9F9",
        },
    },
}));
