import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HomeIcon from "@material-ui/icons/Home";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { Error } from "Custom/Components/Error";

import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { ProgressPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/ProgressPageStyling";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { ProgressViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProgressViewModel";
import { IObservableArray } from "mobx";
import { ProgressActivityModel } from "Custom/Models/DashboardModels/ProgressActivityModel";

export const Progress: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    //console.log("PROGRESSVIEW: PropertyId", router.match.params);
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const progressPageStyles = ProgressPageStyles();

    const [viewModel] = useState(() => new ProgressViewModel(propertyId));

    const [latestActivityEl, setLatestActivityEl] = useState<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (latestActivityEl) {
            const rect = latestActivityEl.getBoundingClientRect();
            if (latestActivityEl.parentElement) {
                const containerRect = latestActivityEl.parentElement.getBoundingClientRect();
                latestActivityEl.parentElement.scrollTop = rect.top - containerRect.top - containerRect.height / 2 + rect.height / 2;
            }
        }
    }, [latestActivityEl]);

    const getActivityIconStyle = (activity: any) => {
        const colour = activity.isCompleted ? activity.activityColour : "#ced4da";

        return {
            backgroundColor: colour,
        };
    };

    const getActivityTextStyle = (activity: any) => {
        const colour = activity.isCompleted ? "#191919" : "#808080";

        return {
            color: colour,
        };
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching progress..." />;

    const renderError = () => <Error errorText="Failed to retrieve progress information." />;

    const renderTitleRow = () =>
        isDesktop ? (
            <Box className={progressPageStyles.titleRow}>
                <Typography className={progressPageStyles.title} variant={"h5"}>
                    Progress
                </Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Progress</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderMajorActivity = (activity: any) => (
        <Box>
            {/* Dummy box for link - do not remove */}
            <Box />
            {/* Major activity icon */}
            <Box className={progressPageStyles.majorActivityIcon} style={getActivityIconStyle(activity)}>
                <HomeIcon />
            </Box>
        </Box>
    );

    const renderMinorActivity = (activity: any) => (
        <Box>
            {/* Dummy box for link - do not remove */}
            <Box />
            {/* Minor activity icon */}
            <Box className={progressPageStyles.minorActivityIcon} style={getActivityIconStyle(activity)} />
        </Box>
    );

    const renderActivities = (activities: IObservableArray<ProgressActivityModel>) => {
        const latestActivityId = viewModel.latestActivityId;

        return Array.from(activities)
            .slice()
            .map((activity, index) => (
                <React.Fragment key={index}>
                    <div
                        ref={ref => ref && latestActivityId === activity.getValue("id") && ref !== latestActivityEl && setLatestActivityEl(ref)}
                        className={`${progressPageStyles.progress} ${latestActivityId === activity.getValue("id") ? "latest" : ""}`}
                        key={index}
                    >
                        {renderMinorActivity(activity)}
                        <Box className={progressPageStyles.progressTextRow} style={getActivityTextStyle(activity)}>
                            <Typography className={progressPageStyles.progressTitle}>{activity.activityName}</Typography>
                            <Typography className={progressPageStyles.progressSubTitle}>{activity.completedDateFormatted}</Typography>
                        </Box>
                    </div>
                </React.Fragment>
            ));
    };

    const renderProgress = () => {
        const latestActivityId = viewModel.latestActivityId;

        return (
            <Box className={progressPageStyles.progresses}>
                <Box className={progressPageStyles.gradient} />
                {Array.from(viewModel.Activities)
                    .slice()
                    .filter(a => a.isNotApplicable == false)
                    .map((activity, index) => (
                        <React.Fragment key={index}>
                            <div
                                ref={ref => ref && latestActivityId === activity.getValue("id") && ref !== latestActivityEl && setLatestActivityEl(ref)}
                                className={`${progressPageStyles.progress} ${latestActivityId === activity.getValue("id") ? "latest" : ""}`}
                                key={index}
                            >
                                {renderMajorActivity(activity)}
                                <Box className={progressPageStyles.progressTextRow} style={getActivityTextStyle(activity)}>
                                    <Typography className={progressPageStyles.progressTitle}>{activity.activityName}</Typography>
                                    <Typography className={progressPageStyles.progressSubTitle}>{activity.completedDateFormatted}</Typography>
                                </Box>
                            </div>
                            {activity.hasActivities && renderActivities(activity.Activities)}
                        </React.Fragment>
                    ))}
            </Box>
        );
    };

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${progressPageStyles.root}`}>
            <Box className={`${dashboardPageStyles.workspace} ${progressPageStyles.workspace}`}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderTitleRow()}
                        {viewModel.hasActivities && renderProgress()}
                    </React.Fragment>
                )}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
        </Box>
    ));
};
