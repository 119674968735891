import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { Error } from "Custom/Components/Error";
import { DocumentLink } from "Custom/Components/Links/DocumentLink";

import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { DocumentsPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/DocumentPageStyling";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { DocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/DocumentsViewModel";
import { DevelopmentDocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/DevelopmentDocumentsViewModel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { DocumentsModel } from "Custom/Models/DashboardModels/DocumentsModel";
import { DocumentModel } from "Custom/Models/DashboardModels/DocumentModel";
import { PropertyDrawingDocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/PropertyDrawingDocumentsViewModel";
import { DocumentCategoryType } from "Custom/Models/Enums/DocumentCategoryType";
import { isNullOrEmpty } from "Core/Utils/Utils";
import { DashboardViewModel } from "Custom/ViewModels/DashboardViewModel";
import { StoresInstance } from "Custom/Stores";
import { DocumentType } from "Custom/Models/Enums/DocumentTypes";

export const Documents: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    const documentType = DocumentType;

    //console.log("DOCUMENTSVIEW: PropertyId", router.match.params);
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const documentsPageStyles = DocumentsPageStyles();

    const [viewModel] = useState(() => new DocumentsViewModel(propertyId));
    const [developmentViewModel] = useState(() => new DevelopmentDocumentsViewModel(propertyId));
    const [viewDrawingDocumentModel] = useState(() => new PropertyDrawingDocumentsViewModel(propertyId));

    const onAccordionChange = (document: DocumentsModel) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        document.setCanDisplay(!document.canDisplay);
    };

    const getDocumentLength = (documents: DocumentModel[]) => {
        let count = 0;
        if (documents && documents.length) {
            documents.map(document => {
                if (!isNullOrEmpty(document.documentUrl) && document.type != 3) {
                    count++;
                }
            });
        }

        return count > 1 ? `${count} documents` : `${count} document`;
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching documents..." />;

    const renderError = () => <Error errorText="Failed to retrieve the documents." />;

    const renderTitleRow = () =>
        isDesktop ? (
            <>
                <Box className={documentsPageStyles.titleRow}>
                    <Typography className={documentsPageStyles.title} variant={"h5"}>
                        Property Documents
                    </Typography>
                    <CloseButton execute={viewModel.navigateToDashboard} />
                </Box>
                <Box className={documentsPageStyles.titleBorder} />
            </>
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Property Documents</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderDevelopmentTitleRow = () =>
        isDesktop ? (
            <>
                <Box className={documentsPageStyles.titleRow}>
                    <Typography className={documentsPageStyles.title} variant={"h5"}>
                        Development Documents
                    </Typography>
                    <CloseButton execute={viewModel.navigateToDashboard} />
                </Box>
                <Box className={documentsPageStyles.titleBorder} />
            </>
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Development Documents</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderDrawingTitleRow = () =>
        isDesktop ? (
            <>
                <Box className={documentsPageStyles.titleRow}>
                    <Typography className={documentsPageStyles.title} variant={"h5"}>
                        Drawing Documents
                    </Typography>
                    <CloseButton execute={viewModel.navigateToDashboard} />
                </Box>
                <Box className={documentsPageStyles.titleBorder} />
            </>
        ) : (
            <Box className={dashboardPageStyles.titleRow}>
                <Typography variant={"h5"}>Drawing Documents</Typography>
                <CloseButton execute={viewModel.navigateToDashboard} />
            </Box>
        );

    const renderDocuments = (documents: any[], type: DocumentType, viewModel: DocumentsViewModel | PropertyDrawingDocumentsViewModel | DevelopmentDocumentsViewModel) =>
        documents.map((document, index) =>
            document.type == 3 ? (
                <div className={documentsPageStyles.checklistSubCategory}>
                    <label>{document.subCategoryName}</label>
                </div>
            ) : (
                document.documentUrl && (
                    <DocumentLink
                        className={documentsPageStyles.documentLink}
                        displayName={document.documentName}
                        key={index}
                        link={document.documentUrl}
                        id={document.id}
                        type={type}
                        viewModel={viewModel}
                    />
                )
            ),
        );

    const renderDocumentCategories = () => (
        <Box className={documentsPageStyles.documentCategories}>
            {viewModel.DocumentCategories.length > 0
                ? viewModel.DocumentCategories.map((documentCategory, index) => (
                      <Accordion
                          className={documentsPageStyles.accordion}
                          expanded={documentCategory.canDisplay}
                          onChange={onAccordionChange(documentCategory)}
                          TransitionProps={{ unmountOnExit: true, timeout: 0 }}
                          key={`documentCategory`}
                      >
                          <AccordionSummary
                              className={documentsPageStyles.accordionSummary}
                              expandIcon={documentCategory.canDisplay ? <CancelIcon /> : <AddCircleIcon />}
                              aria-label="Expand"
                              aria-controls={`additional-content${index}`}
                              id={`additional-header${index}`}
                          >
                              <Box className={documentsPageStyles.productGroupTitleContainer}>
                                  <Typography>{documentCategory.categoryName.split(/(?=[A-Z])/).join(" ")}</Typography>
                                  <Box className={documentsPageStyles.productGroupCounterContainer}>
                                      <Typography variant="body2">{getDocumentLength(documentCategory.documents)}</Typography>
                                  </Box>
                              </Box>
                          </AccordionSummary>
                          <AccordionDetails className={documentsPageStyles.productGroupAccordionDetails}>
                              {renderDocuments(documentCategory.documents, documentType.property, viewModel)}
                          </AccordionDetails>
                      </Accordion>
                  ))
                : "There are currently no documents to show. If you think this may be incorrect, please contact your sales representative."}
        </Box>
    );

    const renderDevelopmentDocumentCategories = () => (
        <Box className={documentsPageStyles.documentCategories}>
            {developmentViewModel.DocumentCategories.length > 0
                ? developmentViewModel.DocumentCategories.map((documentCategory, index) => (
                      <Accordion
                          className={documentsPageStyles.accordion}
                          expanded={documentCategory.canDisplay}
                          onChange={onAccordionChange(documentCategory)}
                          TransitionProps={{ unmountOnExit: true, timeout: 0 }}
                          key={`documentCategory`}
                      >
                          <AccordionSummary
                              className={documentsPageStyles.accordionSummary}
                              expandIcon={documentCategory.canDisplay ? <CancelIcon /> : <AddCircleIcon />}
                              aria-label="Expand"
                              aria-controls={`additional-content${index}`}
                              id={`additional-header${index}`}
                          >
                              <Box className={documentsPageStyles.productGroupTitleContainer}>
                                  <Typography>{documentCategory.categoryName.split(/(?=[A-Z])/).join(" ")}</Typography>
                                  <Box className={documentsPageStyles.productGroupCounterContainer}>
                                      <Typography variant="body2">{getDocumentLength(documentCategory.documents)}</Typography>
                                  </Box>
                              </Box>
                          </AccordionSummary>
                          <AccordionDetails className={documentsPageStyles.productGroupAccordionDetails}>
                              {renderDocuments(documentCategory.documents, documentType.development, developmentViewModel)}
                          </AccordionDetails>
                      </Accordion>
                  ))
                : "There are currently no documents to show."}
        </Box>
    );

    const renderDrawingCategories = () => (
        <Box className={documentsPageStyles.documentCategories}>
            {viewDrawingDocumentModel.Documents.length > 0
                ? viewDrawingDocumentModel.Documents.map((document, index) => (
                      <Box>
                          <Box className={documentsPageStyles.productGroupTitleContainer}>
                              <Typography>{document.DocumentName.split(/(?=[A-Z])/).join(" ")}</Typography>
                              {/* <Box className={documentsPageStyles.productGroupCounterContainer}>
                                  <Typography variant="body2">{viewDrawingDocumentModel.Documents.length}</Typography>
                              </Box> */}
                          </Box>
                          {document.documentUrl && (
                              <DocumentLink
                                  className={documentsPageStyles.documentLink}
                                  displayName={document.fileName}
                                  key={index}
                                  link={document.documentUrl}
                                  id={document.id}
                                  type={documentType.drawing}
                                  viewModel={viewDrawingDocumentModel}
                              />
                          )}
                      </Box>
                  ))
                : "There are currently no documents to show. If you think this may be incorrect, please contact your sales representative."}
        </Box>
    );

    return useObserver(() => (
        <>
            <Box className={`${dashboardPageStyles.root} ${documentsPageStyles.root}`}>
                <Box className={dashboardPageStyles.workspace}>
                    {developmentViewModel.ApiStatus === ApiStatus.success && (
                        <React.Fragment>
                            {renderDevelopmentTitleRow()}
                            {renderDevelopmentDocumentCategories()}
                        </React.Fragment>
                    )}
                    {developmentViewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                    {developmentViewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
                </Box>
            </Box>
            <Box className={`${dashboardPageStyles.root} ${documentsPageStyles.root}`}>
                <Box className={dashboardPageStyles.workspace}>
                    {viewModel.ApiStatus === ApiStatus.success && (
                        <React.Fragment>
                            {renderTitleRow()}
                            {renderDocumentCategories()}
                        </React.Fragment>
                    )}
                    {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                    {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
                </Box>
            </Box>
            <Box className={`${dashboardPageStyles.root} ${documentsPageStyles.root}`}>
                <Box className={dashboardPageStyles.workspace}>
                    {viewModel.ApiStatus === ApiStatus.success && (
                        <React.Fragment>
                            {renderDrawingTitleRow()}
                            {renderDrawingCategories()}
                        </React.Fragment>
                    )}
                    {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                    {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
                </Box>
            </Box>
        </>
    ));
};
