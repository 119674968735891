// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { ProgressActivityModel, ProgressActivityModelDTO } from "./ProgressActivityModel";

export class ProgressModel extends ModelBase<ProgressModel> {
    // #region Properties

    @observable
    public activities = observable<ProgressActivityModel>([]);

    // #endregion Properties

    @action
    public fromDto = (dto: ProgressActivityModelDTO[]): void => {
        for (const activity of dto) {
            const activityModel = new ProgressActivityModel();

            activityModel.fromDto(activity);

            this.activities.push(activityModel);
        }
    };

    public toDto = (): ProgressActivityModelDTO[] => {
        throw "toDto is not supported";
    };
}
