// Libraries
import { action, computed, observable } from "mobx";
import moment from "moment";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { KeyValuePair } from "Core/Models/KeyValuePair";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { SnagModel, ViewSnagDTO, SnagPhotoModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { StoresInstance } from "Custom/Stores/Stores";
import { SnagPhotoStore } from "Custom/Stores/Domain/SnagPhotoStore";
import { HouseAreaStore } from "Custom/Stores/Domain/HouseAreaStore";
import { SnagTypeStore } from "Custom/Stores/Domain/SnagTypeStore";
import { SnagStatusType } from "../../Models/Enums/SnagStatusType";

export class ViewSnagViewModel extends ViewModelBase<SnagModel> {
    constructor(propertyId: string, id: string) {
        super(new SnagModel(), false);
        this.setDecorators(SnagModel);

        //console.log("VIEWSNAGVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId, id);
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    private snagPhotoStore: SnagPhotoStore = StoresInstance.domain.SnagPhotoStore;

    private houseAreaStore: HouseAreaStore = StoresInstance.domain.HouseAreaStore;

    private snagTypeStore: SnagTypeStore = StoresInstance.domain.SnagTypeStore;

    @observable
    public haveSubmittedToServer = false;

    @observable
    public serverValidationMessage = "";

    // #endregion Properties

    // #region Computeds

    @computed
    public get getSnagPhotos(): SnagPhotoModelDTO[] {
        return this.snagPhotoStore.getSnagPhotos.filter(sp => !sp.isDeleted);
    }

    @computed
    public get getSnagPhotosTmp(): string[] {
        return this.snagPhotoStore.getTmpPhotos;
    }

    @computed
    public get getAreaName(): string {
        let area = this.houseAreaStore.getHouseAreas.find(a => a.id === this.model.areaId);
        return area !== undefined ? area.name : "";
    }

    @computed
    public get getTitle(): string {
        return this.model.title;
    }

    @computed
    public get getHouseAreasKeyValue(): KeyValuePair[] {
        let arr: KeyValuePair[] = [];
        this.houseAreaStore.getHouseAreas.map(item => {
            let keyValue: KeyValuePair = {
                key: item.name,
                value: item.name,
            };
            arr.push(keyValue);
        });

        return arr;
    }

    @computed
    public get getSnagTypesKeyValue(): KeyValuePair[] {
        let arr: KeyValuePair[] = [];
        if (this.snagTypeStore.getSnagTypes) {
            this.snagTypeStore.getSnagTypes.map(item => {
                let keyValue: KeyValuePair = {
                    key: item.name,
                    value: item.name,
                };
                arr.push(keyValue);
            });
        }

        return arr;
    }

    @computed
    public get ReportedDate(): string {
        return this.model.reportedDate ? `${moment(this.model.reportedDate).format("DD/MM/YYYY")}` : `Unknown date`;
    }

    @computed
    public get isCompleted(): boolean {
        return this.model.resolved;
    }

    @computed
    public get isRejected(): boolean {
        return this.model.snagStatus === SnagStatusType.Rejected;
    }

    @computed
    public get rejectedReason(): string | undefined {
        return this.model.rejectedReason;
    }

    @computed
    public get hasRejectedReason(): boolean {
        return this.rejectedReason !== "" && this.rejectedReason !== null && this.rejectedReason !== undefined && this.isRejected && !this.isCompleted;
    }

    @computed
    public get FormattedSnagStatus(): string {
        if (this.isCompleted) {
            const resolvedDate = this.model.resolvedDate !== null && this.model.resolvedDate !== undefined ? moment(this.model.resolvedDate).format("DD/MM/YYYY") : "Unknown date";
            return `COMPLETED: ${resolvedDate}`;
        }

        if (this.isRejected) {
            const snagStatusUpdatedDate =
                this.model.snagStatusUpdatedDate !== null && this.model.snagStatusUpdatedDate !== undefined
                    ? moment(this.model.snagStatusUpdatedDate).format("DD/MM/YYYY")
                    : "Unknown date";
            return `REJECTED: ${snagStatusUpdatedDate}`;
        }

        return "";
    }

    @computed
    public get resolvedBy(): string {
        return this.model.resolvedBy !== "" && this.model.resolvedBy !== null && this.model.resolvedBy !== undefined ? this.model.resolvedBy : "Unknown";
    }

    // #endregion Computeds

    // #region Actions

    public naviagateToSnagList = () => {
        if (this.propertyId !== undefined) {
            this.history.push(`/snags/${this.propertyId}`);
        }
    };

    @action
    public setHaveSubmittedToServer = (value: boolean) => {
        this.haveSubmittedToServer = value;
    };

    @action
    public setServerValidationMessage = (value: string) => {
        this.serverValidationMessage = value;
    };

    private load = async (propertyId: string, id: string): Promise<void> => {
        try {
            this.setServerValidationMessage("");

            const apiResult = await this.Get<ViewSnagDTO>(Server.Api.Snags.GetViewById + "/" + propertyId + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload.snag);

                this.snagPhotoStore.populateSnagPhotos(apiResult.payload.snagPhotos);
                this.houseAreaStore.populateHouseAreas(apiResult.payload.houseAreas);
            } else {
                if (apiResult.errors.length > 0) {
                    const error = apiResult.errors[0];

                    if (!isEmptyOrWhitespace(error.message)) {
                        this.setServerValidationMessage(error.message);
                    } else {
                        this.setServerValidationMessage("There was an unknown error getting data related for the snag.");
                    }
                }
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error getting data related for the snag.");
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
