import React, { useState, useContext } from "react";
import { Button, AppBar, Drawer, IconButton, Link, List, Toolbar, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Core
import { useMediaQuery } from "Core/Utils/Media";

// Custom
import { StoresContext, Stores } from "Custom/Stores";
import { GridSpaced } from "Custom/StylesAppSpecific/GridHelperStyling";
import { HeaderViewModel } from "Custom/ViewModels/HeaderViewModel";
import { HeaderLogoModel } from "Custom/ViewModels/DashboardSubViewModels/HeaderLogoModel";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

const StyledIconButton = styled(IconButton)`
    padding: 0;
    color: white;
`;

const DrawerList = styled(List)`
    button,
    a {
        width: 100%;
    }

    .MuiTypography-root {
        text-align: center;
        color: white;
        font-weight: bold;
    }
`;

export const Header: React.FC = () => {
    // #region Code Behind

    const isMobile = !!useMediaQuery("screen and (max-width: 767px)");
    const storesContext = useContext<Stores>(StoresContext);

    const [viewModel] = useState(() => new HeaderViewModel());
    const [ViewModels] = useState(() => new HeaderLogoModel());
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    // #endregion Code Behind

    const headerOptions = () => {
        return (
            <>
                <Button color="primary">
                    <Link onClick={viewModel.logout}>
                        <Typography color={"textSecondary"} variant={"button"}>
                            Logout
                        </Typography>
                    </Link>
                </Button>
            </>
        );
    };

    return useObserver(() => (
        <>
            {storesContext.domain.AccountStore.IsLoggedIn ? (
                <>
                    <AppBar position="fixed">
                        <Toolbar style={{ maxHeight: "42px", minHeight: "42px" }}>
                            {storesContext.domain.AccountStore.IsLoggedIn ? (
                                <>
                                    <div onClick={ViewModels.handleNavBarLogoClick} style={{ cursor: "pointer" }}>
                                        <img src="resources/logo-white.png" alt="Customer Portal Logo" style={{ marginLeft: "10px" }} />
                                    </div>
                                    <GridSpaced item margin={"0 0 0 auto"} padding={"10px 0px"}>
                                        {isMobile ? (
                                            <StyledIconButton aria-label="Open drawer" color="primary" onClick={handleDrawerOpen}>
                                                <MenuIcon />
                                            </StyledIconButton>
                                        ) : (
                                            <React.Fragment>{headerOptions()}</React.Fragment>
                                        )}
                                    </GridSpaced>
                                </>
                            ) : (
                                <></>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Drawer anchor="right" onClose={handleDrawerClose} open={open} PaperProps={{ style: { backgroundColor: "black" } }}>
                        <div style={{ display: "block", height: "42px" }}>
                            <IconButton onClick={handleDrawerClose} style={{ display: "block", marginLeft: "auto", height: "100%" }}>
                                <ChevronRightIcon style={{ color: "white" }} />
                            </IconButton>
                        </div>
                        <DrawerList>{headerOptions()}</DrawerList>
                    </Drawer>
                </>
            ) : (
                <></>
            )}
        </>
    ));
};
