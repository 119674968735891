// Libraries
import { Box, Card, CardActionArea, CardContent, CardMedia, Menu, MenuItem, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { useRouter } from "Core/Base";
import { Loader } from "Core/Components/Loader";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { Error } from "Custom/Components/Error";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { DashboardPageStyles, MainPageStyles, usePageAnimationStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { DashboardViewModel } from "Custom/ViewModels/DashboardViewModel";
import { DocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/DocumentsViewModel";
import { DevelopmentDocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/DevelopmentDocumentsViewModel";
import { PropertyDrawingDocumentsViewModel } from "Custom/ViewModels/DashboardSubViewModels/PropertyDrawingDocumentsViewModel";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

export const Dashboard: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    const dashboardPageStyles = DashboardPageStyles();
    const mainPageStyles = MainPageStyles();
    const pageAnimationStyles = usePageAnimationStyles();
    const propertyId = (router.match.params as any).propertyId;

    const [viewModel] = useState(() => new DashboardViewModel(propertyId));
    const [viewDocumentModel] = useState(() => new DocumentsViewModel(propertyId));
    const [developmentViewModel] = useState(() => new DevelopmentDocumentsViewModel(propertyId));
    const [viewDrawingDocumentModel] = useState(() => new PropertyDrawingDocumentsViewModel(propertyId));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget.parentElement!.parentElement!);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleItemClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
        event.stopPropagation();
        setAnchorEl(null);
        viewModel.changePlot(id);
    };

    const mouseDown = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText="Fetching dashboard..." />;

    const renderError = () => <Error errorText="Failed to retrieve dashboard information." />;

    const renderPropertyItemCardContentDesktop = (title: string, subTitle: string) => {
        const hasSubtitle = !isEmptyOrWhitespace(subTitle);
        return (
            <CardContent className={mainPageStyles.content}>
                <Box className={mainPageStyles.propertyTitleRow}>
                    <Box className={dashboardPageStyles.titleParent}>
                        <Typography className={mainPageStyles.title}>{title}</Typography>
                        {viewModel.availablePlotOptions.length > 1 && (
                            <Box className={mainPageStyles.dropdownArrow} onClick={e => handleClick(e)} onMouseDown={e => mouseDown(e)}>
                                <ArrowForwardIosOutlinedIcon />
                            </Box>
                        )}
                    </Box>
                    <Box className={mainPageStyles.navigateToArrow}>
                        <ArrowForwardIosOutlinedIcon />
                    </Box>
                </Box>

                <Box className={mainPageStyles.subTitleRow}>{hasSubtitle && <Typography className={mainPageStyles.propertySubTitle}>{subTitle}</Typography>}</Box>
                {viewModel.availablePlotOptions && (
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: "300px",
                                maxWidth: "70%",
                            },
                        }}
                        getContentAnchorEl={null}
                    >
                        {viewModel.availablePlotOptions.map((plot, index) => (
                            <MenuItem key={index} onClick={e => handleItemClick(e, plot.id)} selected={plot.id === viewModel.getCurrentPropertyId}>
                                {`${plot.plotNumber} ${plot.developmentName}`}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </CardContent>
        );
    };

    const renderPropertyItemCardContentMobile = (title: string, subTitle: string) => {
        const hasSubtitle = !isEmptyOrWhitespace(subTitle);

        return (
            <CardContent className={mainPageStyles.content}>
                <Box className={mainPageStyles.propertyTitleRow} onClick={e => handleClick(e)} onMouseDown={e => mouseDown(e)}>
                    <Box className={dashboardPageStyles.titleParent}>
                        <Typography className={mainPageStyles.title}>{title}</Typography>
                        <Box className={mainPageStyles.dropdownArrow}>
                            <ArrowForwardIosOutlinedIcon />
                        </Box>
                    </Box>
                    {hasSubtitle && <Typography className={mainPageStyles.propertySubTitle}>{subTitle}</Typography>}
                </Box>
                {viewModel.availablePlotOptions && (
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: "300px",
                                maxWidth: "70%",
                            },
                        }}
                        getContentAnchorEl={null}
                    >
                        {viewModel.availablePlotOptions.map((plot, index) => (
                            <MenuItem key={index} onClick={e => handleItemClick(e, plot.id)} selected={plot.id === viewModel.getCurrentPropertyId}>
                                {`${plot.plotNumber} ${plot.developmentName}`}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
                <Box className={mainPageStyles.navigateToArrow}>
                    <ArrowForwardIosOutlinedIcon />
                </Box>
            </CardContent>
        );
    };

    const renderPropertyItem = (image: string, title: string, subTitle: string, propertyItemPathname: string, thumbnail: string) => {
        const hasImage = !isEmptyOrWhitespace(image);
        return (
            <Card className={`${mainPageStyles.navigateToPropertyItem} ${router.location.pathname === propertyItemPathname || router.location.pathname === "/" ? "active" : ""}`}>
                <CardActionArea onClick={() => router.history.push(propertyItemPathname)}>
                    {isDesktop ? renderPropertyItemCardContentDesktop(title, subTitle) : renderPropertyItemCardContentMobile(title, subTitle)}
                    {hasImage ? (
                        <>
                            <PrivateBlob src={image.split(".").pop() !== "pdf" ? image : thumbnail} title={subTitle} cardMedia={true} />
                        </>
                    ) : isDesktop ? (
                        <CardMedia title="There is no image available for this property" />
                    ) : (
                        <React.Fragment />
                    )}
                </CardActionArea>
            </Card>
        );
    };

    const renderItemCardContentDesktop = (title: string, subTitle: string, date: string) => {
        const hasSubtitle = !isEmptyOrWhitespace(subTitle);
        const hasDate = !isEmptyOrWhitespace(date);

        return (
            <CardContent className={mainPageStyles.content}>
                <Box className={mainPageStyles.titleRow}>
                    <Typography className={mainPageStyles.title}>{title}</Typography>
                    <Box className={mainPageStyles.navigateToArrow}>
                        <ArrowForwardIosOutlinedIcon />
                    </Box>
                </Box>
                <Box className={mainPageStyles.subTitleRow}>
                    {hasSubtitle && <Typography className={mainPageStyles.subTitle}>{subTitle}</Typography>}
                    {hasDate && <Typography className={mainPageStyles.subTitle}>{`Last update: ${date}`}</Typography>}
                </Box>
            </CardContent>
        );
    };

    const renderItemCardContentMobile = (title: string, subTitle: string, date: string) => {
        const hasSubtitle = !isEmptyOrWhitespace(subTitle);
        const hasDate = !isEmptyOrWhitespace(date);

        return (
            <CardContent className={mainPageStyles.content}>
                <Box className={mainPageStyles.titleRow}>
                    <Typography className={mainPageStyles.title}>{title}</Typography>
                    <Box>
                        {hasSubtitle && <Typography className={mainPageStyles.subTitle}>{subTitle}</Typography>}
                        {hasDate && <Typography className={mainPageStyles.subTitle}>{`Last update: ${date}`}</Typography>}
                    </Box>
                </Box>
                <Box className={mainPageStyles.navigateToArrow}>
                    <ArrowForwardIosOutlinedIcon />
                </Box>
            </CardContent>
        );
    };

    const renderItem = (image: string, title: string, subTitle: string, date: string, itemPathname: string) => {
        const hasImage = !isEmptyOrWhitespace(image) && isDesktop;

        return (
            <Card className={`${mainPageStyles.navigateToItem} ${router.location.pathname === itemPathname || router.location.pathname === "/" ? "active" : ""}`}>
                <CardActionArea onClick={() => router.history.push(itemPathname)}>
                    {isDesktop ? renderItemCardContentDesktop(title, subTitle, date) : renderItemCardContentMobile(title, subTitle, date)}
                    {hasImage && <PrivateBlob src={image} title={subTitle} cardMedia={true} />}
                </CardActionArea>
            </Card>
        );
    };

    const renderItems = () => {
        const docLength = viewDocumentModel.DocumentsLength;
        const documentlength = viewDrawingDocumentModel.model.documents.length;
        const devdocumentlength = developmentViewModel.DocumentsLength;

        const totalDocuments = `${docLength + documentlength + devdocumentlength} docs uploaded`;
        return (
            <Box className="cards-container">
                {renderPropertyItem(
                    viewModel.Summary.houseTypeImageUrl,
                    viewModel.PropertySummary,
                    viewModel.Summary.houseTypeName,
                    viewModel.navigateToPropertyPathname,
                    viewModel.Summary.houseTypeThumbnailUrl,
                )}
                {viewModel.canViewBuildStages &&
                    renderItem("resources/build.png", "Build", viewModel.InspectionSummary, viewModel.InspectionDate, viewModel.navigateToInspectionPathname)}
                {renderItem("resources/progress.png", "Progress", viewModel.ProgressSummary, viewModel.ProgressDate, viewModel.navigateToProgressPathname)}
                {viewModel.canViewIsChoicesToPlot &&
                    renderItem("resources/choices.png", "Choices", viewModel.ChoicesSummary, viewModel.ChoicesDate, viewModel.navigateToChoicesPathname)}
                {renderItem("resources/documents.png", "Documents", totalDocuments, viewModel.DocumentsDate, viewModel.navigateToDocumentsPathname)}
                {viewModel.canViewSnags &&
                    viewModel.canViewReportSnags &&
                    renderItem("resources/snags.png", "Snags", viewModel.SnagsSummary, viewModel.SnagsDate, viewModel.navigateToSnagsPathname)}
            </Box>
        );
    };

    const renderLogo = () => {
        return (
            <Box className={`company-logo-container ${mainPageStyles.logoContainer}`}>
                {viewModel.canDisplayCompanyLogo && (
                    <>
                        <PrivateBlob alt="Company logo" src={viewModel.companyLogo} />
                        {/* <img alt="Company logo" src={viewModel.companyLogo} /> */}
                    </>
                )}
            </Box>
        );
    };

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${mainPageStyles.root} ${pageAnimationStyles.pageWrapper}`}>
            <Box className={`${dashboardPageStyles.workspace} ${mainPageStyles.workspace}`}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderItems()}
                        {renderLogo()}
                    </React.Fragment>
                )}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
        </Box>
    ));
};
