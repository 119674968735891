// Libraries
import { action, observable } from "mobx";
import { startOfMonth, isDate, endOfMonth } from "date-fns";

// Core
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { FieldType } from "../../Core/Utils/Utils";

// Custom
import { CardPaymentModel } from "../Models/CardPaymentModel";

export enum CARD_TYPE {
    Visa = "visa",
    Mastercard = "mastercard",
}

export class CardPaymentViewModel extends ViewModelBase<CardPaymentModel> {
    constructor(type: CARD_TYPE) {
        super(new CardPaymentModel(type));
        this.setDecorators(CardPaymentModel);
    }

    // #region Server Properties and Actions
    // #endregion Server Properties and Actions

    // #region Bolierplate

    public afterUpdate: undefined;

    public beforeUpdate(fieldName: keyof FieldType<CardPaymentModel>, value: any): any {
        if (fieldName === "expiry" && isDate(value)) {
            value = endOfMonth(value);
        }
        return value;
    }

    public isFieldValid(fieldName: keyof FieldType<CardPaymentModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (isValid && fieldName === "cardNumber") {
            if (this.getValue("type") === CARD_TYPE.Visa) {
                if (!/^4\d{3}(| |-)(?:\d{4}\1){2}\d{4}$/.test(value)) {
                    isValid = false;
                    errorMessage = "Invalid card type";
                }
            } else if (this.getValue("type") === CARD_TYPE.Mastercard) {
                if (!/^5[1-5]\d{2}(| |-)(?:\d{4}\1){2}\d{4}$/.test(value)) {
                    isValid = false;
                    errorMessage = "Invalid card type";
                }
            } else {
                isValid = false;
                errorMessage = "Error validating card type";
            }
        } else if (fieldName === "expiry") {
            const month = startOfMonth(new Date());
            if (value < month) {
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #endregion Boilerplate
}
