// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { DocumentsModel, DocumentsModelDTO } from "./DocumentsModel";

export class DocumentCategoriesModel extends ModelBase<DocumentCategoriesModel> {
    // #region Properties

    @observable
    public documentCategories = observable<DocumentsModel>([]);

    // #endregion Properties

    @action
    public fromDto = (dto: DocumentsModelDTO[]): void => {
        for (const documentCategory of dto) {
            const documentsModel = new DocumentsModel();

            documentsModel.fromDto(documentCategory);

            this.documentCategories.push(documentsModel);
        }
    };

    public toDto = (): DocumentsModelDTO[] => {
        throw "toDto is not supported";
    };
}
