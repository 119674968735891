// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { ProductGroupViewModel } from "./ProductGroupViewModel";
import { Server } from "Custom/Globals/AppUrls";
import { ChoicesAreaModelDTO, OrderAreaModelDTO, OrderModelDTO } from "Custom/Models/DashboardModels/ChoicesAreaModel";
import { ChoicesAreaModel } from "Custom/Models/DashboardModels/ChoicesAreaModel";
import { OrderAreaProductGroupModelDTO, ProductGroupModelDTO } from "Custom/Models/DashboardModels/ProductGroupModel";
import { OrderAreaProductGroupProductModelDTO, ProductModelDTO } from "Custom/Models/DashboardModels/ProductModel";
import { ProductVariantModelDTO } from "Custom/Models/DashboardModels/ProductVariantModel";
import { SpecificationHouseTypeProductModelDTO } from "Custom/Models/DashboardModels/SpecificationHouseTypeProductModel";
import { SpecificationProductModelDTO } from "Custom/Models/DashboardModels/SpecificationProductModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { StoresInstance } from "../../Stores";

// Content
import HouseType1Svg from "../../Content/Images/noImageDefault.svg";
import { ProductSummaryModelDTO } from "Custom/Models/DashboardModels/ProductSummary";

export class ChoicesAreaViewModel extends ViewModelBase<ChoicesAreaModel> {
    constructor(propertyId: string, areaId: string) {
        super(new ChoicesAreaModel());
        this.setDecorators(ChoicesAreaModel);

        //console.log("CHOICESAREAVM CONSTRUCTOR: PropertyId", propertyId);
        this.propertyId = propertyId;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId, areaId);
        }
    }

    // #region Properties

    @observable
    public propertyId: string = "";

    @observable
    private productGroupViewModels: ProductGroupViewModel[] = [];

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    @observable
    public apiStatusLoadingMessage: string = "Fetching choices...";

    @observable
    public apiStatusErrorMessage: string = "Failed to retrieve the choices.";

    @observable
    private choicesCheckCounter: number = 0;

    // #endregion Properties

    // #region Computeds

    @computed
    public get areaImage() {
        if (this.model.imageUrl !== null && this.model.imageUrl !== undefined && this.model.imageUrl !== "") {
            return this.model.imageUrl;
        }

        return HouseType1Svg;
    }

    @computed
    public get productGroups() {
        return this.productGroupViewModels;
    }

    @computed
    public get canAddToOrder() {
        const missingChoices = this.productGroups.filter(pg => pg.isChoiceSelected === false);

        if (this.productGroups.length > 0) {
            if (missingChoices != undefined) {
                return this.productGroups.length - missingChoices.length >= 1;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    @action
    public async isChoicesLockedAsync(propertyId: string, id: string): Promise<boolean | undefined> {
        //this function checks once when a user selects option if the choices sections is locked
        //this is done in order to prevent the user from making multiple time consuming selection just to realize that the task can't be finalized
        // only allow one check to minimize server requests
        this.setApiStatusLoadingMessage("Saving choices...");
        this.setApiStatus(ApiStatus.loading);

        if (this.choicesCheckCounter == 0) {
            try {
                const apiResult = await this.Get<boolean>(Server.Api.Choices.GetIsChoicesLocked + "/" + propertyId);

                if (apiResult.wasSuccessful) {
                    this.setApiStatus(ApiStatus.success);
                    this.choicesCheckCounter = 1; // only change value on success
                    return apiResult.payload;
                } else {
                    this.setApiStatusErrorMessage("Failed to retrieve the choices.");
                    this.setApiStatus(ApiStatus.error);
                }
            } catch (exception) {
                this.setApiStatusErrorMessage("Failed to retrieve the choices.");
                this.setApiStatus(ApiStatus.error);
            } finally {
                // Finally
                this.setIsLoading(false);
            }
        }
    }

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    @computed
    public get ApiStatusLoadingMessage(): string {
        return this.apiStatusLoadingMessage;
    }

    @computed
    public get ApiStatusErrorMessage(): string {
        return this.apiStatusErrorMessage;
    }

    @computed
    public get isChoicesLocked() {
        return this.model.isChoicesLocked;
    }

    // #end region computed

    // #region Actions

    @action
    private createViewModels() {
        for (const productGroup of this.model.productGroups) {
            this.productGroupViewModels.push(new ProductGroupViewModel(productGroup));
        }
    }

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    @action
    public setApiStatusLoadingMessage(message: string) {
        this.apiStatusLoadingMessage = message;
    }

    @action
    public setApiStatusErrorMessage(message: string) {
        this.apiStatusErrorMessage = message;
    }

    public navigateToChoices = (propertyId: string) => {
        this.history.push(`/choices/${propertyId}`);
    };

    private load = async (propertyId: string, id: string): Promise<void> => {
        this.setApiStatusLoadingMessage("Fetching choices...");
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<ChoicesAreaAndRelatedModelDTO>(Server.Api.Choices.GetChoicesAreaAndRelated + "/" + propertyId + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.createViewModels();
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatusErrorMessage("Failed to retrieve the choices.");
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatusErrorMessage("Failed to retrieve the choices.");
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
            this.setIsLoading(false);
        }
    };

    public addToOrder = async (): Promise<void> => {
        if (this.canAddToOrder) {
            this.setApiStatusLoadingMessage("Saving choices...");
            this.setApiStatus(ApiStatus.loading);

            try {
                let model = this.model.toDto();

                const apiResult = await this.Post<ChoicesAreaAndRelatedModelDTO>(Server.Api.Choices.AddToOrder, model);

                if (apiResult.wasSuccessful) {
                    //this.model.fromDto(apiResult.payload);
                    this.navigateToChoices(apiResult.payload.area.propertyId);
                    this.setApiStatus(ApiStatus.success);
                } else {
                    // Error
                    this.setApiStatusErrorMessage(apiResult.errors[0].message);
                    this.setApiStatus(ApiStatus.error);
                }
            } catch (exception) {
                // Exceptional error
                this.setApiStatusErrorMessage("Failed to save the choices.");
                this.setApiStatus(ApiStatus.error);
            } finally {
                // Finally
                this.setIsLoading(false);
            }
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}

export interface ChoicesAreaAndRelatedModelDTO {
    area: ChoicesAreaModelDTO;
    productGroups: ProductGroupModelDTO[];
    products: ProductModelDTO[];
    productVariants: ProductVariantModelDTO[];
    specificationHouseTypeProducts: SpecificationHouseTypeProductModelDTO[];
    specificationProducts: SpecificationProductModelDTO[];
    productsSummary: ProductSummaryModelDTO[];
    isChoicesLocked: boolean;
}

export interface UpsertOrderModelDTO {
    order: OrderModelDTO;
    orderArea: OrderAreaModelDTO;
    orderAreaProductGroups: OrderAreaProductGroupModelDTO[];
    orderAreaProductGroupProducts: OrderAreaProductGroupProductModelDTO[];
}
