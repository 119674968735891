// Libraries
import { action, computed, observable } from "mobx";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { ForgotPasswordModel } from "Custom/Models/ForgotPasswordModel";
import { Server } from "Custom/Globals/AppUrls";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    constructor() {
        super(new ForgotPasswordModel());
        this.setDecorators(ForgotPasswordModel);
    }

    // #region Server Properties and Actions

    @observable
    public haveSubmittedToServer = false;

    @action
    public setHaveSubmittedToServer = (value: boolean) => {
        this.haveSubmittedToServer = value;
    };

    @observable
    public serverValidationMessage = "";

    @action
    public setServerValidationMessage = (value: string) => {
        this.serverValidationMessage = value;
    };

    @action
    public forgotPassword = async (): Promise<void> => {
        try {
            if (this.isModelValid()) {
                this.setServerValidationMessage("");
                this.setHaveSubmittedToServer(true);

                const apiResult = await this.Post(Server.Api.Account.ForgotPassword, this.model);
                if (apiResult.wasSuccessful) {
                    // Nothing to do.
                } else {
                    if (apiResult.errors.length > 0) {
                        const error = apiResult.errors[0];

                        if (!isEmptyOrWhitespace(error.message)) {
                            this.setServerValidationMessage(error.message);
                        } else {
                            this.setServerValidationMessage("There was an unknown error trying to allow you to change the password.");
                        }
                    }
                }
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error trying to allow you to change the password.");
        } finally {
            // Finally
        }
    };

    public tryAgain = () => {
        this.setHaveSubmittedToServer(false);
        this.setServerValidationMessage("");
    };

    // #endregion Server Properties and Actions

    // #region Can Display Properties

    @computed
    public get canDisplayBusy(): boolean {
        return this.IsLoading;
    }

    @computed
    public get canDisplayError(): boolean {
        return this.haveSubmittedToServer && !this.IsLoading && !isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplaySuccess(): boolean {
        return this.haveSubmittedToServer && !this.IsLoading && isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplayForm(): boolean {
        return !this.haveSubmittedToServer && !this.IsLoading;
    }

    // #endregion Can Display Properties

    // #region Navigation Actions

    public navigateToForgotPassword = () => {
        this.history.push("/forgotpassword");
    };

    public navigateToLogin = () => {
        this.history.push("/login");
    };

    // #endregion Navigation Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: "emailAddress", value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #endregion Boilerplate
}
