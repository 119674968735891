import { Button, colors, createMuiTheme, darken, Theme, ThemeOptions } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Palette } from "@material-ui/core/styles/createPalette";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { coreTheme } from "./../../Core/Components/Styles/CoreStyling";
import { withProps } from "Core/Utils/React/WithProps";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

// #region MuiPicker
type overridesNameToClassKey = {
    [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
    MuiPickersBasePicker: {
        pickerView: {
            maxWidth?: string;
        };
    };
};

declare module "@material-ui/core/styles/overrides" {
    interface ComponentNameToClassKey extends overridesNameToClassKey {}
    export interface ComponentNameToClassKey extends CustomType {}
}
// #endregion MuiPicker

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
export const customBreakpoints = createBreakpoints({ values: customBreakpointValues });

//Add your specific app styling here
declare module "@material-ui/core" {
    export interface Theme {
        app: {
            colors: {
                success: {
                    dark: string;
                    light: string;
                };
            };
        };
    }
}

const getTheme = () => {
    const theme = createMuiTheme(coreTheme as ThemeOptions);

    // Typography.
    const fontFamily = ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",");

    theme.typography.fontFamily = fontFamily;

    theme.typography.caption = {
        ...theme.typography.caption,
        fontFamily: fontFamily,
    };
    theme.typography.body1 = {
        ...theme.typography.body1,
        fontFamily: fontFamily,
    };
    theme.typography.body2 = {
        ...theme.typography.body2,
        fontFamily: fontFamily,
    };
    theme.typography.button = {
        ...theme.typography.button,
        fontFamily: fontFamily,
    };
    theme.typography.h1 = {
        ...theme.typography.h1,
        fontFamily: fontFamily,
    };
    theme.typography.h2 = {
        ...theme.typography.h2,
        fontFamily: fontFamily,
    };
    theme.typography.h3 = {
        ...theme.typography.h3,
        fontFamily: fontFamily,
    };
    theme.typography.h4 = {
        ...theme.typography.h4,
        fontFamily: fontFamily,
    };
    theme.typography.h5 = {
        ...theme.typography.h5,
        fontFamily: fontFamily,
    };
    theme.typography.h6 = {
        ...theme.typography.h6,
        fontFamily: fontFamily,
    };
    theme.typography.overline = {
        ...theme.typography.overline,
        fontFamily: fontFamily,
    };
    theme.typography.subtitle1 = {
        ...theme.typography.subtitle1,
        fontFamily: fontFamily,
    };
    theme.typography.subtitle2 = {
        ...theme.typography.subtitle2,
        fontFamily: fontFamily,
    };

    // Overrides
    theme.overrides = {
        ...theme.overrides,
        MuiFormHelperText: {
            root: {
                fontSize: "12px",
            },
            contained: {
                marginLeft: "0",
                marginRight: "0",
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: "white",
                borderRadius: "none",
            },
            input: {
                fontSize: "12px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "7px",
                paddingTop: "7px",
            },
            inputMarginDense: {
                paddingBottom: "8px",
                paddingTop: "8px",
            },
            notchedOutline: {
                borderColor: "#CED4DA",
            },
        },
        MuiInputLabel: {
            formControl: {
                color: "black !important",
                fontSize: "12px",
                fontWeight: "bold",
                padding: "0 0 8px 0",
                position: "initial",
                transform: "translate(0, 0) scale(1)",
                "&$shrink": {
                    transform: "translate(0, 0) scale(1)",
                },
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(0, 0) scale(1)",
                },
            },
        },
        MuiIconButton: {
            edgeEnd: {
                marginRight: "-20px",
            },
        },
        MuiAccordion: {
            root: {
                border: "none",
                boxShadow: "none",
                "&:not(:last-child)": {
                    borderBottom: 0,
                },
                "&:before": {
                    display: "none",
                },
                "&$expanded": {
                    margin: "auto",
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                borderBottom: "1px dashed #ced4da",
                minHeight: 55,
                padding: "0",
                "&$expanded": {
                    borderBottom: "none",
                    minHeight: 55,
                },
            },
            content: {
                margin: "0",
                padding: "18px 18px 15px 18px",
                "&$expanded": {
                    margin: "0",
                },
                "& > p": {
                    fontSize: "16px",
                    fontWeight: "bold",
                },
            },
            expanded: {},
            expandIcon: {
                marginRight: "0px",
                padding: "8px 12px",
                "& svg": {
                    fill: "#191919",
                    fontSize: "40px",
                },
            },
        },
        MuiCollapse: {
            container: {
                backgroundColor: "#F9F9F9",
                borderBottom: "1px solid #ced4da",
                boxShadow: `inset 0px -5px 10px -5px ${darken("#ced4da", 0.2)}, inset 0 5px 10px -5px ${darken("#ced4da", 0.2)}`,
                [theme.breakpoints.up("md")]: {
                    boxShadow: "none",
                    margin: "0 18px",
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: "18px",
                [theme.breakpoints.up("md")]: {
                    padding: "18px 0",
                },
            },
        },
        MuiPickersBasePicker: {
            container: {
                "& > div.MuiPickersDatePickerRoot-toolbar": {
                    alignItems: "center",
                    flexDirection: "row",
                    height: "auto",
                    justifyContent: "space-evenly",
                    "& > button > span > h6": {
                        fontSize: "16px",
                    },
                    "& > button > span > h4": {
                        fontSize: "16px",
                    },
                },
            },
            pickerView: {
                "& > div > div.MuiPickersYear-yearDisabled": {
                    color: "#aaa",
                },
                "& > div > div.MuiPickersMonth-monthDisabled": {
                    color: "#aaa",
                },
            },
        },
    };

    return theme;
};

const appTheme = {
    app: {
        colors: {
            success: {
                dark: colors.green[600],
                light: colors.green[300],
            },
        },
    },
};

//Overrides
let theme = getTheme();

theme.breakpoints = customBreakpoints;

theme.palette = {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: { light: "#7986cb", main: "#3C3C3B", dark: "#303f9f", contrastText: "#fff" },
    secondary: { light: "#ff4081", main: "#f50057", dark: "#c51162", contrastText: "#fff" },
    error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
    text: {
        primary: "rgba(0,0,0, 1)",
        secondary: "rgba(255,255,255, 0.54)",
        disabled: "rgba(255,255,255, 0.38)",
        hint: "rgba(255,255,255, 0.38)",
    },
} as Palette;

theme = { ...getTheme(), ...appTheme };

export const defaultTheme = theme;

export const HeavyHeader = styled(Typography)`
    font-family: "HelveticaNeue-Heavy";
    letter-spacing: -2.76px;
    line-height: 100%;
`;

const HeavyInputLabel = styled(HeavyHeader)`
    letter-spacing: -0.35px;
`;

export const FormInputLabel = withProps(HeavyInputLabel)({
    variant: "body1",
});

const OvalBtn = styled(Button)`
    border-radius: 1000px;
    text-transform: none;
    padding: 10px 54px;
    margin: 0 auto;
    pointer-events: all;
    line-height: 1.3em;

    span {
        font-weight: bold !important;
    }
`;

export const FormBtn = withProps(OvalBtn)({
    variant: "contained",
    color: "primary",
});
