// Libraries
import { action, computed, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { Server } from "Custom/Globals/AppUrls";

// App
import { ProductViewModel } from "./ProductViewModel";
import { ProductGroupModel } from "Custom/Models/DashboardModels/ProductGroupModel";
import { Format } from "Custom/Utils";

export class ProductGroupViewModel extends ViewModelBase<ProductGroupModel> {
    @observable
    private productViewModels: ProductViewModel[] = [];

    @observable
    private choicesCheckCounter: number = 0;

    @observable
    private isChoicesLocked: boolean = false;

    constructor(productGroup: ProductGroupModel) {
        super(productGroup);
        this.setDecorators(ProductGroupModel);

        this.createViewModels();
    }

    @computed
    public get products() {
        const retVal = this.productViewModels.filter(p => p.isOption === true);
        return retVal;
    }

    @computed
    public get extras() {
        return this.productViewModels.filter(p => p.isExtra === true);
    }

    @computed
    public get sortedProducts() {
        // Order:
        // - Included
        // - Figure (Lowest to highest)
        // - POA

        const arr = this.products.sort((a, b) => {
            // Re-renders cause the list to sort again, so ensure the "None of the above" option as at the bottom.
            if (a.model.productId === "00000000-0000-0000-0000-000000000000") {
                return 1;
            }

            // Get the prices that are displayed to the user.
            let aProductPrice = a.model.price;
            let bProductPrice = b.model.price;

            if (aProductPrice !== undefined && aProductPrice !== "£POA" && aProductPrice.toUpperCase() !== "INCLUDED") {
                aProductPrice = Format.currencyFormatForUpsert(aProductPrice, true);
            }

            if (bProductPrice !== undefined && bProductPrice !== "£POA" && bProductPrice.toUpperCase() !== "INCLUDED") {
                bProductPrice = Format.currencyFormatForUpsert(bProductPrice, true);
            }

            if (aProductPrice === null) {
                return 1;
            }

            if (bProductPrice === null) {
                return -1;
            }

            // If the first item is included and the second isn't, move the first item up.
            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() === "INCLUDED" && bProductPrice.toUpperCase() !== "INCLUDED") {
                return -1;
            }

            // If the first item isn't included and the second is, move the first item down.
            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() !== "INCLUDED" && bProductPrice.toUpperCase() === "INCLUDED") {
                return 1;
            }

            // Compare non POA prices
            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() !== "£POA" && bProductPrice.toUpperCase() !== "£POA") {
                // If the first item has a lower price than the second, move the first item up.
                if (Number(aProductPrice) < Number(bProductPrice)) {
                    return -1;
                }

                // If the first item has a higher price than the second, move the first item down.
                if (Number(aProductPrice) > Number(bProductPrice)) {
                    return 1;
                }
            }

            // Compare price if POA
            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() !== "£POA" && bProductPrice.toUpperCase() === "£POA") {
                return -1;
            }

            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() === "£POA" && bProductPrice.toUpperCase() !== "£POA") {
                return 1;
            }

            return -1;
        });

        return arr;
    }

    @computed
    public get sortedExtras() {
        // Order:
        // - Included
        // - Figure (Lowest to highest)
        // - POA

        const arr = this.extras.sort((a, b) => {
            // Get the prices that are displayed to the user.
            let aProductPrice = a.model.extraPrice;
            let bProductPrice = b.model.extraPrice;

            if (aProductPrice !== undefined && aProductPrice !== "£POA") {
                aProductPrice = Format.currencyFormatForUpsert(aProductPrice, true);
            }

            if (bProductPrice !== undefined && bProductPrice !== "£POA") {
                bProductPrice = Format.currencyFormatForUpsert(bProductPrice, true);
            }

            if (aProductPrice === null) {
                return 1;
            }

            if (bProductPrice === null) {
                return -1;
            }

            // Compare non POA prices
            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() !== "£POA" && bProductPrice.toUpperCase() !== "£POA") {
                // If the first item has a lower price than the second, move the first item up.
                if (Number(aProductPrice) < Number(bProductPrice)) {
                    return -1;
                }

                // If the first item has a higher price than the second, move the first item down.
                if (Number(aProductPrice) > Number(bProductPrice)) {
                    return 1;
                }
            }

            // Compare price if POA
            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() !== "£POA" && bProductPrice.toUpperCase() === "£POA") {
                return -1;
            }

            if (aProductPrice !== undefined && bProductPrice !== undefined && aProductPrice.toUpperCase() === "£POA" && bProductPrice.toUpperCase() !== "£POA") {
                return 1;
            }

            return -1;
        });

        return arr;
    }

    @computed
    public get numberOfExtras() {
        return this.products.filter(p => p.isExtra === true && p.model.isSelected === true).length;
    }

    @computed
    public get isChoiceSelected() {
        let choiceSelected = this.products.findIndex(p => p.isOption === true && p.model.isSelected === true) !== -1 || this.model.isNoneOfTheAbove;

        return choiceSelected;
    }

    @computed
    public get hasOptions() {
        return this.productViewModels.filter(p => p.isOption === true).length > 0;
    }

    @computed
    public get hasExtras() {
        return this.productViewModels.filter(p => p.isExtra === true).length > 0;
    }

    @computed
    public get hasChoices() {
        return this.hasOptions && this.hasExtras;
    }

    @action
    public async isChoicesLockedAsync(orderId: string): Promise<boolean | undefined> {
        //this function checks once when a user selects option if the choices sections is locked
        //this is done in order to prevent the user from making multiple time consuming selection just to realize that the task can't be finalized
        // only allow one check to minimize server requests

        if (this.choicesCheckCounter == 0) {
            try {
                const apiResult = await this.Get<boolean>(Server.Api.Choices.GetIsChoicesLocked + "/" + orderId);

                if (apiResult.wasSuccessful) {
                    // this.setApiStatus(ApiStatus.success);
                    this.choicesCheckCounter = 1; // only change value on success
                    this.isChoicesLocked = apiResult.payload;
                    return this.isChoicesLocked;
                } else {
                    // this.setApiStatusErrorMessage("Failed to retrieve the choices.");
                    // this.setApiStatus(ApiStatus.error);
                }
            } catch (exception) {
                // this.setApiStatusErrorMessage("Failed to retrieve the choices.");
                // this.setApiStatus(ApiStatus.error);
            } finally {
                // Finally
                this.setIsLoading(false);
            }
        } else {
            //if the check is already been done return value.
            return this.isChoicesLocked;
        }
    }

    @action
    public setNoneOfTheAbove(val: boolean = true) {
        this.model.isNoneOfTheAbove = val;

        for (const product of this.productViewModels.filter(p => p.isOption === true)) {
            product.setIsSelected(false);

            for (const productVariant of product.productVariantViewModels) {
                productVariant.setIsSelected(false);
            }
        }
    }

    /**
     * Sets whether a product is selected.
     * @param productSelected
     */
    @action
    public setProductIsSelected(productSelected: ProductViewModel) {
        // this.isChoicesLockedAsync(productSelected.OrderId).then(response => {
        //     if (!response) {

        //     }
        // });
        if (productSelected.hasVariants === false) {
            this.setNoneOfTheAbove(false);

            for (const product of this.productViewModels.filter(p => p.isOption === true)) {
                if (product.model.id === productSelected.model.id) {
                    product.setIsSelected(true);
                } else {
                    product.setIsSelected(false);
                }
            }
        }
    }

    /**
     * Sets whether a product variant or product variant extra is selected.
     * @param selectedVariantId
     * @param productSelected
     */
    @action
    public selectProductVariant(selectedVariantId: string, productSelected: ProductViewModel) {
        if (productSelected.isOption) {
            this.setSelectedProductVariant(selectedVariantId, productSelected);
        } else if (productSelected.isExtra) {
            this.setSelectedProductVariantExtra(selectedVariantId, productSelected);
        }
    }

    /**
     * Sets whether a product is selected.
     * @param selectedVariantId
     * @param productSelected
     */
    @action
    public setSelectedProductVariant(selectedVariantId: string, productSelected: ProductViewModel) {
        this.setNoneOfTheAbove(false);

        for (const product of this.productViewModels.filter(p => p.isOption === true)) {
            if (product.model.id === productSelected.model.id) {
                product.setIsSelected(true);
            } else {
                product.setIsSelected(false);
            }

            for (const productVariant of product.productVariantViewModels) {
                if (productVariant.model.id === selectedVariantId) {
                    productVariant.setIsSelected(true);
                } else {
                    productVariant.setIsSelected(false);
                }
            }
        }
    }

    /**
     * Sets whether a product extra is selected.
     * @param productSelected
     * @param isSelected
     */
    @action
    public setProductExtraIsSelected(productSelected: ProductViewModel, isSelected: boolean) {
        if (isSelected) {
            if (productSelected.hasVariants === false) {
                productSelected.setIsSelected(isSelected);
            }
        } else {
            productSelected.setIsSelected(isSelected);
            for (const productVariant of productSelected.productVariantViewModels) {
                productVariant.setIsSelected(isSelected);
            }
        }
    }

    /**
     * Sets whether a product variant extra is selected.
     * @param selectedVariantId
     * @param productSelected
     */
    @action
    public setSelectedProductVariantExtra(selectedVariantId: string, productSelected: ProductViewModel) {
        let selectedVariant = productSelected.productVariantViewModels.find(pv => pv.model.id === selectedVariantId);

        productSelected.setIsSelected(true);

        if (selectedVariant) {
            selectedVariant.setIsSelected(true);

            for (const productVariant of productSelected.productVariantViewModels) {
                if (productVariant.model.id === selectedVariantId) {
                    productVariant.setIsSelected(true);
                } else {
                    productVariant.setIsSelected(false);
                }
            }
        }
    }

    @action
    private createViewModels() {
        for (const product of this.model.products) {
            this.productViewModels.push(new ProductViewModel(product));
        }
    }

    // #region Can Display Properties

    @observable
    public canDisplay = false;

    @action
    public setCanDisplay = (value: boolean): void => {
        this.canDisplay = value;
    };

    // #endregion Can Display Properties

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
