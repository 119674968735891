import "url-search-params-polyfill";
import { getPath } from "ts-object-path";
import { History, LocationState } from "history";
import { StoresInstance } from "../../Custom/Stores";
import { AccountStatus, LoginComplete } from "../Models";
import { GlobalHistory } from "Core/CoreIndex";

export type FieldType<T = any> = Omit<
    T,
    | "Errors"
    | "Dirty"
    | "Touched"
    | "Valid"
    | "getError"
    | "setError"
    | "getDirty"
    | "setDirty"
    | "getTouched"
    | "setTouched"
    | "getValid"
    | "setValid"
    | "getValue"
    | "setValue"
    | "fromDto"
    | "toDto"
>;

export const nameofFactory = <T>() => (name: keyof T) => name;

function _isNil(value: any) {
    return value == null;
}

export const getHistory = (): History<LocationState> => {
    return GlobalHistory;
};

export const setLoginState = (apiResult: LoginComplete) => {
    StoresInstance.domain.AccountStore.setLoginState(apiResult);
};

// export const getJWT = () => {
// 	return StoresInstance.domain.AccountStore.getJwt();
// };

export const getParentObjectPath = (fieldName: string, action: "Errors" | "Valid" | "Dirty" | "Touched"): string[] => {
    let path: string[] = [];
    if (typeof fieldName === "string") {
        path = fieldName.split(".");
    } else {
        path = getPath(fieldName) as string[];
        // (fieldName as any).forEach((key: any) => {
        // 	path.push(key);
        // });
    }
    path.splice(path.length - 1, 0, action);
    return path;
};

export const generateID = function() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return (
        "_" +
        Math.random()
            .toString(36)
            .substr(2, 9)
    );
};

/**
 * Test if a string is null, undefined, or empty.
 *
 * @param {string | undefined | null} text
 *
 * @returns true if null, undefined or empty, otherwise false.
 */
export const isNullOrEmpty = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text.length === 0;
};

/**
 * Test if a string is null, undefined, empty, or whitespace.
 *
 * @param {string | undefined | null} text
 *
 * @returns true if null, undefined, empty, or whitespace otherwise false.
 */
export const isEmptyOrWhitespace = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text.trim().length < 1;
};

export const sortByString = (a: string | undefined, b: string | undefined, options?: Intl.CollatorOptions) => {
    if (a === undefined && b === undefined) {
        return 0;
    }
    if (a === undefined) {
        return -1;
    }
    if (b === undefined) {
        return 1;
    }

    return a.localeCompare(b, undefined, options);
};

export const coalesce = <TArg>(...args: (TArg | undefined)[]) => {
    for (const arg of args) {
        if (_isNil(arg) === false) {
            return arg;
        }
    }

    return null;
};
export const getApiUrl = (): string => (window as any).apiurl;

export const getImageUrl = (imageUrl: string): string => {
    return getApiUrl() + imageUrl;
};

export const getBaseUrl = (): string | undefined => {
    const baseElements = document.getElementsByTagName("base");

    if (baseElements.length === 0) {
        throw new Error("Base element not found");
    }

    if (baseElements.length > 1) {
        throw new Error("Multiple base elements found");
    }

    const baseElement = baseElements[0];
    const baseUrl = baseElement.getAttribute("href");

    if (baseUrl === undefined) {
        throw new Error("Base element 'href' attribute not found.");
    }

    let retVal: string | undefined;
    if (baseUrl !== null) {
        retVal = baseUrl;
    }
    return retVal;
};

export const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    let retval = "";
    try {
        const base64 = decodeURIComponent(
            atob(base64Url)
                .split("")
                .map(c => {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(""),
        );
        retval = JSON.parse(base64);
    } catch {}

    return retval;
};

export const getCookie: (cname: string) => string = (cname: string): string => {
    var name: string = cname + "=";
    var decodedCookie: string = decodeURIComponent(document.cookie);
    var ca: string[] = decodedCookie.split(";");
    for (var i: number = 0; i < ca.length; i++) {
        var c: string = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const getUrlSearchParams: () => URLSearchParams = () => {
    return new URLSearchParams(window.location.search);
};
