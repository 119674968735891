import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { ChoicesPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/ChoicesPageStyling";
import { ChoicesSummaryViewModel } from "Custom/ViewModels/DashboardSubViewModels/ChoicesSummaryViewModel";
import EditIcon from "Custom/Content/Images/editBlue.svg";
import { useRouter } from "Core/Utils";
import { ChoicesSummaryModel } from "Custom/Models/DashboardModels/ChoicesSummaryModel";
import { ChoicesProductGroupStyles } from "Custom/StylesAppSpecific/DashboardSubViews/ChoicesProductGroupStyling";
import { Format } from "Custom/Utils";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

interface IProps {
    propertyId: string;
    canEditChoices?: boolean;
}

export const ChoicesSummary: React.FC<IProps> = props => {
    // #region Code Behind

    const { match } = useRouter();
    //const { propertyId } = match.params as any;
    const propertyId = props.propertyId; //match.params as any;

    const choicesPageStyles = ChoicesPageStyles();
    const choicesProductGroupStyles = ChoicesProductGroupStyles();

    const [viewModel] = useState(() => new ChoicesSummaryViewModel(propertyId));

    const onChoicesAreaClick = (areaId: string) => {
        viewModel.navigateToChoicesArea(propertyId, areaId);
    };
    // #endregion Code Behind

    const renderTitleRow = () => (
        <Box className={choicesPageStyles.choicesAreasMainHeadingBlock}>
            <Typography className={choicesPageStyles.choicesAreasMainSummaryTitle}>Choices selected so far...</Typography>
            <div className={choicesPageStyles.choicesAreasSummarySubtitle}>
                <Grid className={choicesPageStyles.choicesAreasSummarySubTotal} item>
                    <div>Order value:</div>
                    <div>{viewModel.orderValue ? "£" + viewModel.orderValue : "-"}</div>
                </Grid>
                <Grid className={choicesPageStyles.choicesAreasSummarySubTotal} item>
                    <div>Outstanding balance:</div>
                    <div>{viewModel.outstandingBalanceTotal ? "£" + viewModel.outstandingBalanceTotal : "-"}</div>
                </Grid>
            </div>
        </Box>
    );

    const renderViewEditChoicesButton = () => {
        if (props.canEditChoices !== undefined && props.canEditChoices === false) {
            return <span style={{ textDecoration: "underline", color: "#556ed1" }}>View choices</span>;
        } else {
            /* return <img src={EditIcon} className={choicesPageStyles.editIcon} alt="Edit choice." />; */
            return <PrivateBlob src={EditIcon} className={choicesPageStyles.editIcon} alt="Edit choice." />;
        }
    };

    const renderChoicesSummary = () => (
        <Box>
            {viewModel.areas.map((choicesArea, index) => (
                <Card className={choicesPageStyles.choicesAreaSummary} key={index}>
                    <CardContent>
                        <Box className={choicesPageStyles.choicesAreaSummaryTitleContainer}>
                            <Typography className={choicesPageStyles.choicesAreaSummaryTitle}>{choicesArea.model.areaName}</Typography>
                            <CardActionArea onClick={() => onChoicesAreaClick(choicesArea.model.areaId)}>{renderViewEditChoicesButton()}</CardActionArea>
                        </Box>
                        <Box className={choicesPageStyles.choicesAreaSummaryOptionsContainer}>
                            <Typography className={choicesPageStyles.choicesAreaSummarySubTitle}>Options you selected:</Typography>

                            {choicesArea.productGroupsSummary.map((productGroup, index) => (
                                <Box key={index}>
                                    {productGroup.options.map((product, prodIndex) => (
                                        <Box className={choicesPageStyles.productSummaryContainer} key={`${prodIndex}-${index}`}>
                                            <Box>{product.productTitle}</Box>
                                            {product.productQuantity && (
                                                <Box>
                                                    <span className={choicesProductGroupStyles.productTitle}>Quanity:</span> {product.productQuantity}
                                                </Box>
                                            )}
                                            <Box>{product.cost}</Box>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        <Box className={choicesPageStyles.choicesAreaSummaryExtrasContainer}>
                            <Typography className={choicesPageStyles.choicesAreaSummarySubTitle}>Extras, you selected:</Typography>

                            {choicesArea.productGroupsSummary.map((productGroup, index) => (
                                <Box key={index}>
                                    {productGroup.extras.map((product, prodIndex) => (
                                        <Box className={choicesPageStyles.productSummaryContainer} key={`${prodIndex}-${index}`}>
                                            <Box>{product.productTitle}</Box>
                                            <Box>{product.cost}</Box>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Box>

                        {viewModel.productBespoke &&
                            viewModel.productBespoke.map(
                                (bespoke, index) =>
                                    bespoke.areaId === choicesArea.model.areaId && (
                                        <Box className={choicesPageStyles.choicesAreaSummaryExtrasContainer}>
                                            {!viewModel.areaId.includes(bespoke.areaId) && (
                                                <Typography className={choicesPageStyles.choicesAreaSummarySubTitle}>Bespoke:</Typography>
                                            )}

                                            <Box className={choicesPageStyles.productSummaryContainer} key={index}>
                                                <Box>{`${bespoke.name} - ${bespoke.notes}`}</Box>
                                                <Box>{bespoke.poa ? "POA" : bespoke.isPaid ? "Paid" : `£${Format.currencyFormat(bespoke.cost)}`}</Box>
                                            </Box>
                                            {viewModel.addAreaId(bespoke.areaId)}
                                        </Box>
                                    ),
                            )}
                    </CardContent>
                </Card>
            ))}
        </Box>
    );

    return useObserver(() => (
        <Box>
            {viewModel.hasSelectedChoices && renderTitleRow()}
            <Box className={choicesPageStyles.choicesAreasSummary}>{renderChoicesSummary()}</Box>
        </Box>
    ));
};
