import { Box, FormControlLabel, MenuItem, Radio, Checkbox, Select, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

import { ChoicesProductGroupStyles, GridContainerCounter } from "Custom/StylesAppSpecific/DashboardSubViews/ChoicesProductGroupStyling";
import { ProductViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProductViewModel";
import { ProductGroupViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProductGroupViewModel";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { CheckedIcon } from "../../Content/Components/CheckedIcon";
import Lightbox from "Custom/Components/Popups/Lightbox";
import { action } from "mobx";
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import { ProductSummaryModel } from "Custom/Models/DashboardModels/ProductSummary";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

interface IProps {
    viewModel: ProductViewModel;
    productGroupViewModel: ProductGroupViewModel;
    index: number;
    isExtra: boolean;
    isDisabled: boolean;
}

export const ChoicesProduct: React.FC<IProps> = props => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;
    const [productImage, setProductImage] = useState("");
    const [isProductImgOpen, setIsProductImageOpen] = useState(false);
    const [productVariantErrorMessage, setProductVariantErrorMessage] = useState("");
    const choicesProductGroupStyles = ChoicesProductGroupStyles();

    const inputCSSClassNames = (defaultCSS: string) => {
        let CSSRetVal = defaultCSS;

        if (props.isDisabled) {
            CSSRetVal += " choices-product__input--disabled";
        }

        return CSSRetVal;
    };

    const handleProductIsSelectOption = () => {
        setProductVariantErrorMessage("");
        if (!props.isDisabled) {
            if (props.viewModel.hasVariants) {
                setProductVariantErrorMessage("Please select a variant");
            }
            props.productGroupViewModel.setProductIsSelected(props.viewModel);
        }
    };

    const handleProductIsSelectExtra = () => {
        setProductVariantErrorMessage("");
        if (!props.isDisabled) {
            if (props.viewModel.hasVariants && !props.viewModel.model.isSelected) {
                setProductVariantErrorMessage("Please select a variant");
            }
            props.productGroupViewModel.setProductExtraIsSelected(props.viewModel, !props.viewModel.model.isSelected);
        }
    };

    const handleSelectProductVariant = (val: string) => {
        setProductVariantErrorMessage("");
        if (!props.isDisabled) {
            props.productGroupViewModel.selectProductVariant(val, props.viewModel);
        }
    };

    const productSummary = props.viewModel.model.productsSummary.find(p => p.productId === props.viewModel.model.productId);
    return useObserver(() => (
        <Box className={choicesProductGroupStyles.productContainer}>
            <Box className={choicesProductGroupStyles.productContentRow}>
                <Box className={choicesProductGroupStyles.productImageContainer}>
                    {/* <img
                        alt={props.viewModel.model.productName}
                        src={props.viewModel.productImage}
                        onClick={() => {
                            if (props.viewModel.productOriginalImage) {
                                setProductImage(props.viewModel.productOriginalImage);
                                setIsProductImageOpen(true);
                            }
                        }}
                    /> */}
                    <PrivateBlob
                        alt={props.viewModel.model.productName}
                        src={props.viewModel.productImage}
                        onClick={() => {
                            if (props.viewModel.productOriginalImage) {
                                setProductImage(props.viewModel.productOriginalImage);
                                setIsProductImageOpen(true);
                            }
                        }}
                    />
                </Box>
                <Box className={choicesProductGroupStyles.productContentContainer}>
                    <Box>
                        <Typography className={choicesProductGroupStyles.productTitle} variant="body2">
                            {props.viewModel.productTitle}
                        </Typography>
                        <Typography className={choicesProductGroupStyles.productDescription} variant="body2">
                            {props.viewModel.model.productDescription}
                        </Typography>
                    </Box>
                    {props.viewModel.model.isQuantityAllowed && (
                        <GridContainerCounter>
                            <label className={choicesProductGroupStyles.counterLabel}>Quantity:</label>
                            <EditableInput
                                className={choicesProductGroupStyles.counterInput}
                                type="number"
                                fieldName="orderQuantity"
                                viewModel={props.viewModel}
                                onChange={props.viewModel.model.onQuantityChange}
                                inputProps={{
                                    id: "orderQuantity-input",
                                }}
                            />
                        </GridContainerCounter>
                    )}
                    {props.viewModel.hasVariants && (
                        <Box className={choicesProductGroupStyles.productVariantsContainer} key={`product-variants-list ${props.index}`}>
                            <Select
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                                variant="outlined"
                                onChange={e => handleSelectProductVariant(e.target.value as string)}
                                placeholder={!props.isDisabled ? "Please select variant..." : ""}
                                className={choicesProductGroupStyles.productVariantSelect}
                                value={props.viewModel.getSelectedVariant}
                            >
                                {props.viewModel.productVariantsOptions!.map((item: KeyValuePair, index: number) => {
                                    return (
                                        <MenuItem disabled={item.value === ""} key={item.value} value={item.value}>
                                            {item.key}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {productVariantErrorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {productVariantErrorMessage}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {console.log("props.viewModel.model.price", props.viewModel.model.price)}
            <Box>
                {props.viewModel.isOption && (
                    <FormControlLabel
                        control={<Radio className={choicesProductGroupStyles.radioButton} />}
                        checked={props.viewModel.model.isSelected}
                        label={
                            <Typography variant="body2">
                                {props.viewModel.model.isQuantityAllowed && props.viewModel.model.getPriceOnAddQuantity()
                                    ? props.viewModel.model.getPriceOnAddQuantity()
                                    : props.viewModel.model.price}
                            </Typography>
                        }
                        labelPlacement={isDesktop ? "start" : "end"}
                        name={`${props.productGroupViewModel.model.productGroupName + props.index}`}
                        value={props.viewModel.model.productId}
                        onChange={() => handleProductIsSelectOption()}
                        className={inputCSSClassNames(props.viewModel.model.isSelected ? choicesProductGroupStyles.productRadioChecked : choicesProductGroupStyles.productRadio)}
                    />
                )}

                {props.viewModel.isExtra && (
                    <FormControlLabel
                        control={<Checkbox className={choicesProductGroupStyles.checkboxButton} />}
                        checked={props.viewModel.model.isSelected}
                        label={<Typography variant="body2">{props.viewModel.model.extraPrice}</Typography>}
                        labelPlacement={isDesktop ? "start" : "end"}
                        name={`${props.productGroupViewModel.model.productGroupName + props.index}-extra`}
                        value={props.viewModel.model.productId}
                        onChange={e => handleProductIsSelectExtra()}
                        className={inputCSSClassNames(
                            props.viewModel.model.isSelected ? choicesProductGroupStyles.productCheckboxChecked : choicesProductGroupStyles.productCheckbox,
                        )}
                    />
                )}
            </Box>
            <Lightbox isOpen={isProductImgOpen} imageUrl={productImage} onClose={setIsProductImageOpen} />
        </Box>
    ));
};

export default ChoicesProduct;
