// Libraries
import { action, computed, observable, runInAction } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { PropertyDrawingDocumentsModel } from "Custom/Models/DashboardModels/PropertyDrawingDocumentsModel";
import { PropertyDrawingDocumentsModelDTO } from "Custom/Models/DashboardModels/PropertyDrawingDocumentsModel";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import axios from "axios";
import { contentTypeHelper } from "Custom/Utils/Format/contentTypeHelper";

export class PropertyDrawingDocumentsViewModel extends ViewModelBase<PropertyDrawingDocumentsModel> {
    constructor(propertyId: string) {
        super(new PropertyDrawingDocumentsModel());
        this.setDecorators(PropertyDrawingDocumentsModel);

        //console.log("DOCUMENTSVM CONSTRUCTOR: PropertyId", propertyId);
        // this.developmentId = developmentId;
        this.propertyId = propertyId;

        if (propertyId !== null && propertyId !== undefined && propertyId !== "") {
            this.load(propertyId);
        }
    }

    // #region Properties

    @observable
    public developmentId: string = "";

    @observable
    public propertyId: string = "";

    @observable
    public apiStatus: ApiStatus = ApiStatus.loading;

    // #endregion Properties

    // #region Computeds

    @computed
    public get Documents() {
        console.table(this.model.documents);
        return this.model.documents;
    }

    @computed
    public get DocumentsLength() {
        return `${this.model.documents.length} docs uploaded`;
    }

    @computed
    public get ApiStatus(): ApiStatus {
        return this.apiStatus;
    }

    // @observable
    // public canDisplay = false;

    // @action
    // public setCanDisplay = (value: boolean): void => {
    //     this.canDisplay = value;
    // };

    // #endregion Computeds

    // #region Actions

    @action
    public setApiStatus(apiStatus: ApiStatus) {
        this.apiStatus = apiStatus;
    }

    public navigateToDashboard = () => {
        this.history.push(`/${this.propertyId}`);
    };

    private load = async (id: string): Promise<void> => {
        this.setApiStatus(ApiStatus.loading);

        try {
            const apiResult = await this.Get<PropertyDrawingDocumentsModelDTO>(Server.Api.Dashboard.GetPropertyDrawingDocuments + "/" + id);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.setApiStatus(ApiStatus.success);
            } else {
                this.setApiStatus(ApiStatus.error);
            }
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    public getDocument = async (id: string, fileName: string) => {
        this.setApiStatus(ApiStatus.loading);

        let filenameWithoutType = fileName.substring(0, fileName.lastIndexOf("."));
        let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);

        try {
            //const apiResult = await this.Get<FileDownloadResponse>(Server.Api.Dashboard.GetPropertyDrawingDocument + "/" + id);
            const apiResult = await axios.get(Server.Api.Dashboard.GetPropertyDrawingDocument + "/" + id, {
                responseType: "blob",
                headers: {
                    "Content-Type": contentTypeHelper(fileType),
                },
            });
            runInAction(() => {
                const response = apiResult as any;
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentTypeHelper(fileType) }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filenameWithoutType + fileName.substring(fileName.lastIndexOf(".")));
                document.body.appendChild(link);
                link.click();
                this.setApiStatus(ApiStatus.success);
            });
        } catch (exception) {
            this.setApiStatus(ApiStatus.error);
        } finally {
            // Finally
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
