/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

export const SessionExpired: React.FC = () => {
    return (
        <Typography variant={"h2"} color={"textSecondary"}>
            Your session has expired!
        </Typography>
    );
};
