// Libraries
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

// Custom
import { InspectionStageModel, InspectionStageModelDTO } from "./InspectionStageModel";

export class InspectionModel extends ModelBase<InspectionModel> {
    // #region Properties

    @observable
    public inspectionStages = observable<InspectionStageModel>([]);

    // #endregion Properties

    @action
    public fromDto = (dto: InspectionStageModelDTO[]): void => {
        for (const stage of dto) {
            const inspectionStageModel = new InspectionStageModel();

            inspectionStageModel.fromDto(stage);

            this.inspectionStages.push(inspectionStageModel);
        }
    };

    public toDto = (): InspectionStageModelDTO[] => {
        throw "toDto is not supported";
    };
}
