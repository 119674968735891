// Libraries
import { action, computed, observable } from "mobx";

// Core
import { StoresInstance } from "Core/Base";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { Client, Server } from "Custom/Globals/AppUrls";

export class HeaderViewModel extends ViewModelBase<any> {
    constructor() {
        super({});
    }

    @action
    public logout = async (): Promise<void> => {
        try {
            await this.Post(Server.Api.Account.Logout);

            // If logout is not successful, there isn't much we can do.
        } catch (exception) {
            // If logout is not successful, there isn't much we can do.
        } finally {
            StoresInstance.domain.AccountStore.Logout();
            this.history.push(Client.Path.Account.Login);
        }
    };

    // #region Bolierplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
