import axios, * as Axios from "axios";
import { action, computed, observable } from "mobx";

// App
import { GlobalSetting } from "Custom/Models/GlobalSetting";
import { GlobalSettingType } from "Custom/Models/GlobalSettingType";
import { LoginComplete } from "Core/Models";
import { ApiResult } from "Core/Models/ApiResult";
import { BaseStore } from "Core/Stores/BaseStore";
import { isNullOrUndefined } from "util";

import { Stores } from "../Stores";
import { Server } from "Custom/Globals/AppUrls";
import { InitialState } from "Custom/Models";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { getWeekYearWithOptions } from "date-fns/fp";

export class AccountStore extends BaseStore {
    @observable public Id: string | undefined;
    @observable public DisplayName: string = "";
    @observable public ViewAsCompanyName = "";
    @observable public IsLoggedIn: boolean = false;
    @observable public ViewAsCompanyId = "";
    @observable public UserName: string = "";
    @observable public UserRoles: string[] = [];
    @observable public PropertyId: string | undefined = undefined;
    @observable public CompanyLogo: string = "";
    @observable public IsInTenYearWarranty: boolean = false;
    @observable public GlobalSettings = observable<GlobalSetting>([]);
    @observable public GlobalSettingTypes = observable<GlobalSettingType>([]);
    @observable public StripePublicKey: string = "";

    public permissions = observable<string>([]);

    @computed
    public get hasGlobalPermission() {
        const hasGlobalPermission = this.hasPermission("global");

        return hasGlobalPermission;
    }

    @computed
    public get hasViewAsCompanyId() {
        const viewAsCompanyId = this.ViewAsCompanyId;
        const hasViewAsCompanyId = viewAsCompanyId != null && viewAsCompanyId !== "";

        return hasViewAsCompanyId;
    }

    @computed
    public get isSysAdminViewMode() {
        const hasGlobalPermission = this.hasGlobalPermission;
        const hasViewAsCompanyId = this.hasViewAsCompanyId;
        const isSysAdminViewMode = hasGlobalPermission === true && hasViewAsCompanyId === false;

        return isSysAdminViewMode;
    }

    @computed
    public get isCompanyViewMode() {
        const hasGlobalPermission = this.hasGlobalPermission;
        const hasViewAsCompanyId = this.hasViewAsCompanyId;
        const isCompanyViewMode = hasGlobalPermission === false || hasViewAsCompanyId === true;

        return isCompanyViewMode;
    }

    @action
    public getSettingByType = (settingType: number) => {
        // Find setting type that matches the setting type.
        let globalSettingType = this.GlobalSettingTypes.find(a => a.type === settingType);

        //No settings exist that match this setting type.
        if (globalSettingType === undefined || globalSettingType === null) {
            return undefined;
        }

        // Find the setting with that type.
        let setting = this.GlobalSettings.find(cs => cs.globalSettingTypeId === globalSettingType!.id);

        //Setting does not exist
        if (setting === undefined || setting === null) {
            return undefined;
        }

        return setting;
    };

    public constructor() {
        super();
    }

    public init(stores: Stores, initialState: InitialState) {
        if (initialState !== undefined) {
            if (initialState.accountStatus !== undefined) {
                this.Id = initialState.accountStatus.id;
                this.IsLoggedIn = initialState.accountStatus.isLoggedIn;
                this.DisplayName = initialState.accountStatus.displayName;
                this.ViewAsCompanyName = initialState.accountStatus.viewAsCompanyName;

                if (initialState.accountStatus.permissions) {
                    this.permissions.replace(initialState.accountStatus.permissions);
                }
            }

            this.PropertyId = initialState.propertyId === null ? undefined : initialState.propertyId;
            this.IsInTenYearWarranty = initialState.isInTenYearWarranty;
            this.ViewAsCompanyId = initialState.viewAsCompanyId != null ? initialState.viewAsCompanyId : "";
            this.StripePublicKey = initialState.stripePublicKey;

            if (initialState.globalSettingTypes) {
                this.GlobalSettingTypes.replace(initialState.globalSettingTypes);
            } else {
                this.GlobalSettingTypes.clear();
            }

            if (initialState.globalSettings) {
                this.GlobalSettings.replace(initialState.globalSettings);
            } else {
                this.GlobalSettings.clear();
            }

            if (this.ViewAsCompanyId === "") {
                this.ViewAsCompanyId = initialState.accountStatus.companyViewId != null ? initialState.accountStatus.companyViewId : "";
            }
        }
    }

    @action
    public setLoginState = (apiResult: LoginComplete) => {
        this.Id = apiResult.id;
        this.IsLoggedIn = apiResult.accountStatus.isLoggedIn;
        this.DisplayName = apiResult.accountStatus.displayName;
        this.PropertyId = apiResult.propertyId;
        this.IsInTenYearWarranty = apiResult.isInTenYearWarranty;
        this.GlobalSettingTypes.replace(apiResult.globalSettingTypes);
        this.GlobalSettings.replace(apiResult.globalSettings);
        this.StripePublicKey = apiResult.stripePublicKey;

        if (apiResult.accountStatus.companyViewId) {
            this.ViewAsCompanyId = apiResult.accountStatus.companyViewId;
        }

        if (apiResult.accountStatus.viewAsCompanyName) {
            this.ViewAsCompanyName = apiResult.accountStatus.viewAsCompanyName;
        }

        if (apiResult.accountStatus.permissions) {
            this.permissions.replace(apiResult.accountStatus.permissions);
        } else {
            this.permissions.clear();
        }
    };

    public hasPermission = (target: string, level?: "read" | "write") => {
        for (const userPermission of this.permissions) {
            const userPermissionComponents = userPermission.split(":");
            const userPermissionTarget = userPermissionComponents[0];

            if (userPermissionTarget === "global") {
                return true;
            }

            if (userPermissionTarget === target) {
                if (userPermissionComponents.length === 1) {
                    return true;
                }

                if (level == null) {
                    return true;
                }

                const userPermissionLevel = userPermissionComponents[1];

                if (level === userPermissionLevel) {
                    return true;
                }

                if (level === "write" && userPermissionLevel === "read") {
                    return true;
                }
            }
        }

        return false;
    };

    @action
    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }

        return false;
    };

    @action
    public Logout = (redirect: boolean = true): void => {
        this.IsLoggedIn = false;
        this.UserName = "";
        this.DisplayName = "";
        this.Id = "";
        this.ViewAsCompanyId = "";
        this.ViewAsCompanyName = "";
        this.permissions.clear();
        this.GlobalSettingTypes.clear();
        this.GlobalSettings.clear();

        if (redirect) {
            window.location.href = "/login";
        }
    };

    @action
    public setPropertyId = (val: string) => {
        // Potentially, properties can belong to different companies, so we
        // should reload the company logo if the property id changes.
        if (this.PropertyId !== val || isEmptyOrWhitespace(this.CompanyLogo)) {
            this.loadCompanyLogo(val);
        }

        this.PropertyId = val;
    };

    @action
    public setIsInTenYearWarranty = (val: boolean) => {
        this.IsInTenYearWarranty = val;
    };

    @action
    public setCompanyLogo = (val: string) => {
        this.CompanyLogo = val;
    };

    private loadCompanyLogo = async (id: string): Promise<void> => {
        try {
            const apiResult = await axios.get<ApiResult>(Server.Api.Image.GetCompanyImage + "/" + id);

            if (!isNullOrUndefined(apiResult.data) && apiResult.data.wasSuccessful) {
                this.setCompanyLogo(apiResult.data.payload!);
            }
        } catch (exception) {
            // Do nothing. If we cannot get the company logo, it is not a big deal!
            // It is secondary to the functionality of the application.
        }
    };
}
