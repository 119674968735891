import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useObserver } from "mobx-react-lite";

// Core
import { useRouter } from "Core/Base";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { UploadableImage } from "Core/Components/Forms";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { NavButton } from "Custom/Components/Buttons/NavButton";

import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { ViewSnagStyle } from "Custom/StylesAppSpecific/DashboardSubViews/ViewSnagStyling";
import { ViewSnagViewModel } from "Custom/ViewModels/DashboardSubViewModels/ViewSnagViewModel";
import { SnagPhotoModelDTO } from "Custom/Models/DashboardModels/SnagModel";
import { StandardButton } from "Custom/Components/Buttons/StandardButton";
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import Lightbox from "Custom/Components/Popups/Lightbox";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

interface IProps {
    propertyId: string;
}

export const ViewSnag: React.FC<any> = props => {
    const dashboardPageStyles = DashboardPageStyles();
    const viewSnagStyle = ViewSnagStyle();

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    const snagId = (router.match.params as any).snagId;
    const propertyId = (router.match.params as any).propertyId;

    const [viewModel] = React.useState(() => new ViewSnagViewModel(propertyId, snagId));

    const [lightboxImg, setLightboxImg] = React.useState("");
    const [lightboxImgName, setLightboxImgName] = React.useState("");
    const [lightboxOpen, setLightboxOpen] = React.useState(false);

    /**
     * Create the contents of the title for the desktop.
     */
    const desktopSnagTitle = () => {
        const parts: string[] = [];

        parts.push(viewModel.model.snagNumber);

        if (!isEmptyOrWhitespace(viewModel.getAreaName)) {
            parts.push(viewModel.getAreaName);
        }

        if (!isEmptyOrWhitespace(viewModel.getTitle)) {
            parts.push(viewModel.getTitle);
        }

        return parts.join(" - ");
    };

    /**
     * Create the contents of the title for the mobile.
     */
    const mobileSnagTitle = () => {
        const parts: string[] = [];

        parts.push(viewModel.model.snagNumber);

        if (!isEmptyOrWhitespace(viewModel.getAreaName)) {
            parts.push(viewModel.getAreaName);
        }

        return parts.join(" - ");
    };

    /**
     * Create the contents of the details. If none are available, display
     * something to that effect.
     */
    const detailsDescription = () => {
        let description = viewModel.model.details;

        if (isEmptyOrWhitespace(description)) {
            description = "None specified.";
        }

        return description;
    };

    const canDisplayThumbnails = () => {
        return viewModel.getSnagPhotos.length > 0;
    };

    const getSnagDateClasses = (viewSnagViewModel: ViewSnagViewModel): string => {
        let classes = viewSnagStyle.date;

        if (viewSnagViewModel.isCompleted) {
            classes = `${classes} ${viewSnagStyle.resolvedSnagTitle}`;
        } else if (viewSnagViewModel.isRejected) {
            classes = `${classes} ${viewSnagStyle.rejectedSnagTitle}`;
        }

        return classes;
    };

    /**
     * Renders the snag header. This varies between mobile and desktop
     * devices.
     */
    const renderSnagHeader = () =>
        isDesktop ? (
            <Box className={viewSnagStyle.header}>
                <Box className={viewSnagStyle.headerRowA}>
                    <Box className={viewSnagStyle.navSnags}>
                        <NavButton displayName="Back to snags" execute={() => viewModel.naviagateToSnagList()} />
                    </Box>
                </Box>
                <Box className={viewSnagStyle.headerRowB}>
                    <Box className={viewSnagStyle.title}>
                        <Typography variant="h5">{desktopSnagTitle()}</Typography>
                    </Box>

                    <Box className={viewSnagStyle.dateContainer}>
                        <Box className={viewSnagStyle.date}>
                            <Typography variant="h5">REPORTED: {viewModel.ReportedDate}</Typography>
                        </Box>
                        {viewModel.FormattedSnagStatus !== "" && (
                            <Box className={getSnagDateClasses(viewModel)}>
                                <Typography variant="h5">{viewModel.FormattedSnagStatus}</Typography>
                            </Box>
                        )}
                        {viewModel.isCompleted && (
                            <Box className={viewSnagStyle.completedBy}>
                                <Typography variant="h5">
                                    <strong>WORK COMPLETED BY:</strong> {viewModel.resolvedBy}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                {viewModel.hasRejectedReason && viewModel.isRejected && (
                    <Box className={viewSnagStyle.headerRowC}>
                        <Box className={viewSnagStyle.rejectedReason}>
                            <Typography>{viewModel.rejectedReason}</Typography>
                        </Box>
                    </Box>
                )}
                <Box className={viewSnagStyle.headerRowD}>
                    <Box className={viewSnagStyle.detailsTitle}>
                        <Typography variant="h5">Details</Typography>
                    </Box>
                    <Box className={viewSnagStyle.details}>
                        <Typography>{detailsDescription()}</Typography>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box className={viewSnagStyle.header}>
                <Box className={viewSnagStyle.headerRowA}>
                    <Box>
                        <Box className={viewSnagStyle.title}>
                            <Typography variant="h5">{mobileSnagTitle()}</Typography>
                        </Box>
                        <Box className={viewSnagStyle.title}>
                            <Typography variant="h5">{viewModel.getTitle}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box className={viewSnagStyle.navSnags}>
                            <CloseButton execute={() => viewModel.naviagateToSnagList()} />
                        </Box>
                    </Box>
                </Box>
                <Box className={viewSnagStyle.headerRowB}>
                    <Box className={viewSnagStyle.dateContainer}>
                        <Box className={viewSnagStyle.date}>
                            <Typography variant="h5">REPORTED: {viewModel.ReportedDate}</Typography>
                        </Box>

                        {viewModel.FormattedSnagStatus !== "" && (
                            <Box className={getSnagDateClasses(viewModel)}>
                                <Typography variant="h5">{viewModel.FormattedSnagStatus}</Typography>
                            </Box>
                        )}
                        {viewModel.isCompleted && (
                            <Box className={viewSnagStyle.completedBy}>
                                <Typography variant="h5">
                                    <strong>WORK COMPLETED BY:</strong> {viewModel.resolvedBy}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                {viewModel.hasRejectedReason && viewModel.isRejected && (
                    <Box className={viewSnagStyle.headerRowC}>
                        <Box className={viewSnagStyle.rejectedReason}>
                            <Typography>{viewModel.rejectedReason}</Typography>
                        </Box>
                    </Box>
                )}

                <Box className={viewSnagStyle.headerRowD}>
                    <Box className={viewSnagStyle.detailsTitle}>
                        <Typography variant="h5">Details</Typography>
                    </Box>
                    <Box className={viewSnagStyle.details}>
                        <Typography>{detailsDescription()}</Typography>
                    </Box>
                </Box>
            </Box>
        );

    const renderAttatchedImagesRow = () => (
        <Box className={viewSnagStyle.paddedBox}>
            <Box className={viewSnagStyle.dashedBorderBox}>
                <Typography variant="h5">Attatched images</Typography>
                {canDisplayThumbnails() && (
                    <Grid
                        container
                        style={{
                            marginTop: "12px",
                        }}
                    >
                        {viewModel.getSnagPhotos.map((photo: SnagPhotoModelDTO, index: number) => {
                            return (
                                <Grid item xs={6} spacing={4} key={`snag-photo-${index}`}>
                                    {/* <img
                                        src={photo.azureFileName}
                                        alt={`Snag photo ${index}`}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "120px",
                                        }}
                                        onClick={() => {
                                            setLightboxImg(photo.azureFileName);
                                            setLightboxImgName(photo.fileName);
                                            setLightboxOpen(true);
                                        }}
                                    /> */}
                                    <PrivateBlob
                                        src={photo.azureFileName}
                                        alt={`Snag photo ${index}`}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "120px",
                                        }}
                                        onClick={() => {
                                            setLightboxImg(photo.azureFileName);
                                            setLightboxImgName(photo.fileName);
                                            setLightboxOpen(true);
                                        }}
                                    />
                                    <Typography variant="body2">{photo.fileName}</Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                {!canDisplayThumbnails() && <Typography variant="body1">No attatched images found.</Typography>}
            </Box>
        </Box>
    );

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${viewSnagStyle.root}`}>
            <Box className={dashboardPageStyles.workspace}>
                {renderSnagHeader()}

                <React.Fragment>{renderAttatchedImagesRow()}</React.Fragment>
                <Lightbox isOpen={lightboxOpen} imageUrl={lightboxImg} onClose={setLightboxOpen} fileName={lightboxImgName} />
            </Box>
        </Box>
    ));
};
