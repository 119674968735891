import { Accordion, AccordionDetails, AccordionSummary, Box, CardMedia, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Core
import { Loader } from "Core/Components/Loader";
import { useRouter } from "Core/Utils";

// Custom
import { CloseButton } from "Custom/Components/Buttons/CloseButton";
import { NavButton } from "Custom/Components/Buttons/NavButton";
import { StandardButton } from "Custom/Components/Buttons/StandardButton";
import { Error } from "Custom/Components/Error";
import { ChoicesProductGroup } from "Custom/Views/DashboardSubViews/ChoicesProductGroup";
import { PrivateBlob } from "Custom/Components/Image/PrivateImage";
import { DashboardPageStyles } from "Custom/StylesAppSpecific/DashboardPagesStyling";
import { ChoicesAreaPageStyles } from "Custom/StylesAppSpecific/DashboardSubViews/ChoiceAreaPageStyling";
import { ApiStatus } from "Custom/Models/Enums/ApiStatus";
import { ChoicesAreaViewModel } from "Custom/ViewModels/DashboardSubViewModels/ChoicesAreaViewModel";
import { ProductGroupViewModel } from "Custom/ViewModels/DashboardSubViewModels/ProductGroupViewModel";

export const ChoicesArea: React.FC = () => {
    // #region Code Behind

    const router = useRouter();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")) ? true : false;

    //console.log("CHOICESAREAVIEW: PropertyId", router.match.params);
    const areaId = (router.match.params as any).areaId;
    const propertyId = (router.match.params as any).propertyId;

    const dashboardPageStyles = DashboardPageStyles();
    const choicesAreaPageStyles = ChoicesAreaPageStyles();

    const [viewModel] = useState(() => new ChoicesAreaViewModel(propertyId, areaId));

    const onAccordionChange = (productGroup: ProductGroupViewModel) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        productGroup.setCanDisplay(!productGroup.canDisplay);
    };

    // #endregion Code Behind

    const renderBusy = () => <Loader loadingText={viewModel.apiStatusLoadingMessage} />;

    const renderError = () => <Error errorText={viewModel.apiStatusErrorMessage} />;

    const renderSummaryText = () => {
        if (viewModel.isChoicesLocked) {
            return <Typography>Choices are disabled. You can view but you can't add or remove any. If you wish to make changes, please contact your sales agent.</Typography>;
        } else {
            return (
                <Typography>Select an option then choose to upgrade and add any extras. Once you have finished add them to your order via the "add to order" button.</Typography>
            );
        }
    };

    const renderTitleRow = () =>
        isDesktop ? (
            <Box className={choicesAreaPageStyles.titleRow}>
                <Box className={choicesAreaPageStyles.titleTextRow}>
                    <Box>
                        <NavButton displayName="Back to choices" execute={() => viewModel.navigateToChoices(propertyId)} />
                    </Box>
                    <Typography variant={"h5"}>{viewModel.model.areaName}</Typography>
                    {renderSummaryText()}
                </Box>
                <Box className={choicesAreaPageStyles.titleImageRow}>
                    <PrivateBlob src={viewModel.areaImage} title="" cardMedia={true} />
                </Box>
            </Box>
        ) : (
            <Box className={choicesAreaPageStyles.titleRow}>
                <Box className={choicesAreaPageStyles.titleImageRow}>
                    {/* This is a dummy element - do not remove */}
                    <Box />
                    <PrivateBlob src={viewModel.areaImage} title="" cardMedia={true} />
                    <Box>
                        <CloseButton execute={() => viewModel.navigateToChoices(propertyId)} />
                    </Box>
                </Box>
                <Typography variant={"h5"}>{viewModel.model.areaName}</Typography>
                {renderSummaryText()}
            </Box>
        );

    const renderOptionsAccordionLabel = () => {
        if (viewModel.isChoicesLocked) {
            return "Options are in view only mode";
        } else {
            return "Please select your options...";
        }
    };

    const renderForms = () => (
        <Box>
            {viewModel.productGroups.map((productGroup, index) => (
                <Accordion
                    className={choicesAreaPageStyles.accordion}
                    expanded={productGroup.canDisplay}
                    onChange={onAccordionChange(productGroup)}
                    TransitionProps={{ unmountOnExit: true, timeout: 0 }}
                    key={productGroup.model.id}
                >
                    <AccordionSummary
                        className={choicesAreaPageStyles.accordionSummary}
                        expandIcon={productGroup.canDisplay ? <RemoveCircleIcon /> : <AddCircleIcon />}
                        aria-label="Expand"
                        aria-controls={`additional-content${index}`}
                        id={`additional-header${index}`}
                    >
                        <Box className={choicesAreaPageStyles.productGroupTitleContainer}>
                            <Typography>{productGroup.model.productGroupName}</Typography>
                            <Box className={choicesAreaPageStyles.productGroupCounterContainer}>
                                <Typography variant="body2">{`${productGroup.numberOfExtras} extras added`}</Typography>
                                <Box
                                    className={
                                        !productGroup.isChoiceSelected
                                            ? choicesAreaPageStyles.productGroupChoiceCounterContainer
                                            : `${choicesAreaPageStyles.productGroupChoiceCounterContainer} ${choicesAreaPageStyles.productGroupChoiceCounterContainerComplete}`
                                    }
                                >
                                    {productGroup.isChoiceSelected && <CheckIcon />}
                                    {!productGroup.isChoiceSelected && <ClearIcon />}
                                    <Typography variant="body2">{` option selected`}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={choicesAreaPageStyles.productGroupAccordionDetails}>
                        <ChoicesProductGroup viewModel={productGroup} isDisabled={viewModel.isChoicesLocked} accordionLabel={renderOptionsAccordionLabel()} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );

    const renderSubmit = () => {
        if (!viewModel.isChoicesLocked) {
            return (
                <Box className={choicesAreaPageStyles.submitButton}>
                    <StandardButton displayName="Add to order" execute={viewModel.addToOrder} canExecute={viewModel.canAddToOrder} />
                </Box>
            );
        }
    };

    return useObserver(() => (
        <Box className={`${dashboardPageStyles.root} ${choicesAreaPageStyles.root}`}>
            <Box className={dashboardPageStyles.workspace}>
                {viewModel.ApiStatus === ApiStatus.success && (
                    <React.Fragment>
                        {renderTitleRow()}
                        {renderForms()}
                        {renderSubmit()}
                    </React.Fragment>
                )}
                {viewModel.ApiStatus === ApiStatus.loading && <React.Fragment>{renderBusy()}</React.Fragment>}
                {viewModel.ApiStatus === ApiStatus.error && <React.Fragment>{renderError()}</React.Fragment>}
            </Box>
        </Box>
    ));
};

//export default withRouter(ChoicesArea);
