/**
 * Regex to match all non numerical values and decimal points.
 */
const regexRemoveNonNumeric = /[^\d.]/g;

/**
 * Regex to match all non numerical values, decimal points and minus signs.
 */
const regexRemoveNonNumericNegative = /[^\d.-]/g;

/**
 * Formats the currency value with the pound sign, thousands separator and 2 decimal places.
 * @param {string} amount Price or currency value.
 * @param {boolean} [allowNegatives=false] Flag to allow negative values, false by default.
 * @returns {string} Formatted currency value.
 */
export const currencyFormat = (amount: string, allowNegatives: boolean = false) => {
    //JC: Remove "£" character for now, as the regex fails on the production build. (£ becomes \xa3 so the regex validation fails.)
    // 1234567.89 => £1,234,567.89
    // 12a34b56c7.89 => £1,234,567.89

    // TODO: Allow params to set format options.
    let options = {
        //style: "currency",
        //currency: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    const formatter = new Intl.NumberFormat("en-GB", options);

    // JC: To remove any gibberish entered by the user before we format the value.
    let numValue = +amount.toString().replace(allowNegatives ? regexRemoveNonNumericNegative : regexRemoveNonNumeric, "");

    if (isNaN(numValue)) {
        // JC: Just echo the value back to the user if we can't format it.
        return amount;
    } else {
        let formattedValue = formatter.format(numValue);
        return formattedValue;
    }
};

/**
 * Unformats the currency value before upserting. Removes everything but numbers and decimal.
 * @param {string} amount Price or currency value.
 * @param {boolean} [allowNegatives=false] Flag to allow negative values, false by default.
 * @returns {string} Unformatted currency value.
 */
export const currencyFormatForUpsert = (amount: string, allowNegatives: boolean = false) => {
    // £1,234,567.89 => 1234567.89

    return amount.toString().replace(allowNegatives ? regexRemoveNonNumericNegative : regexRemoveNonNumeric, "");
};
