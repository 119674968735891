import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { FieldType, getHistory } from "../../Core/Utils/Utils";
import { action, computed, observable } from "mobx";
import { BuyModel, BuyModelDTO } from "../../Custom/Models/BuyModel";
import { IProperty } from "../Models/IProperty";
import { ApiResult } from "../../Core/Models";
import { CardPaymentModel, CardPaymentModelDTO } from "../Models/CardPaymentModel";

declare var Stripe: any;

export class BuyViewModel extends ViewModelBase<BuyModel> {
    private static _instance: BuyViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable
    public errorMessage = "";

    constructor() {
        super(new BuyModel());
        this.setDecorators(BuyModel);
        getHistory().listen(change => {
            const lowerPathname = change.pathname.toLowerCase();
            if (!lowerPathname.includes("/buy") && !lowerPathname.includes("/checkout")) {
                this.resetPurchase();
            }
        });
    }

    @action
    public resetPurchase() {
        this.errorMessage = "";
        this.setValue("propertyId", "");
    }

    private async doPayment(buyModelDTO: BuyModelDTO): Promise<boolean> {
        try {
            this.setErrorMessage("");
            const response = await this.Post("/api/pay/payment", buyModelDTO);
            if (response && response.wasSuccessful) {
                let responsePayload: any = response.payload;
                if (responsePayload && responsePayload.actionUrl && responsePayload.actionUrl !== "") {
                    this.setValue("paymentSuccess", false);
                    this.setValue("stripeResponse", responsePayload.actionUrl + "||" + responsePayload.intentId);
                    return false;
                } else {
                    this.setValue("paymentSuccess", true);
                    this.setValue("stripeResponse", "");
                    return true;
                }
            } else if (response && response.errors && response.errors[0] && response.errors[0].message) {
                this.setErrorMessage(response.errors[0].message);
                this.setValue("paymentSuccess", false);
                this.setValue("stripeResponse", "");
            } else {
                this.unknownError();
            }
        } catch {
            this.unknownError();
        }
        return false;
    }

    // public applePay = (result: any, completion: any) => {
    //     this.doPayment("applepay", result.token.id).then(success => {
    //         if (success) {
    //             completion(Stripe.ApplePaySession.STATUS_SUCCESS);
    //             //getHistory().push("/tickets");
    //         } else {
    //             completion(Stripe.ApplePaySession.STATUS_FAILURE);
    //         }
    //     });
    // };

    // public googlePay = (paymentData: any) => {
    //     this.doPayment("googlepay", JSON.parse(paymentData.paymentMethodData.tokenizationData.token).id).then(success => {
    //         if (success) {
    //             //getHistory().push("/tickets");
    //         } else {
    //         }
    //     });
    // };

    public cardPayment = async (buyModelDTO: BuyModelDTO) => {
        const success = await this.doPayment(buyModelDTO);
        if (success) {
            getHistory().push("/property/" + buyModelDTO.propertyId);
        } else {
        }
    };

    private unknownError = () => {
        this.setErrorMessage("An unknown error occured.");
    };

    @action
    public setErrorMessage(msg: string) {
        this.errorMessage = msg;
    }

    @computed
    public get price() {
        return this.getPrice(false) as number;
    }

    public getPrice(formatted: boolean) {
        let price = 0;
        price = this.price || 0;

        if (!formatted) return price;

        return `£${price.toFixed(2)}`;
    }

    public isFieldValid(fieldName: keyof FieldType<BuyModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // public afterUpdate(fieldName: keyof FieldType<BuyModel>, value: any) {
    //     if (fieldName === "route" && value) {
    //         this.fetchRouteFares(value);
    //     }
    // }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
