import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";
import { IsNotEmpty, IsCreditCard, IsNumberString, Length, IsDate } from "class-validator";
import { CARD_TYPE } from "../../Custom/ViewModels/CardPaymentViewModel";

export class CardPaymentModel extends ModelBase<CardPaymentModel, CardPaymentModelDTO> {
    @IsNotEmpty({ message: "Name is required" })
    @MobX.observable
    public name: string = "";

    @IsNotEmpty({ message: "Card number is required" })
    @IsCreditCard({ message: "Card number is invalid" })
    @MobX.observable
    public cardNumber: string = "";

    @IsNotEmpty({ message: "Security code is required" })
    @IsNumberString({ message: "Security code is invalid" }, { maxDecimalPlaces: 0 })
    @Length(3, 3, { message: "Security code is invalid" })
    @MobX.observable
    public security: string = "";

    @IsNotEmpty({ message: "Expiry is required" })
    @IsDate({ message: "Expiry is invalid" })
    @MobX.observable
    public expiry: Date | null = null;

    public type: CARD_TYPE;

    constructor(type: CARD_TYPE) {
        super();

        this.type = type;
    }

    public fromDto = (model: CardPaymentModelDTO) => {};
    public toDto = (model: CardPaymentModel) => {};
}

export interface CardPaymentModelDTO {
    name: string;
    type: string;
    expiry: Date | null;
    security: string;
    cardNumber: string;
}
