// Libraries
import MomentUtils from "@date-io/moment";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInputProps,
    Radio,
    RadioGroup,
    Select,
    TextField,
    InputLabelProps,
} from "@material-ui/core";
import { DatePickerView, KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { getPath } from "ts-object-path";

// Core
import { Validation } from "Core/Components/Validation";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { FieldType, generateID } from "Core/Utils/Utils";
import { IViewModel } from "Core/ViewModels/IViewModel";
import { property } from "lodash-es";

const Control = styled(FormControl)`
    min-width: 120px;
`;

type InputProps = {
    autoComplete?: string;
    autoOk?: boolean;
    disablePast?: boolean;
    displayEmpty?: boolean;
    format?: string;
    id?: string;
    InputProps?: Partial<OutlinedInputProps>;
    InputLabelProps?: Partial<InputLabelProps>;
    mask?: string;
    multiline?: boolean;
    openTo?: DatePickerView;
    placeholder?: string;
    rows?: number;
    type?: string;
    maxLength?: number;
    minDateMessage?: string;
    renderValue?: (selected: any) => JSX.Element;
    views?: DatePickerView[];
};

export type EditableInputProps<T> = {
    className?: string;
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T> | string;
    selectItems?: KeyValuePair[];
    inputProps?: InputProps;
    required?: boolean;
    editMode?: boolean;
    validateOnStart?: boolean;
    validateOnBlur?: boolean;
    fullwidth?: boolean;
    type?: "text" | "number" | "password" | "email" | "checkbox" | "radio" | "select" | "date" | "time" | "datetime" | "multiselect";
    label?: string;
    onChange?: (event: any) => void;
};

export function EditableInput<T>(props: EditableInputProps<T>) {
    const rendered = useRef<boolean>(false);
    const { multiline } = props.inputProps!;
    const [bind, { text, password, radio, checkbox, date, time, email, select, number, multiselect }] = Validation<T>(props.viewModel);

    let fieldName: keyof FieldType<T> = props.fieldName as any;

    if (typeof props.fieldName !== "string") {
        let p = getPath(fieldName as string);

        fieldName = p.join(".") as any;
    }

    useEffect(() => {
        rendered.current = true;

        if (props.validateOnStart) {
            validate(props.viewModel.getValue(fieldName));
        }
    }, []);

    const validate = (value: any) => {
        props.viewModel.isFieldValid(fieldName, value);
    };

    const isInError = (): boolean => {
        let isValid = props.viewModel.getValid(fieldName);
        if (props.validateOnStart) {
            return !isValid;
        }

        if (!rendered.current) {
            return false;
        }
        return !isValid;
    };

    const renderControl = () => {
        if (props.type === "checkbox") {
            return checkBox();
        } else if (props.type === "radio") {
            return radiocomp();
        } else if (props.type === "select" || props.type === "multiselect") {
            return selectField();
        } else if (props.type === "date") {
            return dateComp();
        } else if (props.type === "time") {
            return timeComp();
        } else if (props.type === "password") {
            return textField();
        } else {
            return textField();
        }
    };

    const dateComp = () => {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    className={props.className}
                    format="DD-MMM-YYYY"
                    fullWidth
                    inputVariant="outlined"
                    label={props.label}
                    mask={"__-__-____"}
                    placeholder="01/JAN/2020"
                    size="small"
                    {...date(fieldName, { validateOnBlur: props.validateOnBlur })}
                    {...props.inputProps}
                />
            </MuiPickersUtilsProvider>
        );
    };

    const timeComp = () => {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker
                    className={props.className}
                    fullWidth
                    inputVariant="outlined"
                    label={props.label}
                    mask={"__:__ _M"}
                    placeholder="08:00 AM"
                    size="small"
                    {...time(fieldName, { validateOnBlur: props.validateOnBlur })}
                />
            </MuiPickersUtilsProvider>
        );
    };

    const radiocomp = () => {
        return (
            <Control className={props.className} error={isInError()}>
                <FormLabel component="legend" id="demo-simple-radio-label">
                    {props.label}
                </FormLabel>
                <RadioGroup {...radio(fieldName, { validateOnBlur: props.validateOnBlur })} aria-label={props.label} name={props.label} row={true}>
                    {props.selectItems!.map((item: KeyValuePair) => {
                        return <FormControlLabel control={<Radio />} key={generateID()} label={item.key} name={props.label} value={item.value} />;
                    })}
                </RadioGroup>
                {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
            </Control>
        );
    };

    const selectField = () => {
        return (
            <Control className={props.className} error={isInError()}>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    variant="outlined"
                    {...(props.type === "multiselect"
                        ? multiselect(fieldName, { validateOnBlur: props.validateOnBlur })
                        : select(fieldName, { validateOnBlur: props.validateOnBlur }))}
                    {...(props.inputProps || {})}
                >
                    {props.selectItems!.map((item: KeyValuePair, index: number) => {
                        return (
                            <MenuItem disabled={item.value === ""} key={generateID()} value={item.value}>
                                {item.key}
                            </MenuItem>
                        );
                    })}
                </Select>
                {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
            </Control>
        );
    };

    const getTypeOfInput = () => {
        if (props.type === "number") {
            return number(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "password") {
            return password(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "email") {
            return email(fieldName, { validateOnBlur: props.validateOnBlur });
        }

        return text(fieldName, { validateOnBlur: props.validateOnBlur });
    };

    const textField = () => {
        if (props.editMode) {
            if (props.onChange) {
                return (
                    <TextField
                        autoComplete={props.type}
                        className={props.className}
                        fullWidth
                        {...getTypeOfInput()}
                        {...props.inputProps}
                        helperText={props.viewModel.getError(fieldName)}
                        label={props.label}
                        multiline={multiline ? true : undefined}
                        size="small"
                        variant="outlined"
                        onChange={props.onChange}
                    />
                );
            } else {
                return (
                    <TextField
                        autoComplete={props.type}
                        className={props.className}
                        fullWidth
                        {...getTypeOfInput()}
                        {...props.inputProps}
                        helperText={props.viewModel.getError(fieldName)}
                        label={props.label}
                        multiline={multiline ? true : undefined}
                        size="small"
                        variant="outlined"
                    />
                );
            }
        } else {
            return <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>;
        }
    };

    const checkBox = () => {
        return (
            <>
                <FormControlLabel
                    control={<Checkbox {...checkbox(fieldName, { validateOnBlur: props.validateOnBlur })} />}
                    label={props.label}
                    style={{ color: isInError() ? "red" : "black" }}
                />
                {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
            </>
        );
    };

    const formatSelectValue = (value: any) => {
        let retval = props.selectItems!.find(a => a.value == value);
        if (retval) return retval!.key;
        else return "";
    };

    return useObserver(() => <Box>{renderControl()}</Box>);
}

EditableInput.defaultProps = {
    type: "text",
    editMode: true,
    validateOnStart: false,
    validateOnBlur: true,
    fullwidth: true,
    inputProps: {
        multline: undefined,
    },
};
