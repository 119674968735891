import { action, computed, observable } from "mobx";

import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

import { ResetPasswordModel } from "Custom/Models/ResetPasswordModel";
import { Server } from "Custom/Globals/AppUrls";
import { StoresInstance } from "Core/Base";
import { LoginComplete } from "Core/Models";
export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    @observable
    public tokenValid?: boolean;

    constructor(token: string) {
        super(new ResetPasswordModel());
        this.setDecorators(ResetPasswordModel);

        this.model.token = token;
    }

    // #region Server Properties and Actions

    @observable
    public haveSubmittedToServer = false;

    @action
    public setHaveSubmittedToServer = (value: boolean) => {
        this.haveSubmittedToServer = value;
    };

    @observable
    public serverValidationMessage = "";

    @action
    public setServerValidationMessage = (value: string) => {
        this.serverValidationMessage = value;
    };

    @action
    public changePassword = async (): Promise<void> => {
        try {
            if (this.isModelValid()) {
                this.setServerValidationMessage("");
                this.setHaveSubmittedToServer(true);

                const apiResult = await this.Post(Server.Api.Account.ChangePassword, this.model);

                if (apiResult.wasSuccessful) {
                    // Nothing to do.
                } else {
                    if (apiResult.errors.length > 0) {
                        const error = apiResult.errors[0];

                        if (!isEmptyOrWhitespace(error.message)) {
                            this.setServerValidationMessage(error.message);
                        } else {
                            this.setServerValidationMessage("There was an unknown error attempting to change the password.");
                        }
                    }
                }
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error attempting to change the password.");
        } finally {
            // Finally
        }
    };

    @action
    public loginAfterReset = async (): Promise<void> => {
        try {
            if (this.isModelValid()) {
                this.setServerValidationMessage("");
                this.setHaveSubmittedToServer(true);

                const apiResult = await this.Post<LoginComplete>(Server.Api.Account.Login, this.getModel);

                if (apiResult.wasSuccessful) {
                    StoresInstance.domain.AccountStore.setLoginState(apiResult.payload);

                    const { from } = (this.history.location.state as any) || { from: { pathname: `/${apiResult.payload.propertyId}` } };

                    if (from) {
                        this.history.replace(from.pathname);
                    }
                } else {
                    if (apiResult.errors.length > 0) {
                        const error = apiResult.errors[0];

                        if (!isEmptyOrWhitespace(error.message)) {
                            this.setServerValidationMessage(error.message);
                        } else {
                            this.setServerValidationMessage("There was an unknown error trying to log in.");
                        }
                    }
                }
            }
        } catch (exception) {
            this.setServerValidationMessage("There was an unknown error trying to log in.");
        } finally {
            // Finally
        }
    };

    public tryAgain = () => {
        this.setHaveSubmittedToServer(false);
        this.setServerValidationMessage("");
    };

    // #endregion Server Properties and Actions

    // #region Can Display Properties

    @computed
    public get canDisplayBusy(): boolean {
        return this.IsLoading;
    }

    @computed
    public get canDisplayError(): boolean {
        return this.haveSubmittedToServer && !this.IsLoading && !isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplaySuccess(): boolean {
        return this.haveSubmittedToServer && !this.IsLoading && isEmptyOrWhitespace(this.serverValidationMessage);
    }

    @computed
    public get canDisplayForm(): boolean {
        return !this.haveSubmittedToServer && !this.IsLoading;
    }

    // #endregion Can Display Properties

    // #region Navigation Actions

    public navigateToLogin = () => {
        this.history.replace("/login");
    };

    // #endregion Navigation Actions

    public get confirmPasswordValidationMessage(): string {
        //Check against any decorators in the model.
        const result = this.validateDecorators("confirmPassword");

        if (!result.isValid) {
            return result.errorMessage;
        }

        // Compare the confirm password against the password.
        if (this.getValue("password") !== this.getValue("confirmPassword") && this.getValue("password") !== "") {
            return "Passwords must match";
        }

        // No validation issues.
        return "";
    }

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: "emailAddress" | "password" | "confirmPassword", value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName === "confirmPassword") {
            errorMessage = this.confirmPasswordValidationMessage;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #endregion Boilerplate
}
