import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { nth } from "lodash-es";
import { Children } from "react";

/**
 * Contains style information for the documnents page.
 */
export const DocumentsPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    documentCategories: {
        margin: "0 18px",
    },
    documentCategory: {
        display: "flex",
        flexDirection: "column",
        padding: "17.5px 0 15px 0",
        "&:not(:last-child)": {
            borderBottom: "1px dashed #ced4da",
        },
    },
    documentCategoryTitle: {
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    documentLink: {
        overflowWrap: "anywhere",
        "& > div": {
            margin: "2.5px 0 2.5px -4px",
        },
    },
    [theme.breakpoints.up("md")]: {
        titleRow: {
            padding: "27px 18px 15px 18px",
            display: "flex",
            justifyContent: "space-between",
            "& > button": {
                padding: "0",
            },
            "& > h5": {
                padding: "0px",
            },
            "& .MuiSvgIcon-root": {
                margin: "-7px",
                fontSize: "35px",
            },
        },
        title: {
            fontSize: "20px",
            fontWeight: "bold",
        },
        titleBorder: {
            borderBottom: "1px solid #CED4DA",
            margin: "0 18px 16px",
        },
        documentCategories: {
            margin: "-10px 18px 0 18px",
        },
        documentCategory: {
            "& > div": {
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill,minmax(300px, 1fr))",
                columnGap: "18px",
            },
        },
    },
    accordion: {
        "& > div.MuiCollapse-container": {
            background: "none",
        },
    },
    accordionSummary: {},
    productGroupAccordionDetails: {
        padding: "0px",
        flexWrap: "wrap",
    },
    productGroupAccordionDetail: {},
    productGroupTitleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: "1 1 auto",
        "& > p": {
            fontSize: "14px",
            fontWeight: "bold",
            marginRight: "10px",
        },
    },
    productGroupCounterContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "auto",
    },
    productGroupChoiceCounterContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "20px",
        alignItems: "center",
        "& > svg": {
            fontSize: "15px",
            stroke: "black",
            strokeWidth: "0.5px",
        },
    },
    checklistSubCategory: {
        fontSize: "12px",
        fontWeight: "bold",
        margin: "15px 0px",
        padding: "10px 0px",
        flexBasis: "100%",
        borderTop: "1px dashed #ced4da",
    },
}));
