import { Theme } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import React from "react";
import Dropzone, { useDropzone } from "react-dropzone";

const useStyles: any = makeStyles((theme: Theme) => ({
    dropzone: {
        alignItems: "center",
        backgroundColor: "white",
        borderColor: "#D4D2D7",
        borderRadius: "0px",
        borderStyle: "dashed",
        borderWidth: "1px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "16px",
        minHeight: "90px",
        "& > p": {
            color: "#7F7F7F",
            fontSize: "12px",
            maxWidth: "200px",
            textAlign: "center",
        },
    },
}));

export interface IUploadableImageProps {
    imageUrl?: string;
    className?: string;
    onImageSelected?: (imageFile: File) => any;
}

const allowedImageTypes = ["image/jpeg", "image/png"];
const maxSize = 100000000; // 100mb

export const UploadableImage: React.FC<IUploadableImageProps> = props => {
    const { imageUrl, className } = props;
    const theme: any = useTheme();
    const classes: any = useStyles(theme);

    const onDrop = (acceptedFiles: any) => {
        const { onImageSelected } = props;
        const selectedImage = acceptedFiles[0];

        if (onImageSelected) {
            onImageSelected(selectedImage);
        }
    };

    return (
        <Dropzone accept={(allowedImageTypes as any) as string} maxSize={maxSize} multiple={false} onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                    <div className="form-group">
                        <div {...getRootProps()} className={classes.dropzone}>
                            <input {...getInputProps()} />
                            <p>Click or drag a file to attach an image (.JPG .PNG)</p>
                            {imageUrl && (
                                <div className="width-100">
                                    <img alt={"Upload Preview"} className={className} src={imageUrl} />
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
        </Dropzone>
    );
};
