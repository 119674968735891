import { darken, lighten, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { withProps } from "../../../Core/Utils/React/WithProps";

export const HeavyHeader = styled(Typography)`
    font-family: "HelveticaNeue-Heavy";
    letter-spacing: -2.76px;
    line-height: 100%;
`;

const _HeavyParagraph = styled(HeavyHeader)`
    letter-spacing: -0.35px;
    margin-bottom: 6px;
    margin-top: 15px;
`;

export const HeavyParagraph = withProps(_HeavyParagraph)({ component: "p" } as any);

export const PaymentMethods = styled.div``;

/**
 * Contains style information for the property page.
 */
export const PropertyPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    introduction: {
        margin: "18px 18px 12px 18px",
    },
    introductionTitle: {
        fontSize: "12px",
    },
    description: {
        margin: "0px 18px 12px 18px",
        lineClamp: 2,
        "& > div": {
            padding: "0",
            margin: "8px 0",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
        },
        "& > div > a": {
            color: "#556ED1",
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
    reservation: {
        borderTop: "1px solid #7F7F7F",
        margin: "0px 18px 5px 18px",
    },
    reservationTitle: {
        display: "flex",
        fontSize: "12px",
        marginBottom: "11px",
        marginTop: "10px",
        "& > div:first-child": {
            fontWeight: "bold",
            minWidth: "145px",
        },
    },
    status: {
        borderBottom: "1px solid #7F7F7F",
        borderTop: "1px solid #7F7F7F",
        padding: "9px 18px 8px 18px",
        "& > div": {
            margin: "9px 0",
        },
        "& > div > h5": {
            fontSize: "12px",
            fontWeight: "bold",
            marginBottom: "2px",
            textTransform: "uppercase",
        },
        "& > div > div": {
            display: "flex",
        },
    },
    statusTitle: {
        display: "flex",
        flex: "1",
        fontSize: "12px",
        marginBottom: "2px",
        "& > div:first-child": {
            fontWeight: "bold",
            minWidth: "60px",
        },
    },
    titleRow: {
        alignItems: "center",
        backgroundColor: "#F9F9F9",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "72px",
        "& > div": {
            padding: "12px 18px",
            "& > h5": {
                fontSize: "16px",
                fontWeight: "bold",
            },
            "& > p": {
                fontSize: "12px",
            },
        },
    },
    propertyBox: {
        margin: "18px 18px 15px 18px",
    },
    propertyImages: {
        width: "100%",
        maxWidth: "400px",
    },
    topMarginWrapper: {
        paddingTop: "24px",
    },
    accordion: {
        margin: "0 !important",
        "& > div.MuiCollapse-container": {},
    },
    accordionSummary: {},
    [theme.breakpoints.up("md")]: {
        titleRow: {
            alignItems: "flex-start",
            background: "none",
            flexDirection: "column",
            padding: "27px 18px 15px 18px",
            "& > h5": {
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "8px",
            },
            "& > p": {
                fontSize: "12px",
                padding: "0px",
            },
        },
        introduction: {
            margin: "27px 18px 15px 18px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flex: "0 0 auto",
            "& > button": {
                padding: "0",
            },
            "& .MuiSvgIcon-root": {
                margin: "-7px",
                fontSize: "35px",
            },
        },
        introductionTitle: {
            fontSize: "22px",
            fontWeight: "bold",
        },
        reservation: {
            display: "flex",
            margin: "6px 18px 15px 18px",
            flex: "0 0 auto",
            "& > div": {
                flex: "1",
            },
            "& > div:last-child > div > div:first-child": {
                // Overrides the reservationTitle first-child width below.
                minWidth: "115px",
            },
        },
        reservationTitle: {
            fontSize: "16px",
            marginBottom: "2px",
            marginRight: "54px",
            "& > div:first-child": {
                minWidth: "200px",
            },
        },
        status: {
            margin: "8px 18px 12px 18px",
            padding: "1px 0",
            flex: "0 0 auto",
            "& > div": {
                margin: "10px 54px 8px 0",
            },
            display: "flex",
            "& > div > h5": {
                fontSize: "14px",
                marginBottom: "3px",
            },
            "& > div > div": {
                flexDirection: "column",
            },
        },
        statusTitle: {
            "& > div:first-child": {
                minWidth: "70px",
            },
        },
        accordion: {
            "& > div.MuiCollapse-container": {
                background: "none",
                borderTop: "1px dashed #ced4da",
                "& div.MuiAccordionDetails-root": {
                    padding: "30px 0px",
                },
            },
        },
        accordionSummary: {
            margin: "0 18px",
            "& > div.MuiAccordionSummary-content": {
                marginLeft: "-18px",
                "& > p": {
                    fontSize: "14px",
                },
            },
            "& > div.MuiAccordionSummary-expandIcon": {
                marginRight: "-18px",
            },
        },
    },
    caption: {
        textAlign: "center",
    },
}));

/**
 * Contains style information for the payment section.
 */
export const PaymentSectionStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    paymentRow: {
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexWrap: "wrap",
        margin: "16px 18px 0 18px",
        padding: "16px 18px",
    },
    paymentRowTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "6px",
        marginTop: "12px",
    },
    paymentRowSubTitle: {
        display: "flex",
        fontSize: "12px",
        marginBottom: "2px",
        justifyContent: "space-between",
        "& > div:first-child": {
            fontWeight: "bold",
            marginRight: "12px",
        },
    },
    paymentSelection: {
        display: "flex",
        flexDirection: "column-reverse",
    },
    paymentMethodRow: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "4px",
        position: "sticky",
    },
    paymentMethod: {
        display: "flex",
        backgroundColor: "white",
        border: "1px solid #D6D6D6",
        borderRadius: "10px",
        cursor: "pointer",
        height: "72px",
        margin: "2px",
        padding: "8px",
        width: "72px",
    },
    paymentMethodSelected: {
        border: "2px solid #000",
        cursor: "default",
        boxShadow: "2px 2px 15px 2px rgba(105,105,105,0.50)",
    },
    paymentMethodSelectionTitle: {
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
    },
    [theme.breakpoints.up("sm")]: {
        paymentRowSubTitle: {
            justifyContent: "flex-start",
        },
    },
    [theme.breakpoints.up("md")]: {
        paymentRow: {
            backgroundColor: "#F9F9F9",
            display: "flex",
            flexWrap: "wrap",
        },
        paymentSelection: {
            flexDirection: "column",
        },
        paymentMethodSelectionTitle: {
            fontSize: "10px",
            marginBottom: "0px",
            textAlign: "center",
        },
    },
}));

/**
 * Contains style information for the payment history section.
 */
export const PaymentHistoryStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    paymentRow: {
        backgroundColor: "#F9F9F9",
        borderTop: "1px solid #D6D6D6",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        margin: "0 18px",
        padding: "16px 18px",
    },
    paymentRowTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "6px",
        marginTop: "12px",
    },
    paymentRowSubTitle: {
        display: "flex",
        fontSize: "12px",
        marginBottom: "2px",
        justifyContent: "space-between",
        "& > div:first-child": {
            fontWeight: "bold",
            marginRight: "12px",
        },
    },
    [theme.breakpoints.up("sm")]: {
        paymentRowSubTitle: {
            justifyContent: "flex-start",
        },
    },
}));
