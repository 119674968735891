import React from "react";
import * as Mui from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { PrivateBlob } from "Custom/Components/Image/PrivateImage";

import { useStyles } from "./Lightbox.Style";

interface Props {
    isOpen: boolean;
    imageUrl: string;
    fileName?: string;
    imageCaption?: string;
    onClose(isOpen: boolean): void;
}

export const Lightbox: React.FC<Props> = (props: Props) => {
    const { isOpen, imageUrl, fileName, onClose, imageCaption } = props;
    const styles = useStyles();

    return (
        <Mui.Dialog
            open={isOpen}
            onClose={() => onClose(false)}
            fullWidth={true}
            maxWidth="md"
            BackdropProps={{
                classes: {
                    root: styles.lightboxRoot,
                },
            }}
            PaperProps={{
                classes: {
                    root: styles.lightboxPaper,
                },
            }}
        >
            <Mui.Box display="flex" justifyContent="center">
                <PrivateBlob src={imageUrl} className={styles.lightboxImg} alt="Lightbox image." />

                {/* <img src={imageUrl} className={styles.lightboxImg} alt="Lightbox image." /> */}
            </Mui.Box>
            {imageCaption && (
                <Mui.Box display="flex" justifyContent="center">
                    <div className={styles.caption}>{imageCaption}</div>
                </Mui.Box>
            )}
            <Mui.Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                    paddingTop: "8px",
                }}
            >
                <Mui.Typography
                    variant="body2"
                    style={{
                        color: "#FFFFFF",
                    }}
                >
                    {fileName}
                </Mui.Typography>
                <Mui.Box display="flex" alignItems="center" className={styles.closeWrapper} onClick={() => onClose(false)}>
                    <Mui.Typography variant="body1" className={styles.closeTxt}>
                        Close
                    </Mui.Typography>

                    <CloseIcon className={styles.closeIcon} />
                </Mui.Box>
            </Mui.Box>
        </Mui.Dialog>
    );
};

export default Lightbox;
