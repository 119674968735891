import { darken, lighten, makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

/**
 * Contains style information for the documnents page.
 */
export const InspectionPageStyles = makeStyles((theme: Theme) => ({
    root: {},
    stages: {
        padding: "20px 0 16px 0",
        overflowY: "auto",
        paddingBottom: "150px",
    },
    stage: {
        display: "flex",
        margin: "0 18px",
        "& > div:first-child": {
            minHeight: "80px",
            minWidth: "80px",
            position: "relative",
            "& > div": {
                position: "absolute",
            },
            // Link element.
            "& > div:first-child": {
                backgroundColor: "#ced4da",
                minHeight: "10%",
                minWidth: "8px",
                transform: "translate(26px, 0px)",
            },
            "& > div:last-child": {
                backgroundColor: "#ced4da",
                minHeight: "15%",
                minWidth: "8px",
                transform: "translate(26px, 0px)",
                bottom: "0",
            },
        },
        // First progress element.
        "&:nth-child(2)": {
            "& > div:first-child": {
                // Link element.
                "& > div:first-child": {
                    display: "none",
                },
            },
        },
        // Last progress element.
        "&:last-child": {
            "& > div:first-child": {
                // Link element.
                "& > div:last-child": {
                    display: "none",
                },
            },
        },
    },
    stageTextRow: {
        color: "#808080",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "10px",
    },
    stageTitle: {
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    stageSubTitle: {
        fontSize: "12px",
    },
    icon: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "60px",
        height: "60px",
        margin: "10% 0  10%",
    },
    gradient: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: "36px",
        background: "linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 25%)",
        pointerEvents: "none",
        zIndex: 1,
    },
    workspace: {
        position: "relative",
    },
    [theme.breakpoints.up("md")]: {
        stages: {
            padding: "0 0 16px 0",
            marginRight: "18px",
            paddingBottom: "150px",
        },
        titleRow: {
            padding: "27px 18px",
            display: "flex",
            justifyContent: "space-between",
            "& > h4": {
                padding: "0px",
            },
            "& > button": {
                padding: "0",
            },
            "& .MuiSvgIcon-root": {
                margin: "-7px",
                fontSize: "35px",
            },
        },
        title: {
            fontSize: "22px",
            fontWeight: "bold",
        },
    },
}));
