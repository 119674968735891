import { BaseStore } from "../../../Core/Stores/BaseStore";
import { InitialState } from "../../../Custom/Models";
import { Stores } from "..";
import * as MobX from "mobx";

declare var Stripe: any;

export class StripeStore extends BaseStore {
    @MobX.observable
    public loaded = false;

    public test = true;

    public tokenizationSpecification?: any;

    public merchantInfo?: any;

    public init(stores: Stores, initialState: InitialState) {
        if (initialState !== undefined) {
            if (Stripe) {
                Stripe.setPublishableKey(initialState.stripePublicKey);
            }

            this.test = initialState.useTestPaymentGateways;

            this.tokenizationSpecification = {
                type: "PAYMENT_GATEWAY",
                parameters: {
                    gateway: "stripe",
                    "stripe:version": "2018-10-31",
                    "stripe:publishableKey": initialState.stripePublicKey,
                },
            };

            // this.merchantInfo = {
            //     merchantName: initialState.appSettings.googleMerchantName,
            //     merchantId: initialState.appSettings.googleMerchantId,
            // };
        }

        MobX.runInAction(() => {
            this.loaded = true;
        });
    }
}
