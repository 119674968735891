import { Box, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React from "react";

import { ErrorStyles } from "Custom/StylesAppSpecific/ErrorStyling";

interface IProps {
    errorText: string;
}

export const Error: React.FC<IProps> = props => {
    const errorStyles = ErrorStyles();

    return useObserver(() => (
        <Box className={errorStyles.root}>
            <Typography variant="h5">There seems to have been a problem</Typography>
            <Typography variant="body1">{props.errorText}</Typography>
        </Box>
    ));
};
