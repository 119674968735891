// Lib
import * as React from "react";

//App
import * as Models from "../../Models";

interface Props {
    colour: string;
    grayed: boolean;
}

export const InspectionStagePresetHelper = (colour: string, type: number) => {
    if (colour) {
        return <InspectionStageSVG colour={colour} grayed={type == null ? true : false} />;
    } else {
        return <></>;
    }
};

export const InspectionStageSVG = (props: Props) => {
    const { colour, grayed } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 50 50">
            <g transform="translate(-652 -970)">
                <rect width="50" height="50" transform="translate(652 970)" fill={grayed ? "lightgrey" : colour} />
                <path
                    d="M3.209,7.666c.948-.736,1.734-.229,2.783.987.118.137.276-.023.367-.1S7.834,7.225,7.9,7.168a.2.2,0,0,0,.042-.3c-.109-.125-.5-.635-.755-.966-1.837-2.4,5.025-4.032,3.971-4.057-.536-.014-2.688-.039-3.01,0-1.3.137-2.94,1.355-3.764,1.923A10.262,10.262,0,0,0,2.839,5c-.3.266-.049.881-.6,1.365-.584.511-.949.124-1.287.42-.168.149-.637.5-.771.616a.33.33,0,0,0-.022.479S1.443,9.3,1.551,9.423a.441.441,0,0,0,.576.072c.18-.159.642-.562.719-.633s-.051-.875.364-1.2Zm5.8.525a.26.26,0,0,0-.4-.029L7.148,9.434a.293.293,0,0,0-.027.406l8.425,9.588a.54.54,0,0,0,.763.054l.985-.826a.549.549,0,0,0,.053-.77l-8.339-9.7ZM20.242,4.111c-.075-.5-.335-.4-.47-.185s-.733,1.119-.978,1.53a1.216,1.216,0,0,1-1.964.416c-1.166-.824-.761-1.4-.557-1.786s.831-1.48.921-1.615a.273.273,0,0,0-.377-.369c-.363.166-2.563,1.041-2.868,2.3-.312,1.276.261,2.416-.863,3.549L11.719,9.369l1.37,1.591,1.68-1.595a2.377,2.377,0,0,1,2.03-.616,2.491,2.491,0,0,0,3.11-1.278,7.3,7.3,0,0,0,.332-3.361ZM2.806,17.99a.547.547,0,0,0,0,.77l.966.945a.539.539,0,0,0,.758-.09l4.985-4.9L7.989,12.974,2.806,17.991Z"
                    transform="translate(666 982.174)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};
