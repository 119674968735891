// Libraries
import { Box, CardMedia, Divider, IconButton, InputAdornment, Typography } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// Core
import { Loader } from "Core/Components";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { StandardButton } from "Custom/Components/Buttons/StandardButton";
import { EditableInput } from "Custom/Components/Edits/EditableInput";
import { ResetPasswordModel } from "Custom/Models/ResetPasswordModel";
import { AccountPageStyles, ChangePasswordPageStyles } from "Custom/StylesAppSpecific/AccountPagesStyling";
import { ResetPasswordViewModel } from "Custom/ViewModels/ResetPasswordViewModel";
import { HeaderLogoModel } from "Custom/ViewModels/DashboardSubViewModels/HeaderLogoModel";
import { runInAction } from "mobx";

export const ResetPasswordPage: React.FC<any> = props => {
    // #region Code Behind

    // Get the token from the Url query string. Note that doing this unescapes any
    // escaped characters, so we need to reescape them before passing off to the
    // server.
    const query = new URLSearchParams(props.location.search);
    const email = query.get("email");
    const token = query.get("token");
    const encodedToken = token ? encodeURIComponent(token!) : encodeURIComponent("No Token Parameter");

    const accountPageStyles = AccountPageStyles();
    const changePasswordPageStyles = ChangePasswordPageStyles();
    const [viewModel] = useState(() => new ResetPasswordViewModel(encodedToken));
    const [ViewModels] = useState(() => new HeaderLogoModel());
    const [showPassword, setShowPassword] = React.useState(false);

    runInAction(() => {
        viewModel.model.emailAddress = email!;
    });

    const onClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();

        viewModel.changePassword();
    };

    // #endregion Code Behind

    const renderPasswordVisibilityButton = () => (
        <InputAdornment position="end" style={{ display: isEmptyOrWhitespace(viewModel.model.password) ? "none" : "flex" }}>
            <IconButton aria-label="Toggle password visibility" edge="end" onClick={onClickShowPassword} onMouseDown={onClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    const renderBusy = () => <Loader />;

    const renderError = () => (
        <React.Fragment>
            <Box className={accountPageStyles.error}>
                <Typography className={accountPageStyles.title} gutterBottom>
                    There seems to have been a problem
                </Typography>
                <Typography className={accountPageStyles.subTitle} gutterBottom>
                    {viewModel.serverValidationMessage}
                </Typography>
                <Box>
                    <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                        <StandardButton className={accountPageStyles.submitButton} displayName="Try again" execute={viewModel.tryAgain} />
                    </Box>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </React.Fragment>
    );

    const renderForm = () => (
        <form onSubmit={onSubmit}>
            <Typography className={accountPageStyles.title} gutterBottom>
                Reset your password
            </Typography>
            <Typography className={accountPageStyles.subTitle} gutterBottom>
                Please enter the email address you use to log in below and provide the password you want to use.
            </Typography>
            <Box>
                <EditableInput<ResetPasswordModel>
                    className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl} ${accountPageStyles.formInputControl}`}
                    fieldName="emailAddress"
                    inputProps={{
                        placeholder: "Your email address",
                    }}
                    type="email"
                    validateOnBlur={true}
                    viewModel={viewModel}
                    editMode={false}
                />
                <EditableInput<ResetPasswordModel>
                    className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl} ${accountPageStyles.formInputControl}`}
                    fieldName="password"
                    inputProps={{
                        InputProps: {
                            endAdornment: renderPasswordVisibilityButton(),
                        },
                        placeholder: "Your new password",
                    }}
                    type={showPassword ? "text" : "password"}
                    validateOnBlur={true}
                    viewModel={viewModel}
                />
                <EditableInput<ResetPasswordModel>
                    className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl} ${accountPageStyles.formInputControl}`}
                    fieldName="confirmPassword"
                    inputProps={{
                        InputProps: {
                            endAdornment: renderPasswordVisibilityButton(),
                        },
                        placeholder: "Confirm your new password",
                    }}
                    type={showPassword ? "text" : "password"}
                    validateOnBlur={true}
                    viewModel={viewModel}
                />
                <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                    <StandardButton canExecute={!viewModel.IsLoading} className={accountPageStyles.submitButton} displayName="Change password" isSubmit={true} />
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </form>
    );

    const renderSuccess = () => (
        <React.Fragment>
            <Box className={accountPageStyles.error}>
                <Typography className={accountPageStyles.title}>Success! Your password has been changed.</Typography>
                <Box>
                    <Box className={`${accountPageStyles.gutterBottom} ${accountPageStyles.formControl}`}>
                        <StandardButton className={accountPageStyles.submitButton} displayName="Log in" execute={viewModel.loginAfterReset} />
                    </Box>
                </Box>
            </Box>
            <Divider className={accountPageStyles.divider} />
        </React.Fragment>
    );

    return useObserver(() => (
        <Box className={`${accountPageStyles.root} ${changePasswordPageStyles.root}`}>
            <Box className={accountPageStyles.houseImageContainer} />
            <Box className={accountPageStyles.body}>
                <Box>
                    <Box className={accountPageStyles.customerPortalImageContainer} onClick={ViewModels.handleResetLogoClick} />
                    <Box className={accountPageStyles.bodyContent}>
                        {viewModel.canDisplayBusy && renderBusy()}
                        {viewModel.canDisplayError && renderError()}
                        {viewModel.canDisplayForm && renderForm()}
                        {viewModel.canDisplaySuccess && renderSuccess()}
                    </Box>
                    <Box className={accountPageStyles.logoImageContainer} />
                </Box>
            </Box>
        </Box>
    ));
};

export default withRouter(ResetPasswordPage);
